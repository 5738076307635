import { logError } from '@/utils';
import { useAPI } from '@/composables';

async function getProducts() {
  try {
    const response = await useAPI().request(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/products`, 'POST', {
      filters: {
        active: { op: "eq", "v": true},
        posted: { op: "eq", "v": true},
        type: { op: "ne", "v": "supply"},
      }
    }, {
      includeAuth: true,
      includeToken: true,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    logError(`productsAPI.getProducts: Failed to get products. ${err.toString()}`);
    throw new Error(`Failed to get products ${err.toString()}`);
  }
}


const productsAPI = {
  getProducts
}

export default productsAPI;