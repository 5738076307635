import { logError } from '@/utils';
import { useAPI } from '@/composables';

async function getOutlet(placeID) {
  try {
    const response = await useAPI().request(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/outlet`, 'POST', {
      filters: {
        placeID: { op: "eq", v: placeID }
      }
    }, {
      includeAuth: true,
      includeToken: true,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    logError(`outletsAPI.getOutlet: Failed to get outlet. ${err.toString()}`);
    throw new Error(`Failed to get outlet ${err.toString()}`);
  }
}

async function getOutlets() {
  try {
    const response = await useAPI().request(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/outlets`, 'POST', {
      filters: {}
    }, {
      includeAuth: true,
      includeToken: true,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    logError(`outletsAPI.getOutlets: Failed to get outlet. ${err.toString()}`);
    throw new Error(`Failed to get outlet ${err.toString()}`);
  }
}


const outletsAPI = {
  getOutlet,
  getOutlets,
}

export default outletsAPI;