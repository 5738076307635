import { logError } from "@/utils";
import getUser from "./getUser";
import { useAPI } from "@/composables";

/***** Invoices *****/
async function createStripeInvoice(
  stripeCustomer,
  email,
  orderDetails,
  orderID,
  placeID,
  lang,
  chargeAutomatically
) {
  try {
    const api = useAPI();
    let invoiceObject = {
      stripeCustomer: stripeCustomer,
      email: email,
      orderDetails: orderDetails,
      orderID: orderID,
      placeID: placeID,
      lang: lang,
    };
    if (chargeAutomatically) {
      invoiceObject["collectionMethod"] = chargeAutomatically;
    }

    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createInvoice`,
      "POST",
      invoiceObject,
      { includeAuth: true, includeToken: true }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.invoice;
    }
  } catch (err) {
    logError(
      `useStripe.createStripeInvoice: Failed to create invoice. ${err.toString()}`
    );
    return undefined;
  }
}

/***** Customers *****/

/**
 * Register the current restaurant object within Friendlier's stripe acocunt and save the resulting customer ID to the restaurant
 * @param {JSON Object} restaurantData - the data object of the active restaurant
 * @returns created customerID or undefined if failed
 */
async function createStripeCustomer(restaurantData) {
  try {
    const { user } = getUser();
    const api = useAPI();

    let customerData = {
      placeID: restaurantData.placeID ?? "",
      name: restaurantData.companyName,
      uid: user.value.uid,
      city: restaurantData.city ?? "",
      country: "CA" ?? "",
      address: restaurantData.streetAddress ?? "",
      postal: restaurantData.postal ?? "",
      province: restaurantData.province ?? "",
    };

    console.log(customerData);
    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createStripeCustomer`,
      "POST",
      customerData,
      { includeAuth: true, includeToken: true }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.customer;
    }
  } catch (err) {
    console.log(err.toString());
    return undefined;
  }
}

async function getStripeCustomer(stripeCustomerID) {
  try {
    const api = useAPI();

    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getStripeCustomerDetails`,
      "POST",
      { id: stripeCustomerID },
      { includeAuth: true, includeToken: true }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.customer;
    }
  } catch (err) {
    console.log(err.toString());
    return undefined;
  }
}

/***** Payment Methods *****/
async function createStripePaymentMethod() {
  return "Hello :)";
}

async function getStripePaymentMethodDetails(stripePaymentMethodID) {
  try {
    const api = useAPI();

    let response = api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getStripePaymentMethodDetails`,
      "POST",
      { id: stripePaymentMethodID },
      { includeAuth: true, includeToken: true }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.paymentMethod;
    }
  } catch (err) {
    console.log(err.toString());
    return undefined;
  }
}

/***** Payment Flow *****/

async function createSetupIntentWithToken(stripeToken, stripeCustomerId) {
  try {
    const api = useAPI();

    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createSetupIntentPaymentMethod`,
      "POST",
      {
        customerID: stripeCustomerId,
        paymentMethodToken: stripeToken,
      },
      {
        includeAuth: true,
        includeToken: true,
      }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.clientSecret; // This is the client secret of the Setup Intent
    }
  } catch (err) {
    console.error("Error creating Setup Intent:", err.toString());
    return undefined;
  }
}

async function createSetupIntent(
  orderID,
  orderDetails,
  stripeCustomer,
  email,
  placeID
) {
  try {
    const api = useAPI();
    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createSetupIntent`,
      "POST",
      {
        order: {
          id: orderID,
          orderDetails: orderDetails,
        },
        customer: {
          id: stripeCustomer.id,
          email: email,
          placeID: placeID,
        },
      },
      {
        includeAuth: true,
        includeToken: true,
      }
    );

    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.clientSecret;
    }
  } catch (err) {
    console.log(err.toString());
    return undefined;
  }
}

/**
 * @deprecated replaced with setup intent
 * @param {string} orderID
 * @param {obj} orderDetails
 * @param {obj} stripeCustomer
 * @param {string} email
 * @param {string} placeID
 * @returns string: paymentIntent client_secret
 */
async function createPaymentIntent(
  orderID,
  orderDetails,
  stripeCustomer,
  email,
  placeID
) {
  try {
    const api = useAPI();
    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createStripeIntent`,
      "POST",
      {
        order: {
          id: orderID,
          orderDetails: orderDetails,
        },
        customer: {
          id: stripeCustomer.id,
          email: email,
          placeID: placeID,
        },
      },
      { includeAuth: true, includeToken: true }
    );
    if (response.error) {
      throw new Error(`${response.code} - ${response.message}`);
    } else {
      return response.clientSecret;
    }
  } catch (err) {
    console.log(`Server Error: ${err.toString()}`);
    return undefined;
  }
}

/***** Products *****/

/***** Prices *****/

/**
 * Retrieve all pricing data from Stripe
 * @returns Object: { products: {productID: [productPrices]}, prices: {priceID: priceDetails}}: an object containing a breakdown of stripe prices by product along with a list of all prices by ID;
 */
async function getStripePrices() {
  const api = useAPI();

  let prices = {};

  try {
    let response = await api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getStripePrices`,
      "GET",
      undefined,
      { includeAuth: true, includeToken: true }
    );
    let data = response["result"];

    prices = { products: {}, prices: {} };
    for (let price of data) {
      prices["prices"][price.id] = price;
      if (price["product"] in prices["products"]) {
        prices["products"][price["product"]].push(price);
      } else {
        prices["products"][price["product"]] = [price];
      }
    }
  } catch (err) {
    logError(
      `useStripe.getStripePrices: Failed to retrive stripe prices. ${err.toString()}`
    );
  }
  return prices;
}

const useStripe = () => {
  return {
    // Invoices
    createStripeInvoice,
    // Customers
    createStripeCustomer,
    getStripeCustomer,
    // Payment Methods
    createStripePaymentMethod,
    getStripePaymentMethodDetails,
    // Payment Flow
    createPaymentIntent,
    createSetupIntent,
    createSetupIntentWithToken,
    // Prices
    getStripePrices,

    // @deprecated
  };
};

export default useStripe;
