export default function sum(obj) {
  let total = 0;
  if (obj instanceof Array) {
    for (let el of obj) {
      total += el;
    }
  } else {
    for (let el in obj) {
      total += obj[el];
    }
  }
  return total;
}
