<template>
  <div class="pnf-container">
    <p class="pnf-title">{{ $t("pageNotFound") }}</p>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  document.title = "Page Not Found";
});
</script>

<style>
.pnf-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pnf-title {
  font-size: 2rem;
  font-weight: 900;
  color: #032673;
}
</style>
