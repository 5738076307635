<template>
  <div class="chart-title column is-full is-family-secondary is-size-4">
    <p>{{ title }}</p>
  </div>
  <div class="chart-content columns is-mobile is-vcentered">
    <div v-if="loading" class="mx-auto is-full">
      <Loading class="my-1" />
    </div>
    <div v-else-if="!hasData" class="is-full mt-5"><NoData large centered contained /></div>
    <div v-else class="doughnut-container column is-mobile is-half is-desktop-one-third">
      <Doughnut
        v-if="hasData"
        style="width: 100%; height: 100%"
        :id="id"
        :title="title"
        :options="chartOptions"
        :data="chartData"
        :plugins="[htmlLegendPlugin]"
      />
    </div>
    <div id="legend-container" class="column"></div>
  </div>
</template>

<script setup>
import { Doughnut } from "vue-chartjs";
import { ref, watch, onMounted } from "vue";
import { isEqual } from "lodash";

import { Loading, NoData } from "@/components";
import { logError } from "@/utils";

const loading = ref(true);
const chartData = ref({});
const hasData = ref(false);

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  resizeDelay: 10,
  plugins: {
    legend: {
      display: false,
    },
    htmlLegend: {
      containerID: "legend-container",
    },
  },
});

const FRIENDLIER_COLORS = {
  // sunflower: "#F0BA30",
  // stormcloud: "#969696",
  // ocean: "#3BBAF2",
  // denim: "#032673",
  // sunrise: "#FA7854",

  sunflower: "#F0BA30",
  faded_subflower: "#FAE6B4",
  tangerine: "#F68319",
  faced_tangerine: "#FF9F40",
  faded_sunrise: "#FCC7B8",
  sunrise: "#FA7854",
  faded_denim: "#5372B5",
  denim: "#032673",
  faded_ocean: "#99DAF6",
  ocean: "#3BBAF2",
};

const props = defineProps({
  id: String,
  withPercentages: Boolean,
  data: {
    type: [Object, Function],
  },
  colors: {
    type: Array,
    default: null,
  },
  title: {
    type: String,
    default: "",
  },
  dataset: {
    type: [Object, undefined],
    required: true,
  },
  activeBusiness: {
    type: Object,
    required: true,
  },
});

watch(
  () => props.dataset,
  async (newVal, oldVal) => {
    try {
      if (newVal == undefined) return;
      if (isEqual(newVal, {})) {
        return;
      }
      await makeChartData();
      loading.value = false;
    } catch (e) {
      logError(`Doughnut.watchDataset: Failed to setup donut. ${e.toString()}`);
    }
  },
  { deep: true, immediate: true }
);

async function makeChartData() {
  try {
    if (props.dataset) {
      if (!props.dataset) {
        hasData.value = false;
        return;
      }

      let data = {};
      for (const [key, val] of Object.entries(props.dataset)) {
        if (val > 0) data[key] = val;
      }

      hasData.value = true;
      chartData.value = {
        labels: Object.keys(data),
        datasets: [
          {
            backgroundColor: Object.values(FRIENDLIER_COLORS),
            data: Object.values(data),
          },
        ],
      };
    } else {
      if (!props.data) {
        hasData.value = false;
        return;
      }
      hasData.value = true;
      chartData.value = {
        labels: Object.keys(props.data),
        datasets: [
          {
            backgroundColor: Object.values(FRIENDLIER_COLORS),
            data: Object.values(props.data),
          },
        ],
      };
    }
    if (props.colors != null) {
      chartData.value["datasets"][0]["backgroundColor"] = props.colors;
    }
  } catch (err) {
    logError(`Doughnut.makeChartData: Failed to make chart data. ${err}`);
  }
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexFlow = "column wrap";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    var fractions = [""];
    // include % in legend
    if (props.withPercentages) {
      var total = 0;
      chart.data.datasets[0].data.forEach((val) => {
        total += val;
      });
      fractions = chart.data.datasets[0].data.map((x) => (x / total) * 100);
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      /* item.text = Math.round(fractions[index]) + "% " + item.text  */

      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.display = "flex";
      li.style.flexFlow = "row";
      li.style.marginLeft = "10px";

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "15px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "15px";
      boxSpan.style.borderRadius = "15px";
      boxSpan.style.flexShrink = "0";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor;
      textContainer.style.flexDirection = "row";
      textContainer.style.margin = 0;
      textContainer.style.paddingBlock = "3%";
      textContainer.style.color = FRIENDLIER_COLORS.denim;
      textContainer.style.fontFamily = "GibsonBold";
      textContainer.style.fontSize = "14px";
      textContainer.style.cursor = "default";
      textContainer.style.userSelect = "none";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.chart-title {
  color: $denim;
  padding-bottom: 2%;
  white-space: nowrap;
}

.chart-content {
  height: 100%;
  max-width: 100%;
  margin: auto;

  .doughnut-container {
    position: relative;
    padding: 1%;
    min-height: 200px;
    max-height: 100%;
  }
}

@media screen and (max-width: 700px) {
  .chart-title {
    font-size: 18px;
  }
}
</style>
