<template>
  <div class="chart-title column is-full is-family-secondary is-size-4">
    <p>{{ title }}</p>
  </div>
  <div class="chart-content columns is-multiline is-mobile is-vcentered">
    <div v-if="loading" class="mx-auto">
      <Loading />
    </div>
    <div v-else-if="!hasData" class="is-full mt-5"><NoData large centered contained /></div>
    <span v-else class="is-full">
      <div v-if="tabs" class="tabs column my-auto is-full is-centered is-family-secondary is-size-6">
        <ul>
          <li v-for="(value, index) in tabs" :key="`tab_${index}`" :class="{ 'is-active': value === activeTab }">
            <a @click="activeTab = value">{{ value }}</a>
          </li>
        </ul>
      </div>
      <div class="line-container column is-full">
        <Line v-if="hasData" style="width: 100%; height: 100%" :id="id" :options="chartOptions" :data="chartData" />
      </div>
    </span>
  </div>
</template>

<script setup>
import { Line } from "vue-chartjs";
import { ref, watch } from "vue";
import { isEqual, shuffle } from "lodash";

import { Loading, NoData } from "@/components";
import { logError } from "@/utils";

const loading = ref(true);
const hasData = ref(false);
const chartData = ref({});
const activeTab = ref(null);

const FRIENDLIER_COLORS = {
  // sunflower: "#f0ba30",
  // sunrise: "#fa7854",
  // stormcloud: "#969696",
  // cloud: "#3bbaf2",
  // denim: "#032673",
  // greenHighlight: "#4D8B31",
  sunflower: "#F0BA30",
  faded_subflower: "#FAE6B4",
  tangerine: "#F68319",
  faced_tangerine: "#FF9F40",
  faded_sunrise: "#FCC7B8",
  sunrise: "#FA7854",
  faded_denim: "#5372B5",
  denim: "#032673",
  faded_ocean: "#99DAF6",
  ocean: "#3BBAF2",
};
const defaultColors = ref(shuffle(Object.values(FRIENDLIER_COLORS)));

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  resizeDelay: 10,
  scales: {
    x: {
      display: "auto",
      offset: true,
      ticks: {
        padding: 10,
        autoSkipPadding: 10,
        font: {
          family: "GibsonBold",
        },
      },
      border: {
        display: false,
      },
      grid: {
        drawTicks: false,
        display: false,
      },
    },
    y: {
      display: "auto",
      ticks: {
        includeBounds: false,
        padding: 10,
        autoSkipPadding: 5,
      },
      border: {
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        boxWidth: 15,
        boxHeight: 5,
        padding: 20,
        color: FRIENDLIER_COLORS.denim,
        font: {
          family: "GibsonBold",
        },
      },
    },
  },
});

const props = defineProps({
  id: String,
  labels: {
    type: Array,
    default: null,
  },
  defaultKey: {
    type: String,
    required: true,
  },
  tabs: {
    type: Array,
    default: null,
  },
  data: {
    type: [Object, Function],
  },
  colors: {
    type: Array,
    default: null,
  },
  title: {
    type: String,
    default: "",
  },
  dataset: {
    type: [Object, undefined],
    required: true,
  },
  activeBusiness: {
    type: Object,
    required: true,
  },
});

watch(
  () => props.dataset,
  async (newVal, oldVal) => {
    try {
      if (isEqual(oldVal, newVal) || newVal == undefined || isEqual(newVal, {})) return;

      if (props.tabs) {
        activeTab.value = props.tabs[0];
      } else {
        activeTab.value = props.defaultKey;
      }

      await makeChartData();
      loading.value = false;
    } catch (e) {
      logError(`Line.watchDataset: Failed to setup line`);
    }
  },
  { immediate: true }
);

watch(activeTab, async (newVal, oldVal) => {
  try {
    if (isEqual(oldVal, newVal) || newVal == undefined || isEqual(newVal, {})) return;
    loading.value = true;
    await makeChartData();
    loading.value = false;
  } catch (e) {
    logError(`Line.watchActiveTab: Failed to setup line`);
  }
});

async function makeChartData() {
  try {
    if (props.dataset) {
      if (!props.dataset) {
        hasData.value = false;
        return;
      }

      hasData.value = true;
      chartData.value = {
        // labels: props.dataset.x_labels,
        datasets: [],
      };

      var colour = 0;
      var border = 0;
      for (const [key, val] of Object.entries(props.dataset.datasets[activeTab.value])) {
        if (colour >= Object.values(FRIENDLIER_COLORS).length) {
          colour = 0;
          border = 3;
        }
        chartData.value.datasets.push({
          label: key,
          data: val,
          borderDash: [border, border],
          backgroundColor: assignColor(props.colors, colour),
          borderColor: assignColor(props.colors, colour),
        });
        colour++;
      }
    } else {
      if (!props.data) {
        hasData.value = false;
        return;
      }
      hasData.value = true;
      chartData.value = {
        labels: props.labels,
        datasets: [],
      };
      var colour = 0;
      var border = 0;
      for (let key of Object.keys(props.data)) {
        if (colour >= Object.values(FRIENDLIER_COLORS).length) {
          colour = 0;
          border = 3;
        }
        chartData.value.datasets.push({
          label: key,
          data: props.data[key],
          backgroundColor: assignColor(props.colors, colour),
          borderColor: assignColor(props.colors, colour),
        });
        colour++;
      }
    }
  } catch (err) {
    logError(`Line.makeChartData: Failed to setup chart data. ${err.toString()}`);
  }
}

function assignColor(prop, i) {
  if (!prop) {
    return defaultColors.value[i];
  } else {
    return prop[i];
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.chart-title {
  color: $denim;
  padding-bottom: 1%;
}

.chart-content {
  height: 100%;
  max-width: 100%;

  .line-container {
    position: relative;
    padding: 1%;
    min-height: 350px;
  }
}

@media screen and (max-width: 700px) {
  .chart-title {
    font-size: 18px;
  }

  .chart-content {
    .line-container {
      min-height: 600px;
    }
  }
}
</style>
