<template>
  <Loading v-if="loading" medium contained />
  <span class="active-client-select" v-else>
    <label for="active-client" class="is-denim is-bold mr-3">{{
      $t("active-business")
    }}</label>
    <select
      :disabled="!editable"
      id="active-client"
      :v-model="activeClient"
      :onchange="changeActiveClient"
    >
      <option
        v-for="client in clientStore.getState().userClients"
        :selected="activeClient.data.placeID == client['data']['placeID']"
        :key="client['data']['placeID']"
      >
        {{ client["data"]["displayName"] }}
      </option>
    </select>
  </span>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { clientStore } from "@/store";
import { Loading } from "@/components";
import { logError } from "@/utils";

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
});

const activeClient = ref();
const loading = ref(true);

onMounted(async () => {
  try {
    await clientStore.initialize();
    activeClient.value = clientStore.activeClient;
  } catch (e) {
    logError(``);
  }
  loading.value = false;
});

watch(
  () => clientStore.userClients,
  () => {
    console.log("User Client Changing");
    loading.value = true;
    loading.value = false;
  }
);

watch(
  () => clientStore.activeClient,
  () => {
    activeClient.value = clientStore.activeClient;
  }
);

async function changeActiveClient(e) {
  clientStore.setActiveClientByDisplayName(e.target.value);
  clientStore.fetchStripeCustomer(true);
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/colors.scss" as c;
@use "@/assets/scss/elements.scss" as e;

.active-client-select {
  label {
    margin-right: 0.75rem;
    color: c.$denim;
    font-weight: bold;
  }
  select {
    padding: 7px 12px;
    font-size: 1.2rem;
    @extend .friendlier-select;
  }
}
</style>
