<template>
  <p class="is-size-3 ml-3 mb-3 is-family-secondary has-text-info">{{ $t("regForm.registration") }}</p>
  <hr />
  <div class="columns">
    <div class="column has-text-left">
      <form>
        <div class="field">
          <p class="control has-icons-left">
            <input type="text" required :placeholder="$t('regForm.name-first')" v-model="firstName" class="input" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input type="text" required :placeholder="$t('regForm.name-last')" v-model="lastName" class="input" />
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input type="email" required :placeholder="$t('loginForm.email')" v-model="email" class="input" />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </p>
          <p class="is-sunrise is-bold">{{ emailError }}</p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input type="password" required :placeholder="$t('loginForm.password')" v-model="password" class="input" />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </p>
          <p class="is-sunrise is-bold">{{ passwordError }}</p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input type="password" required :placeholder="$t('regForm.password-confirm')" v-model="password2" class="input" />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </p>
          <p class="is-sunrise is-bold">{{ confPasswordError }}</p>
        </div>
      </form>
    </div>
  </div>
  <div class="error mt-4 mb-4 is-family-secondary has-text-danger">
    {{ error }}
  </div>
  <button class="button is-info mt-1" @click.prevent="handleSubmit">{{ $t("reg") }}</button>
</template>

<script setup>
import { ref, watch } from "vue";
import { useSignup, getUser } from "@/composables";
import { projectFirestore, projectAuth, timestamp } from "@/firebase/config";
import { passwordValidator, emailValidator } from "@/utils";

const emits = defineEmits(["signup"]);

const { error, signup } = useSignup();

// refs
const firstName = ref("");
const lastName = ref("");
const email = ref("");
const password = ref("");
const password2 = ref("");

const emailError = ref("");
const passwordError = ref("");
const confPasswordError = ref("");

watch(email, async () => {
  error.value = "";
  emailError.value = "";
  if (email.value == "") return;
  if (!(await emailValidator(email.value))) {
    emailError.value = $t("regForm.email-error");
  }
});

watch(password, async () => {
  error.value = "";
  passwordError.value = "";
  if (password.value == "") return;
  passwordError.value = await passwordValidator(password.value);
});

watch(password2, async () => {
  error.value = "";
  confPasswordError.value = "";
  if (password2.value == "") return;
  if (password.value != password2.value) {
    confPasswordError.value = $t("regForm.password-error");
  }
});

const handleSubmit = async () => {
  if (
    firstName.value == "" ||
    lastName.value == "" ||
    emailError.value != "" ||
    passwordError.value != "" ||
    confPasswordError.value != ""
  ) {
    error.value = $t("regForm.missing-field");
    return;
  }

  await signup(email.value.toLowerCase(), password.value, firstName.value);
  const { user } = getUser();

  const userObj = {
    admin: false,
    balance: 0,
    cashed: 0,
    donated: 0,
    email: email.value,
    fcmtoken: "",
    fname: firstName.value,
    lastNotif: timestamp(),
    lname: lastName.value,
    pendingBalance: 0,
    createdAt: timestamp(),
    totalConfirmed: 0,
    totalUnconfirmed: 0,
    totalUserCount: 0,
    tutorialDone: false,
    uid: user.value.uid,
  };

  await projectFirestore.collection("users").doc(user.value.uid).set(userObj);

  await projectAuth.currentUser.sendEmailVerification();
  if (!error.value) {
    // success
    emits("signup");
  }
};
</script>

<style></style>
