import logError from "./logError";

export default function (past,threshold) {
  try {
    const elapsed = past - Date.now();
    if (elapsed >= threshold)
      return true;
  } catch (e) {
    logError(`timeSince: Failed to calculate time since. ${e.toString()}`);
  }
  return false;
}