export { default as FriendlierDoughnutChart } from "./Doughnut.vue";
export { default as FriendlierLineChart } from "./Line.vue";
export { default as FriendlierBarChart } from "./Bar.vue";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, ArcElement, BarElement} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, BarElement, Tooltip, Legend)
ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = "Gibson";
ChartJS.defaults.color = "rgb(66, 66, 66)";
ChartJS.defaults.plugins.tooltip.titleFont = {family: "GibsonBold"};
