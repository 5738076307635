export default {
  "emailNotice": {
    "email-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email List"])},
    "notice-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add multiple emails to be notified when an order is completed."])},
    "notice-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These emails will receive an order confirmation"])},
    "notice-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with a copy of the invoice and a quick payment link."])},
    "new-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new Email"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "add-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to add email:"])},
    "remove-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to remove email: "])}
  },
  "resetPassword": {
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "reset-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be sent an email containing instructions to change your password."])},
    "reset-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This may take up to 10 minutes, ensure you check your junk mail."])},
    "reset-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be"])},
    "reset-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logged out"])},
    "reset-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["after clicking"])},
    "reset-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your inbox! We've sent you an email with a link to change your password. You will be logged out in"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])}
  },
  "outletDetails": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "cardDetails": {
    "payment-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "pay-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed, please check that all fields are correct and try again"])}
  },
  "checkoutModal": {
    "order-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Received!"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "please-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please review"])},
    "invoice-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your invoice has been created, it can be downloaded or viewed online using the links below."])},
    "download-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Invoice"])},
    "pay-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Invoice"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for using the Friendlier system, we will get started on your order right away. If you have any questions or concerns please"])},
    "thank-cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["let us know."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "checkoutRestaurantDetails": {
    "restaurant-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant Details"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name:"])},
    "select-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Location"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region:"])},
    "estimated-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated Delivery:"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address:"])},
    "help-modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something incorrect? Reach out!"])}
  },
  "checkoutUserDetails": {
    "user-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Details"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First:"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])}
  },
  "orderDetailsTable": {
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Details"])},
    "var-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*exact product prices may vary"])},
    "each": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["each"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty:"])},
    "tax-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Included"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "sales-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Tax"])},
    "total-due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Due"])}
  },
  "stripeCheckout": {
    "card-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Details"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number:"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry:"])},
    "cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC:"])},
    "submit-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now Testing"])}
  },
  "addressField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full address, including number to autofill address"])},
  "businessForm": {
    "business-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["business registration"])},
    "business-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only enter Place ID if you received one already"])},
    "placeid-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Place ID (ex. R100111)"])},
    "business-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "select-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Province"])},
    "ontario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
    "quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quebec"])},
    "alberta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alberta"])},
    "bc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Columbia"])},
    "manitoba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manitoba"])},
    "nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Brunswick"])},
    "nfl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newfoundland and Labrador"])},
    "nwt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northwest Territories"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Scotia"])},
    "nunavut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunavut"])},
    "pei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prince Edward Island"])},
    "saskatchewan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saskatchewan"])},
    "yukon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukon"])},
    "select-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Region"])},
    "postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "street-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address"])},
    "website-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website URL  (optional)"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "business-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business has been registered."])},
    "placeid-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Place ID"])},
    "field-missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please fill out all mandatory fields"])},
    "already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already registered for a business!"])},
    "address-num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address Number"])}
  },
  "loginForm": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])}
  },
  "regForm": {
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])},
    "name-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "name-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
    "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "missing-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete fields"])}
  },
  "sidebar": {
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "past-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Orders"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Return"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "email-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email format is incorrect"])},
    "missing-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for submitting a help request, we will try to address this quickly."])}
  },
  "noData": {
    "name-present": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No Data for ", _interpolate(_named("name")), " Yet!"])},
    "name-missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Yet!"])}
  },
  "notApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business has yet to be approved. Please wait to be contacted by the Friendlier Team once you have filled out the business registration form on the order page."])},
  "orderDetailModal": {
    "container-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container type"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])}
  },
  "productCard": {
    "per-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per case"])},
    "deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposits"])},
    "quant-per-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity per case:"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deposit"])},
    "case-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Cases:"])}
  },
  "productFilter": {
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters:"])},
    "container-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container Size:"])},
    "container-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container Type:"])},
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
  },
  "passwordValidator": {
    "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be longer than 8 characters"])},
    "password-req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must include at least 1 uppercase, and 1 number"])},
    "password-mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])}
  },
  "forgotPassword": {
    "reset-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to reset password:"])},
    "return-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["return to home"])},
    "email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent! Check your inbox"])}
  },
  "login": {
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to join Friendlier"])}
  },
  "register": {
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account? Click Here."])}
  },
  "businessPending": {
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your business submission! It has yet to be approved. Please wait to be contacted by the Friendlier Team."])},
    "update-password-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please reset your password"])},
    "update-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
  },
  "analytics": {
    "an": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analytics"])},
    "packages-reused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Packages Reused"])},
    "cycle-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Container Cycle Time"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Users"])},
    "return-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Rate"])},
    "sold-returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumulative Sold and Returned"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "by-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Size"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordering Frequency (cases)"])},
    "customer-breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Breakdown"])},
    "more-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in more detailed information?"])},
    "click-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here!"])},
    "thats-like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wow! That's like..."])},
    "ghg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG Emissions Diverted"])},
    "transportEmissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Emissions saved accounts for ", _interpolate(_named("value")), " emmisions from transportation"])},
    "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste Diverted"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Savings"])},
    "bowling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bowling Balls of Plastic"])},
    "trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trees Absorbing GHGs in a Year"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filled Bottles of Water"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback"])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "portal-improve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal Improvement Ideas"])},
    "new-feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Feature Request"])},
    "trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having Trouble with Something"])},
    "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praise for the Platform"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "email-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email field must be of the following format: _____", "@", "____.com"])},
    "empty-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Name"])},
    "empty-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Email"])},
    "empty-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Prompt"])},
    "empty-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Message"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for submitting feedback", "!"])}
  },
  "home": {
    "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is not part of a Registered Friendlier Business."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to register"])}
  },
  "order": {
    "order-containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order containers"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order"])},
    "transaction-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Message"])},
    "processing-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Payment:"])},
    "creating-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Order:"])},
    "not-working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something not working?"])},
    "hang-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hang On!"])},
    "empty-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty, please make a selection"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops"])},
    "empty-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select A Location"])},
    "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Products matching the selected filters"])}
  },
  "pastOrders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["past orders"])},
    "order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order id"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company name"])},
    "date-submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date submitted"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment (`$`)"])},
    "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery date"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order details"])},
    "order-complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Complete"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank-you for using the Friendlier system, we will get started on your order right away. If you have any questions or concerns please let us know."])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account settings"])}
  },
  "confirm-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Checkout"])},
  "payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
  "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found!"])},
  "active-business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Business:"])},
  "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["register"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
  "enter-portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Portal"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])},
  "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])}
}