<template>
  <div v-if="type == 'user-report'" :class="`data-panel main is-`+bgColour+`-back`" style="float: left; padding: 1rem;">
    <div class="data" style="padding-left: 0.5rem; margin: 0;"> <!-- ADD CLASSES FOR STYLING -->
      <p :class="`is-family-secondary is-size-5 is-` + data[0].title.colour">
        {{ data[0].title.text }}
      </p>
      <div v-if="loading" class="mx-auto mt-2">
        <Loading medium />
      </div>
      <span v-else-if="!hasData" class="is-inline-block">
        <NoData/>
      </span>
      <span v-else :class="`is-family-secondary is-size-3 is-`+fontColour" style="padding-left: 0.5rem">
        {{ formatNumber(data[0].value.total) }}
        <span class="is-family-secondary is-size-6">users</span>
        <div style="padding-left: 2rem; line-height: 0.7; padding-bottom: 1rem;">
          <span class="is-family-secondary is-size-6">{{ formatNumber(data[0].value.repeat) }} repeat users</span>
          <br/>
          <span class="is-family-secondary is-size-6">{{ formatNumber(data[0].value.new) }} new users</span>
        </div>
      </span>
    </div>
  </div>
  <div v-else-if="type == 'cycle-time'" :class="`data-panel main is-`+bgColour+`-back`" style="float: left; padding: 1rem;">
    <div class="data" style="padding-left: 0.5rem; min-width: 60%; margin: 0;"> <!-- ADD CLASSES FOR STYLING -->
      <p :class="`is-family-secondary is-size-5 is-`+data[0].title.colour">{{ data[0].title.text }}</p>
      <div v-if="loading" class="mx-auto mt-2">
        <Loading medium />
      </div>
      <span v-else-if="!hasData" class="is-inline-block">
        <NoData/>
      </span>
      <span v-else :class="`is-family-secondary is-size-3 is-`+fontColour" style="padding-left: 1rem">
        {{ formatNumber(data[0].value.time) }}
        <span class="is-family-secondary is-size-6">days</span>
      </span>
    </div>
  </div>
  <div v-else-if="type == 'return-rate'" :class="`data-panel main is-`+bgColour+`-back`">
    <div class="data" style="padding-left: 0.5rem;"> <!-- ADD CLASSES FOR STYLING -->
      <div style="border-bottom: 5px solid; border-color: #f0ba30; margin-right:1.5rem">
        <p :class="`is-family-secondary is-size-5 is-`+data[0].title.colour">
          <div :class="`is-family-secondary is-size-3 is-`+data[0].title.colour">
            {{ formatNumber(data[0].value.percent) }} %
          </div>
          {{ data[0].title.text }}
        </p>
      </div>
      <div v-if="loading" class="mx-auto mt-2">
        <Loading medium />
      </div>
      <span v-else-if="!hasData" class="is-inline-block">
        <NoData/>
      </span>
      <span v-else :class="`is-family-secondary is-size-3 is-`+fontColour">
        <div style="padding: 1rem">
          {{ formatNumber(data[0].value.returned) }}
          <div class="is-family-secondary is-size-6" style="padding-bottom: 1rem;">containers returned</div>
          <!-- {{ data[0].value.sold }}
          <div class="is-family-secondary is-size-6">containers sold</div> -->
        </div>
      </span>
    </div>
  </div>
  <div v-else-if="type == 'main'" :class="`data-panel main is-`+bgColour+`-back`">
    <p v-if="title != ''" class="title">{{ title }}</p>
    <span class="data-icon" style="padding-left: 0.5rem; padding-top: 0.5rem"> <img :src="icon" /></span>
    <div class="data" style="padding-left: 0.5rem">
      <p :class="`is-family-secondary is-size-5 is-`+fontColour">{{ data[0].title }}</p>
      <p :class="`is-family-secondary is-size-1 is-`+fontColour">{{ formatNumber(data[0].value) }}</p>
    </div>
  </div>
  <div v-else-if="type == 'full'" :class="`data-panel full is-`+bgColour+`-back`">
    <p v-if="title != ''" :class="`is-family is-bold is-size-3 is-`+fontColour" style="min-width: 100%;">{{ title }}</p>
    <template  v-for="(el, index) in localData" :key="index">
      <slot v-if="el == null"/>
      <div class="data" v-else>
        <p :class="`is-family-secondary is-size-3 is-`+fontColour">
          {{ formatNumber(el.value) }}
          <span class = "data-icon"> <img :src= "icon[index]" /></span>
        </p>
        <p :class="`is-family-secondary is-size-6 is-`+fontColour" style="min-width:100%;">{{ el.title }}</p>
      </div>
    </template>
  </div>
  <div v-else-if="type == 'secondary'" :class="`data-panel secondary is-`+bgColour+`-back`">
    <p v-if="title != ''" class="title">{{ title }}</p>
    <div class="data">
      <p :class="`is-family-secondary is-size-5 is-`+data[0].title.colour">{{ data[0].title.text }}</p>
      <div v-if="loading" class="mx-auto mt-2">
        <Loading medium />
      </div>
      <span v-else-if="!hasData" class="is-inline-block"> <NoData/></span>
      <span v-else :class="`is-family-secondary is-size-3 is-`+fontColour">{{ formatNumber(displayData) }} <span class="is-family-secondary is-size-6">{{ unit }}</span> </span>
    </div>
  </div>
  <div v-else :class="`data-panel is-`+bgColour+`-back`">
    <p v-if="title != ''" class="title">{{ title }}</p>
    <span class="data-icon" style="padding-left: 0.5rem; padding-top: 0.5rem"> <img :src="icon" /></span>
    <div class="data" style="padding-left: 0.5rem; margin-top: 0.5rem">
      <p :class="`is-family-secondary is-size-3 is-`+fontColour">{{ formatNumber(data[0].value) }}</p>
      <p :class="`is-family-secondary is-size-6 is-`+fontColour">{{ data[0].title }}</p>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, useSlots } from "vue";
import { isEqual } from "lodash";

import { Loading, NoData } from '@/components';
import { logError, formatNumber } from "@/utils";

const props = defineProps({
  id: String,
  type: String,
  data: Array,
  title: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  bgColour: String,
  fontColour: {
    type: String,
    default: 'cloud',
  },
  icon: Array,
  delim: {
    type: Boolean,
    default: false,
  },
  dataset: {
    type: [Object, undefined],
  },
  activeBusiness: {
    type: Object,
  },
});

const slots = useSlots();

const loading = ref(true);
const hasData = ref(false);

const localData = ref([]);
const displayData = ref("");


/**
 * Convert recieved data into local copies
 * @props {object} activeBusiness
 * @props {array} data
 * @props {string} id
 * @state {object} displayData
 * @state {boolean} has data
 */
async function fillData() {
  try {
    if (props.data[0].value instanceof Function) {
      displayData.value = await props.data[0].value(
        props.dataset,
        props.activeBusiness['data']['placeID'],
        props.id
      );
    } else {
      displayData.value = props.data[0].value
    }
    hasData.value = displayData.value ? true : false;
  } catch (e) {
    logError(`DataPanel.fillData: Failed to fill data. ${e.toString()}`);
  }
}

/**
 * Reload data when a change is noticed
 * @props {string} dataset
 * @state {boolean} loading
 */
watch(
  () => props.dataset,
  async (newVal,oldVal) => {
    if (isEqual(oldVal,newVal) || newVal == undefined || isEqual(newVal,{})) return;
    await fillData();
    loading.value = false;
  },
  { immediate: true}
);

/**
 * Create a copy of the sent data to manipulate locally
 * @props {delim}
 * @props {data}
 * @state {localData} 
 */
watch(
  () => [props.delim, props.data],
  async () => {
    localData.value = [];
    if (props.delim) {
      for (let i in props.data) {
        localData.value.push(props.data[i]);
        if (i < props.data.length - 1) localData.value.push(null);
      }
    } else {
      localData.value = props.data;
    }
    loading.value = false;
    hasData.value = localData.value ? true : false;
  },
  { immediate: true }
);

</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.data-panel {
  display: flex;
  flex-flow: column nowrap;
  flex: 0;
  align-items: stretch;
  border-radius: 20px;
  min-width: 22%;
  padding: 1%;

  .data {
    align-self: flex-end;
    min-width: 100%;
    margin: auto;
  }

  .data-icon {
    align-self: flex-start;
    flex-shrink: 0;
    img {
      padding: 5%;
      height: 4vw;
    }
  }

  &.main {
    min-width: 30%;
  }

  &.secondary{
    display: inline-block;
    width: 100%;
    padding: 2%;
    margin: auto;
  } 

  &.full {
    min-width: 98%;
    flex-flow: row wrap;
    row-gap: 2vh;
    column-gap: 0.1vw;
    height: 100%;
    margin: auto;
    text-align: center;
    padding-block:2%;

    .data {
      min-width: 25%;
      max-width:30%;
      margin: auto;
      padding-inline:2%;
      p {
        .data-icon {
          padding-inline-start: 5%;
          img {
            flex: 0;
            padding: 1%;
            height: 4vw;
          }
        }
      }
    }
    
  }
}

@media screen and (max-width: 700px) {
  .data-panel,
  .data-panel.main,
  .data-panel.full {
    min-width: 80%;
    padding: 5%;

    .data-icon {
      margin-block: 3%;
      img {
        padding: 10%;
        height: 10vw;
      }
    }

  }

  .data-panel.full {
    flex-flow: column nowrap;

    .data {
      min-width: 100%;
      margin: auto;
      flex: 0;
      p {
        .data-icon {
          margin-block: 3%;
          img {
            flex: 0;
            padding: 1%;
            height: 8vw;
          }
        }
      }
    }
  }
}
</style>
