import { createRouter, createWebHistory } from "vue-router";
import {
  PageNotFound,
  Login,
  Register,
  BusinessRegistration,
  BusinessPending,
  Home,
  ForgotPassword,
  Order,
  PastOrders,
  Feedback,
  Analytics,
  Settings,
  Onboarding,
} from "@/views";
import { projectAuth } from "@/firebase/config";

const auth = (to, from, next) => {
  let user = projectAuth.currentUser;

  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const noAuth = (to, from, next) => {
  let user = projectAuth.currentUser;

  if (user) {
    next({ name: "Home" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/login",
    alias: "/",
    name: "Login",
    component: Login,
    beforeEnter: noAuth,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: noAuth,
  },
  // {
  //   path: "/register-business",
  //   name: "BusinessRegistration",
  //   component: BusinessRegistration,
  //   beforeEnter: auth,
  // },
  {
    path: "/business-pending",
    name: "BusinessPending",
    component: BusinessPending,
    beforeEnter: auth,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: noAuth,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: auth,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    beforeEnter: auth,
  },
  {
    path: "/past-orders",
    name: "PastOrders",
    component: PastOrders,
    beforeEnter: auth,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    beforeEnter: auth,
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    beforeEnter: auth,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: auth,
  },
  // {
  //   path: "/onboarding",
  //   name: "Onboarding",
  //   component: Onboarding,
  //   beforeEnter: requireAuth,
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found!",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
