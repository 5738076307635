<template>
  <Loading v-if="loading" />
  <template v-else>
    <NotPermitted v-if="!permitted" />

    <template v-else>
      <UnderCons v-if="isUnderConstruction" />

      <template v-else>
        <div>
          <!-- Active Restaurant Select -->
          <span
            class="is-flex is-justify-content-space-between is-align-items-center p-5"
          >
            <p
              class="is-size-3 is-family-secondary has-text-info has-text-left"
            >
              {{ $t("analytics.an") }}
            </p>
            <span
              class="active-restaurant-select"
              style="
                background-color: #f0ba30;
                /* position: fixed;
                right: 0px;
                top: 0px; */
                padding: 1rem;
                border-radius: 0.5rem;
                z-index: 1;
              "
            >
              <label for="active-restaurant" class="is-denim is-bold mr-3">{{
                $t("active-business")
              }}</label>
              <select
                id="active-restaurant"
                :v-model="activeRestaurant"
                :onchange="changeActiveRestaurant"
              >
                <option
                  v-for="res of restaurants"
                  :key="res['data']['placeID']"
                >
                  {{ res["data"]["displayName"] }}
                </option>
              </select>
            </span>
          </span>

          <!-- <hr /> -->

          <!-- Date Selection -->
          <!-- <div v-if="premiumData && enoughData && !noData" class="date-selection">
          <button @click="selectTotals">View Totals</button>
          <p style="padding-left: 3rem; padding-right: 3rem">- or -</p>
          <div class="week-range-select">
            <label for="date-range-picker">Select a Range:</label>
            <div id="date-range-picker">
              <span>
                <label for="end-week-picker">Start:</label>
                <select v-model="startWeek">
                  <option v-for="star of startWeekOptions" :key="star">{{ star }}</option>
                </select>
              </span>
              <span>
                <label for="end-week-picker">End:</label>
                <select v-model="endWeek">
                  <option v-for="week of endWeekOptions" :key="week">{{ week }}</option>
                </select>
              </span>
            </div>
            <button @click="selectWeeks">Go!</button>
          </div>
        </div> -->

          <!-- <hr /> -->

          <!-- Data Dashboard -->
          <div class="items">
            <div v-if="!noData" class="data-dashboard" style="margin-top: 15px">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  "
                >
                  <!-- <v-btn
                  :color="currentTab === 'total' ? 'blue-darken-4' : 'grey-lighten-4'"
                  class="font-weight-bold"
                  elevation="0"
                  style="margin-right: 10px"
                  @click="selectTotals"
                >
                  Total
                </v-btn> -->

                  <div class="selected-month">
                    {{ selectedMonth || "Select a Month" }}
                  </div>
                  <!-- <div style="width: 380px; display: flex">?\ -->
                  <!-- <v-menu style="margin-top: 10px">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        style="
                          background-color: #032673 !important;
                          color: white;
                          margin-top: 10px;
                          font-weight: 900;
                        "
                      >
                        Filter
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="onMenuItemClick">
                        <v-list-item-title>Show All Outlets</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> -->
                  <v-select
                    label="Select Month"
                    v-model="selectedMonth"
                    :items="monthOptions"
                    style="max-width: 300px; margin-left: 5px"
                  ></v-select>
                </div>
                <!-- </div> -->

                <div
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  "
                >
                  <div v-if="isMultiOutlet">
                    <v-chip
                      :class="{ active: activeChip === 'current' }"
                      @click="onMenuItemClick('current')"
                      style="font-weight: 900; margin-right: 10px"
                    >
                      Current Unit
                    </v-chip>
                    <v-chip
                      :class="{ active: activeChip === 'total' }"
                      @click="onMenuItemClick('total')"
                      style="font-weight: 900"
                    >
                      Total Organization
                    </v-chip>
                  </div>
                  <div v-else></div>

                  <div>
                    <v-chip @click="exportAsPDF" class="item">
                      Export as PDF
                    </v-chip>
                  </div>
                </div>

                <!-- <v-window v-model="tab"> </v-window> -->
                <!-- total Returns -->
                <div
                  v-show="currentTab === 'monthly'"
                  style="
                    display: flex;
                    width: 100%;
                    margin-top: 15px;
                    justify-content: space-between;
                  "
                >
                  <DataPanel
                    type="main"
                    bgColour="denim"
                    :icon="[ReuseIconCloud]"
                    :data="[
                      {
                        value: basicStats.total_returns,
                        title: $t('analytics.packages-reused'),
                      },
                    ]"
                  />

                  <DataPanel
                    v-for="(value, name) in basicStats.metrics"
                    bgColour="ocean"
                    :icon="[statsIcons[$t(name)]]"
                    :data="[{ value: value, title: $t(name) }]"
                    :key="name"
                  />
                </div>

                <div
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  "
                  v-show="currentTab === 'total'"
                >
                  <DataPanel
                    type="main"
                    bgColour="denim"
                    :icon="[ReuseIconCloud]"
                    :data="[
                      {
                        value: basicStats.total_returns,
                        title: $t('analytics.packages-reused'),
                      },
                    ]"
                  />

                  <DataPanel
                    v-for="(value, name) in basicStats.metrics"
                    bgColour="ocean"
                    :icon="[statsIcons[$t(name)]]"
                    :data="[{ value: value, title: $t(name) }]"
                    :key="name"
                  />
                </div>
              </div>
              <!-- <span class="p-2" v-for="(value, name) in basicStats.extra">*{{ $t(name, { value }) }}</span> -->

              <hr class="is-full mx-5" />

              <template v-if="premiumData && enoughData">
                <div
                  v-if="tab == 'weekly'"
                  class="premium-data columns is-multiline is-centered is-vcentered"
                >
                  <!-- Users -->
                  <div class="column is-half-desktop is-full-moble">
                    <FriendlierLineChart
                      id="users-line"
                      :labels="['']"
                      :tabs="['breakdown', 'total']"
                      :dataset="{
                        tabs: ['breakdown', 'total'],
                        datasets: {
                          total: { total: activeUsers },
                          breakdown: {
                            'Repeat Users': repeatUsers,
                            'New Users': newUsers,
                          },
                        },
                      }"
                      defaultKey="total"
                      :activeBusiness="activeRestaurant"
                      title="Active Users"
                    />
                  </div>

                  <!-- Cycle Time -->
                  <div class="column is-half-desktop is-full-moble">
                    <FriendlierLineChart
                      id="cycle-line"
                      :labels="['']"
                      :dataset="{
                        datasets: {
                          time: { time: cycleTime },
                        },
                      }"
                      defaultKey="time"
                      :activeBusiness="activeRestaurant"
                      title="Active Users"
                    />
                  </div>

                  <!-- Outlet Returns -->
                  <div class="column is-half-desktop is-full-moble">
                    <FriendlierLineChart
                      id="outlet-returns-line"
                      :labels="['']"
                      :dataset="{
                        datasets: {
                          outletReturns: outletReturns,
                        },
                      }"
                      defaultKey="outletReturns"
                      :activeBusiness="activeRestaurant"
                      title="Outlet Returns"
                    />
                  </div>

                  <!-- Return Rate -->
                  <div class="column is-half-desktop is-full-mobile">
                    <FriendlierLineChart
                      id="return-rate-line"
                      :labels="['']"
                      :dataset="{
                        datasets: {
                          rate: { rate: returnRate },
                        },
                      }"
                      defaultKey="rate"
                      :activeBusiness="activeRestaurant"
                      title="Return Rate %"
                    />
                  </div>
                </div>

                <div
                  v-if="tab == 'monthly'"
                  class="premium-data columns is-multiline is-centered is-vcentered"
                >
                  <!-- Users and Cycle Time -->
                  <div class="column" style="max-width: 15rem">
                    <DataPanel
                      type="user-report"
                      id="active-users"
                      fontColour="ocean"
                      :data="[
                        {
                          value: {
                            total: activeUsersMonth,
                            repeat: repeatUsersMonth,
                            new: newUsersMonth,
                          },
                          title: { text: 'Active Users', colour: 'denim' },
                        },
                      ]"
                      :activeBusiness="activeRestaurant"
                    />

                    <DataPanel
                      type="cycle-time"
                      id="cycle-time"
                      fontColour="ocean"
                      :data="[
                        {
                          value: { time: cycleTimeMonth },
                          title: { text: 'Cycle Time', colour: 'denim' },
                        },
                      ]"
                      :unit="$t('analytics.days')"
                      :activeBusiness="activeRestaurant"
                    />
                  </div>

                  <!-- Returns Donut -->

                  <div
                    v-if="!noData && isMultiOutlet"
                    class="chart-container column ml-auto is-two-fifths-tablet is-full-mobile is-centered"
                    style="margin-left: 0 !important; padding-left: 2rem"
                  >
                    <FriendlierDoughnutChart
                      id="sold-returned-cumulative"
                      :dataset="outletReturns"
                      :activeBusiness="activeRestaurant"
                      title="Outlet Returns"
                    />
                  </div>

                  <!-- return rate -->
                  <div
                    class="chart-container column ml-auto is-full-mobile is-vcentered is-two-fifths-tablet is-full-mobile"
                    style="
                      max-width: 20rem;
                      margin-left: 0 !important;
                      padding-top: 3rem;
                    "
                  >
                    <DataPanel
                      type="return-rate"
                      id="ror"
                      fontColour="ocean"
                      :data="[
                        {
                          value: {
                            percent: isNaN(returnRate) ? 0 : returnRate,
                            returned: containersReturnedMonth,
                            sold: containersSold,
                          },
                          title: {
                            text: $t('analytics.return-rate'),
                            colour: 'denim',
                          },
                        },
                      ]"
                      unit="%"
                      :activeBusiness="activeRestaurant"
                    />
                  </div>
                </div>

                <div
                  v-if="tab == 'total'"
                  class="premium-data columns is-multiline is-centered is-vcentered"
                >
                  <!-- Users and Cycle Time -->
                  <div class="column" style="max-width: 15rem">
                    <DataPanel
                      type="user-report"
                      id="active-users"
                      fontColour="ocean"
                      :data="[
                        {
                          value: {
                            total: activeUsers,
                            repeat: repeatUsers,
                            new: newUsers,
                          },
                          title: { text: 'Active Users', colour: 'denim' },
                        },
                      ]"
                      :activeBusiness="activeRestaurant"
                    />

                    <DataPanel
                      type="cycle-time"
                      id="cycle-time"
                      fontColour="ocean"
                      :data="[
                        {
                          value: { time: cycleTime },
                          title: { text: 'Cycle Time', colour: 'denim' },
                        },
                      ]"
                      :unit="$t('analytics.days')"
                      :activeBusiness="activeRestaurant"
                    />
                  </div>

                  <!-- Returns Donut -->
                  <div
                    v-if="!noData && isMultiOutlet"
                    class="chart-container column ml-auto is-two-fifths-tablet is-full-mobile is-centered"
                    style="margin-left: 0 !important; padding-left: 2rem"
                  >
                    <FriendlierDoughnutChart
                      id="sold-returned-cumulative"
                      :dataset="outletReturns"
                      :activeBusiness="activeRestaurant"
                      title="Outlet Returns"
                    />
                  </div>

                  <!-- return rate -->
                  <div
                    class="chart-container column ml-auto is-full-mobile is-vcentered is-two-fifths-tablet is-full-mobile"
                    style="
                      max-width: 20rem;
                      margin-left: 0 !important;
                      padding-top: 3rem;
                    "
                  >
                    <DataPanel
                      type="return-rate"
                      id="ror"
                      fontColour="ocean"
                      :data="[
                        {
                          value: {
                            percent: returnRate,
                            returned: containersReturned,
                            sold: containersSold,
                          },
                          title: {
                            text: $t('analytics.return-rate'),
                            colour: 'denim',
                          },
                        },
                      ]"
                      unit="%"
                      :activeBusiness="activeRestaurant"
                    />
                  </div>
                </div>
              </template>

              <hr v-if="enoughData" />

              <span v-if="enoughData"
                ><h1
                  style="
                    text-align: center;
                    font-size: 150%;
                    font-weight: bolder;
                  "
                ></h1
              ></span>

              <hr v-if="enoughData" />

              <div class="data-dashboard">
                <div
                  v-if="premiumData && enoughData"
                  class="premium-data columns is-multiline is-centered"
                >
                  <div
                    class="chart-container column is-half-desktop is-full-mobile is-vcentered is-centered"
                  >
                    <template v-if="returnsBreakdown">
                      <FriendlierLineChart
                        v-if="returnsBreakdown.tabs"
                        id="returns-line"
                        :labels="['']"
                        :dataset="returnsBreakdown"
                        defaultKey="total"
                        :activeBusiness="activeRestaurant"
                        title="Sold* vs Returned"
                      />
                      <FriendlierBarChart
                        v-else
                        id="returns-bar"
                        :labels="['']"
                        :data="returnsBreakdown"
                        :activeBusiness="activeRestaurant"
                        title="Sold* vs Returned"
                      />
                      <span class="caveate">*calculated value</span>
                    </template>
                    <FriendlierLineChart
                      v-else
                      id="orders-returns-line"
                      :labels="['']"
                      :dataset="ordersReturnsBreakdown"
                      defaultKey="total"
                      :activeBusiness="activeRestaurant"
                      title="Orders and Returns"
                    />
                  </div>
                  <div
                    class="chart-container table-container column is-half-desktop is-full-mobile is-vcentered is-centered"
                  >
                    <div class="title is-family-secondary">
                      Ordering Frequency
                    </div>
                    <div
                      v-if="
                        orderingFrequency.keys &&
                        orderingFrequency.keys.size > 0
                      "
                      class="table"
                    >
                      <table>
                        <thead>
                          <th><span>Week</span></th>
                          <th v-for="key of orderingFrequency.keys" :key="key">
                            <span>{{ key }}</span>
                          </th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="value of orderingFrequency.values"
                            :key="value.week"
                          >
                            <th>{{ value.week }}</th>
                            <td
                              v-for="key of orderingFrequency.keys"
                              :key="`${value.week}-${key}`"
                            >
                              {{ key in value ? value[key] : 0 }}
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- <div v-else>No data for time period</div> -->
                    <!-- <FriendlierLineChart
                  v-if="orderingFrequency && orderingFrequency.tabs"
                  id="orders-line"
                  :labels="['']"
                  :tabs="orderingFrequency.tabs"
                  :dataset="orderingFrequency"
                  defaultKey="total"
                  :activeBusiness="activeRestaurant"
                  title="Order Breakdown"
                />
                <FriendlierBarChart
                  v-else
                  id="orders-bar"
                  :labels="['']"
                  :data="orderingFrequency"
                  :activeBusiness="activeRestaurant"
                  title="Order Breakdown (boxes)"
                /> -->
                  </div>
                </div>
                <div
                  v-else-if="premiumData && !enoughData"
                  class="is-flex is-justify-content-center is-align-items-center"
                >
                  <p style="text-align: center">
                    Sorry! Not enough data - please wait until at least a month
                    of data has been collected.
                  </p>
                </div>
                <div v-else-if="!premiumData" class="has-text-centered">
                  <h1 class="is-full is-centered is-bold is-denim">
                    {{ $t("analytics.more-details") }}
                  </h1>
                  <a
                    class="button is-medium is-rounded is-sunflower-back is-denim is-family-secondary"
                    href="mailto:info@friendlier.ca?CC=ian@friendlier.ca&Subject=Improving%20Analytics&Body=Let%20us%20know%20which%20metrics%20would%20be%20useful%20to%20your%20business%21"
                    style="margin-block: 5%; text-align: center"
                    target="_blank"
                  >
                    {{ $t("analytics.click-here") }}
                  </a>
                </div>
              </div>

              <hr class="is-full mx-5" />
              <DataPanel
                type="full"
                bgColour="denim"
                :icon="[
                  BowlingIconCloud,
                  null,
                  TreeIconCloud,
                  null,
                  BottleIconCloud,
                ]"
                :data="
                  Object.keys(basicStats.equivalencies).map((key) => ({
                    title: $t(key),
                    value: basicStats.equivalencies[key],
                  }))
                "
                :delim="true"
                :title="$t('analytics.thats-like')"
              >
                <span class="data-delim">
                  <div class="divider" />
                  <p class="text">{{ $t("or") }}</p>
                  <div class="divider" />
                </span>
              </DataPanel>
            </div>

            <div v-else>
              <NoData
                centered
                contained
                large
                :name="activeRestaurant.data.displayName"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
  </template>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { isEqual } from "lodash";
import html2pdf from "html2pdf.js";

import { getUser, useAnalytics, useFirebase } from "@/composables";
import {
  clientStore,
  userStore,
  permissionStore,
  collectionStore,
} from "@/store";
import {
  Loading,
  NoData,
  DataPanel,
  FriendlierDoughnutChart,
  FriendlierLineChart,
  FriendlierBarChart,
  NotPermitted,
  UnderCons,
} from "@/components";
import {
  BinIconCloud,
  EarthIconCloud,
  ReuseIconCloud,
  WaterIconCloud,
  BowlingIconCloud,
  TreeIconCloud,
  BottleIconCloud,
  TransportEmissionsIconCloud,
} from "@/assets";
import {
  getBasicStats,
  getRecentDataset,
  checkAccountTier,
  logError,
} from "@/utils";

let colors = {
  denim: "#032673",
  ocean: "#3bbaf2",
  cloud: "#3bbaf2",
  stormcloud: "#969696",
  sunflower: "#f0ba30",
  sunrise: "#fa7854",
};

let statsIcons = {
  // clean up
  "GHG Emissions Diverted": EarthIconCloud,
  "Émissions de GES détournées": EarthIconCloud,
  "Waste Diverted": BinIconCloud,
  "Déchets détournés": BinIconCloud,
  "Water Savings": WaterIconCloud,
  "Eau économisée": WaterIconCloud,
  "Transport Emissions": TransportEmissionsIconCloud,
  "Émissions des transports": TransportEmissionsIconCloud,
};

/***** view controllers ******/
const loading = ref(true);
const premiumData = ref(false);
const noData = ref(true);
const permitted = ref(false);
const tab = ref("total");

const router = useRouter();
const analytics = useAnalytics();
const _fb = useFirebase();

/******* user data *********/
const isUnderConstruction = ref(false);

/******* user data *********/
const { user } = getUser(); //unused

/******* restaurant lists *******/
const restaurants = ref({});
const activeRestaurant = ref({});

/******* analytic data *******/
const basicStats = ref({}); // total_returns, ghg, water, waste
const dataset = ref({}); //unused // holds the IDs for the most recent dataset to fetch from

const activeLocation = ref(); // NOTE try to replace with store value
const isMultiOutlet = ref();
const enoughData = ref(true);

// clean up
const totalData = ref({});
const activeUsers = ref(0);
const repeatUsers = ref(0);
const newUsers = ref(0);
const cycleTime = ref(0);
const returnRate = ref(0);
const containersReturned = ref(0);
const containersSold = ref(0);

const originalDataMonth = ref({});
//monthly
const activeUsersMonth = ref(0);
const repeatUsersMonth = ref(0);
const newUsersMonth = ref(0);
const cycleTimeMonth = ref(0);
const returnRateMonth = ref(0);
const outletMonth = ref({});
const containersReturnedMonth = ref(0);

//Dates
const datesMap = ref(new Map());
const selectedMonth = ref(null);

const dates = ref([]);
const startWeek = ref();
const startWeekOptions = ref([]);
const endWeek = ref();
const endWeekOptions = ref([]);

const outletReturns = ref({});
const orderingFrequency = ref({});
const returnsBreakdown = ref({});
const ordersReturnsBreakdown = ref({});

const currentTab = ref("");
const totalStats = ref({});
const allStats = ref({});

const fetchedTotals = ref({});

let loc = "en";

/**
 * Complete initial setup of component
 * @state {Object} activeLocation
 */

const activeChip = ref("current");

function onMenuItemClick(chip) {
  activeChip.value = chip;
}

onMounted(async () => {
  document.title = "Friendlier | Analytics";
  try {
    selectTotals();
    await clientStore.initialize();
    await userStore.initialize();
    await collectionStore.initialize();
    await permissionStore.initialize(userStore.accountTier);

    try {
      if (!userStore.portalApproved) {
        router.push({ path: "/business-pending" });
        // router.push({ path: "/onboarding" });

        return;
      }
    } catch (err) {
      logError(
        `Analytics.onMounted: Failed to check for portal approval. ${err.toString()}`
      );
      router.push("login");
    }

    try {
      if (permissionStore.can("viewAnalytics")) {
        permitted.value = true;
      } else {
        loading.value = false;
        return;
      }
    } catch (err) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    await getUserRestaurants();

    try {
      const analyticsData = await analytics.getAnalytics();
      if (
        analyticsData &&
        typeof analyticsData.isUnderConstruction !== "undefined"
      ) {
        isUnderConstruction.value = analyticsData.isUnderConstruction;
      }

      if (
        userStore.uid == "rjLsjdbJ6lZUpW3i8ey4B3enf363" ||
        userStore.uid == "W6i8CHPJRtOAAQDK35VslIwfgzP2" ||
        userStore.uid == "QcjiQr0urEVDfHXlNkR75sCwOxk2" ||
        userStore.uid == "lUg4EMjcMhVKLvRXsCRVb6COHT83" ||
        userStore.uid == "qZPsIsMn40NHwtwcFnqoUPgYu9I3"
      ) {
        isUnderConstruction.value = false;
      }
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }

    storeOriginalData();

    // console.log("Original data stored:", originalDataMonth.value);

    // Impact Data Fetch
    // newData.value = await analytics.grabWeeklyData("2023-07-31", "Scotiabank");

    // Total Data
    // await getTotalData();

    // tab.value = "total";
    // await setValues();

    // loading.value = false;
  } catch (err) {
    logError(
      `Analytics.onMounted: Failed to setup analytics page. ${err.toString()}`
    );
  }
});

function storeOriginalData() {
  originalDataMonth.value = {
    basicStats: { ...basicStats.value },
    activeUsersMonth: activeUsersMonth.value,
    repeatUsersMonth: repeatUsersMonth.value,
    newUsersMonth: newUsersMonth.value,
    cycleTimeMonth: cycleTimeMonth.value,
    returnRateMonth: returnRateMonth.value,
    outletMonth: { ...outletMonth.value },
    containersReturnedMonth: containersReturnedMonth.value,
    returnRate: returnRate.value,
    containersReturned: containersReturned.value,
    containersSold: containersSold.value,
  };
}

watch(activeChip, (newChip) => {
  if (newChip === "total") {
    setSummedValuesFromTotalStats();
  } else {
    restoreOriginalValues();
  }
});

const exportAsPDF = () => {
  const element = document.querySelector(".items");
  const itemElement = document.querySelector(".item");
  const premiumDataElement = document.querySelector(".premium-data");

  if (itemElement) {
    itemElement.style.display = "none";
  }

  let originalMarginBottom;
  if (premiumDataElement) {
    originalMarginBottom = premiumDataElement.style.marginBottom;
    premiumDataElement.style.marginBottom = "80px";
  }

  let options = {
    margin: [0, 15, 10, 15],
    filename: "analytics-export.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 3 },
    jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
  };

  html2pdf()
    .from(element)
    .set(options)
    .save()
    .then(() => {
      if (itemElement) {
        itemElement.style.display = "";
      }

      if (premiumDataElement) {
        premiumDataElement.style.marginBottom = originalMarginBottom;
      }
    });
};

function restoreOriginalValues() {
  // Restoring the basic stats
  basicStats.value = { ...originalDataMonth.value.basicStats };

  // Restoring other monthly values
  activeUsersMonth.value = originalDataMonth.value.activeUsersMonth;
  repeatUsersMonth.value = originalDataMonth.value.repeatUsersMonth;
  newUsersMonth.value = originalDataMonth.value.newUsersMonth;
  cycleTimeMonth.value = originalDataMonth.value.cycleTimeMonth;
  returnRateMonth.value = originalDataMonth.value.returnRateMonth;
  outletMonth.value = { ...originalDataMonth.value.outletMonth };
  containersReturnedMonth.value =
    originalDataMonth.value.containersReturnedMonth;
  returnRate.value = originalDataMonth.value.returnRate;
  containersReturned.value = originalDataMonth.value.containersReturned;
  containersSold.value = originalDataMonth.value.containersSold;
  setupReturnsBreakdown(allStats.value);
  setupOrderFrequency(allStats.value);
}

async function setSummedValuesFromTotalStats() {
  if (!allStats.value) return;

  resetValuesToZero();

  let totalCycleTime = 0;
  let count = 0;

  Object.entries(allStats.value).forEach(([outletGroup, weeks]) => {
    if (outletGroup === "total") return;

    weeks.forEach((weekData) => {
      activeUsersMonth.value += weekData.new_users + weekData.existing_users;
      repeatUsersMonth.value += weekData.returning_users;
      newUsersMonth.value += weekData.new_users;
      containersReturnedMonth.value += weekData.total_returns;

      if (weekData.avgerage_time_between_distribution_and_return != null) {
        totalCycleTime +=
          weekData.avgerage_time_between_distribution_and_return;
        count++;
      }

      if ("sold_override" in weekData && weekData.sold_override != null) {
        containersSold.value += weekData.sold_override;
      } else {
        Object.entries(weekData.boxes_opened || {}).forEach(
          ([boxID, quantity]) => {
            let product = collectionStore.products.find(
              (p) => p.boxIDTag === boxID
            );
            if (product) {
              containersSold.value += quantity * product.containersPerCase;
            }
          }
        );
      }
    });
  });

  // console.log(containersSold.value);
  // console.log(containersReturnedMonth.value);

  if (count > 0) {
    cycleTimeMonth.value =
      Math.round((totalCycleTime / count / 60 / 60 / 24) * 10) / 10;
  }

  setupReturnsBreakdownSum(allStats.value);
  setupOrderFrequencySum(allStats.value);

  basicStats.value = await getBasicStats(containersReturnedMonth.value);

  returnRate.value = Math.round(
    (containersReturnedMonth.value / containersSold.value) * 100
  );
}

function resetValuesToZero() {
  returnRate.value = 0;
  containersReturned.value = 0;
  containersSold.value = 0;
  outletReturns.value = {};
  activeUsersMonth.value = 0;
  repeatUsersMonth.value = 0;
  newUsersMonth.value = 0;
  cycleTimeMonth.value = 0;
  containersReturnedMonth.value = 0;
  outletMonth.value = {};
}
// NOTE define need - centralize
watch(user, () => {
  if (!user.value) {
    router.push({ name: "Login" });
  }
});

/**
 * React to changes in the active restaurant and update numbers accordingly
 * @state {Boolean} loading
 * @state {Boolean} noData
 *
 */
watch(activeRestaurant, async () => {
  try {
    // noData.value = true;
    // basicStats.value = await getBasicStats(activeRestaurant.value["data"]["returnedFrom"]);
    // if (basicStats.value) noData.value = false;

    //Monthly
    tab.value = "monthly";
    currentTab.value = "monthly";
    activeChip.value = "current";

    // tab.value = "monthly";

    // selectTotals();
    premiumData.value = await permissionStore.can("viewDetailedAnalytics");

    try {
      await getTotalData();
    } catch (e) {
      console.log("failed to get totaldata");
    }

    try {
      await setValues();
    } catch (e) {
      console.log("failed to set data");
    }

    storeOriginalData();
    // console.log("Original data stored:", originalDataMonth.value);

    loading.value = false;
    noData.value = false;
  } catch (e) {
    logError(
      `analtics.watchActiveRestaurant: Failed to update for new restaurant. ${e.toString()}`
    );
  }
});

/**
 * Watch for changes in the startWeek value, trim the ending options to not allow inverse time range
 * @state {array} endWeekOptions
 * @state {string} startWeek
 */
watch(startWeek, () => {
  if (startWeek.value > endWeek.value) {
    endWeek.value = startWeek.value;
  }
  endWeekOptions.value = endWeekOptions.value.filter(
    (e) => e >= startWeek.value
  );
});

/**
 * Watch for changes in the endWeek value, trim the starting options to not allow inverse time range
 * @state {array} startWeekOptions
 * @state {string} endWeek
 */
watch(endWeek, () => {
  if (endWeek.value < startWeek.value) {
    endWeek.value = startWeek.value;
  }
  startWeekOptions.value = startWeekOptions.value.filter(
    (e) => e <= endWeek.value
  );
});

/**
 * Update the active restaurant according to the user's selection
 * @param {HtmlSelectEvent} e
 * @state {Object} activeRestaurant
 */
async function changeActiveRestaurant(e) {
  for (let res in restaurants.value) {
    if (restaurants.value[res]["data"]["displayName"] == e.target.value) {
      activeRestaurant.value = restaurants.value[res];
      clientStore.setActiveClientByPlaceID(
        restaurants.value[res]["data"]["placeID"]
      );
      break;
    }
  }
}

/**
 * Switch to the weekly view
 */
async function selectWeeks() {
  tab.value = "weekly";
  await setValues();
}

/**
 * Switch to the monthly view
 */
async function selectMonthly() {
  tab.value = "monthly";
  currentTab.value = "monthly";
  await setValues();
}

/**
 * Switch to the total view
 */
async function selectTotals() {
  tab.value = "total";
  currentTab.value = "total";
  await setValues();
}

async function fetchDataForSelectedMonth() {
  if (selectedMonth.value) {
    const filteredDates = datesMap.value.get(selectedMonth.value);

    if (filteredDates && filteredDates.length > 0) {
      filteredDates.sort((a, b) => new Date(a) - new Date(b));
    }

    if (filteredDates && filteredDates.length > 0) {
      let stats = await analytics.getOutletAnalytics(
        activeRestaurant.value["data"]["placeID"],
        [filteredDates[0], filteredDates[filteredDates.length - 1]],
        isMultiOutlet.value
      );

      if (stats.data && stats.data.total) {
        totalStats.value = stats.data.total; // Store only the 'totals'
        allStats.value = stats.data;
      }

      // console.log(stats.data);
      await buildDashboard("monthly", stats.data);
    }
  }
}

watch(selectedMonth, async (newVal, oldVal) => {
  activeChip.value = "current";
  if (newVal && newVal !== oldVal && oldVal !== null) {
    await fetchDataForSelectedMonth();
  }
});

/**
 * Fetches the total stats of the current placeID from the database
 * @state {object} TotalData
 * @state {array[string]} Dates
 * @state {boolean} enoughData
 * @state {boolean} isMultiOutlet
 */
async function getTotalData() {
  try {
    let response = await analytics.getOutlet(clientStore.activePlaceID, true);
    // console.log(response.data);
    if (response == null || response.error) {
      throw new Error(
        `Failed to retrieve data from API. ${response.message ?? ""}`
      );
    }

    // console.log(response.data);

    totalData.value = response.data;

    // Fetch avaliable data entries
    dates.value = totalData.value["analytic_entries"];

    enoughData.value = dates.value.length >= 4;
    isMultiOutlet.value = totalData.value["outlet_group"] ? true : false;
  } catch (e) {
    logError(
      `analytics.getTotalData: Failed to get total data. ${e.toString()}`
    );
  }
}

async function setValues() {
  try {
    if (tab.value == "total") {
      dates.value.sort();
      startWeekOptions.value = dates.value;
      endWeekOptions.value = dates.value;

      endWeek.value = dates.value[dates.value.length - 1];
      startWeek.value = dates.value[0];

      if (!totalData.value || isEqual(totalData.value, {}))
        throw new Error(`No Data to load from`);

      await buildDashboard("total", totalData.value);
    } else if (tab.value == "monthly") {
      // Sort dates
      dates.value.sort();
      await fetchDataForSelectedMonth();

      // console.log(monthOptions.value);

      // const currentDate = new Date();
      // const oneMonthAndAWeekAgo = new Date(
      //   currentDate.getFullYear(),
      //   currentDate.getMonth() - 1,
      //   currentDate.getDate()
      // );

      // oneMonthAndAWeekAgo.setDate(3); // Set to 3rd of last month

      // const startWeekFormatted = oneMonthAndAWeekAgo
      //   .toISOString()
      //   .split("T")[0];

      // oneMonthAndAWeekAgo.setMonth(currentDate.getMonth());
      // oneMonthAndAWeekAgo.setDate(3); // Set to 3rd of current month

      // const endWeekFormatted = oneMonthAndAWeekAgo.toISOString().split("T")[0];

      // // Filter dates for the specified range
      // const filteredDates = dates.value.filter(
      //   (date) => date >= startWeekFormatted && date <= endWeekFormatted
      // );

      // let stats = await analytics.getOutletAnalytics(
      //   activeRestaurant.value["data"]["placeID"],
      //   [filteredDates[0], filteredDates[filteredDates.length - 1]],
      //   isMultiOutlet.value
      // );

      // // Build the dashboard for the specified range
      // await buildDashboard("monthly", stats.data);
    } else {
      let stats = await analytics.getOutletAnalytics(
        clientStore.activeClient.data.placeID,
        [startWeek.value, endWeek.value],
        isMultiOutlet.value
      );
      // console.log(stats);
      if (stats.error) throw new Error(stats.message);

      if (startWeek.value == endWeek.value) {
        tab.value = "total";
        await buildDashboard("single", stats.data);
      } else {
        await buildDashboard("multi", stats.data);
      }
    }
  } catch (e) {
    logError(
      `RestaurantPortal.setValues: Failed to set values in component. ${e.toString()}`
    );
  }
}

watch(dates, () => {
  if (dates.value.length > 0) {
    setupMonthOptionsAndSelectLast();
  }
});

function setupMonthOptionsAndSelectLast() {
  updateMonthOptions();
  if (monthOptions.value.length > 0) {
    selectedMonth.value = monthOptions.value[monthOptions.value.length - 1];
    fetchDataForSelectedMonth();
  }
}

function updateMonthOptions() {
  datesMap.value.clear();
  dates.value.sort();

  let currentMonthYear = `${new Date().toLocaleString("default", {
    month: "long",
  })} ${new Date().getFullYear()}`;

  dates.value.forEach((dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthYearKey = `${date.toLocaleString("default", {
      month: "long",
    })} ${year}`;

    if (year > 2023 || (year === 2023 && monthIndex >= 9)) {
      if (!datesMap.value.has(monthYearKey)) {
        datesMap.value.set(monthYearKey, []);
      }

      if (day > 3) {
        datesMap.value.get(monthYearKey).push(dateStr);
      }
    }
  });

  datesMap.value.forEach((items, monthYearKey) => {
    if (items.length < 1) return;
    const [_, year] = monthYearKey.split(" ");
    const lastDate = new Date(items[items.length - 1]);
    const nextMonthFirstDate = new Date(
      lastDate.getFullYear(),
      lastDate.getMonth() + 1,
      1
    );

    for (let i = 0; i < 4; i++) {
      let nextDate = new Date(nextMonthFirstDate);
      nextDate.setDate(nextDate.getDate() + i);
      const nextDateStr = nextDate.toISOString().split("T")[0];

      if (dates.value.includes(nextDateStr)) {
        items.push(nextDateStr);
      }
    }
  });

  const monthYearPairs = Array.from(datesMap.value.entries());

  const sortedValidMonths = monthYearPairs
    .filter(([_, dates]) => dates.length >= 1)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .map(([monthYear, _]) => monthYear);

  monthOptions.value = sortedValidMonths;
}

const monthOptions = ref([]);

/**
 * Sum the numbers in the array and return the total
 * @param {number[]} numbers
 */
function sum(numbers) {
  let sum = 0;
  try {
    for (let num of numbers) {
      sum += num;
    }
  } catch (err) {
    logError(`analytics.sum: Failed to calculate sum. ${err.toString()}`);
  }
  return sum;
}

function setupOrderFrequencySum(items) {
  try {
    orderingFrequency.value = { keys: new Set(), values: [] };

    Object.entries(items).forEach(([outletGroup, weeksData]) => {
      if (outletGroup === "total") return;

      weeksData.forEach((weekData) => {
        if (weekData["order_details"] == null) return;

        const weekKey = weekData["week"].split("T")[0];

        if (!orderingFrequency.value.values.some((x) => x.week === weekKey)) {
          orderingFrequency.value.values.push({ week: weekKey });
        }
        const weekEntry = orderingFrequency.value.values.find(
          (x) => x.week === weekKey
        );

        Object.entries(weekData["order_details"]).forEach(
          ([productID, quantity]) => {
            orderingFrequency.value.keys.add(productID);
            weekEntry[productID] = (weekEntry[productID] || 0) + quantity;
          }
        );
      });
    });

    orderingFrequency.value.values.sort((a, b) => a.week.localeCompare(b.week));
  } catch (e) {
    logError(`Analytics.SetupOrderFrequency: Failed to setupOrderFrequencySum`);
  }
}

/**
 *
 * @param {*} stats
 */
function setupOrderFrequency(stats) {
  try {
    // console.log(stats);
    orderingFrequency.value = { keys: new Set(), values: [] };

    for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
      if (entry["order_details"] == null) continue;
      orderingFrequency.value.values.push({
        ...entry["order_details"],
        week: entry["week"].split("T")[0],
      });
      Object.keys(entry["order_details"]).forEach((x) =>
        orderingFrequency.value.keys.add(x)
      );
    }
    // Build out the line charts
    // let datasets = {
    //   total: {},
    //   "by container": {},
    // };

    // for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
    //   let sum = 0;
    //   if (entry["order_details"] != null) {
    //     for (let [key, val] of Object.entries(entry["order_details"])) {
    //       if (!(key in datasets["by container"])) {
    //         datasets["by container"][key] = {};
    //       }
    //       datasets["by container"][key][entry["week"].split("T")[0]] = val;
    //       sum += val;
    //     }
    //   }
    //   datasets["total"][entry["week"].split("T")[0]] = sum;
    // }

    // orderingFrequency.value = {
    //   tabs: ["by container", "total"],
    //   datasets: {
    //     total: { total: datasets["total"] },
    //     "by container": datasets["by container"],
    //   },
    // };
  } catch (e) {
    logError(`Analytics.SetupOrderFrequency: Failed to setupOrderFrequency`);
  }
}

function setupReturnsBreakdownSum(items) {
  // console.log("SetupReturnsBreakdownSum");
  try {
    let datasets = { returned: {}, sold: {} };

    Object.entries(items).forEach(([outletGroup, weeksData]) => {
      if (outletGroup === "total") return;
      weeksData.forEach((weekData) => {
        const weekKey = weekData["week"].split("T")[0];

        datasets["returned"][weekKey] =
          (datasets["returned"][weekKey] || 0) + weekData["total_returns"];

        if (!datasets["sold"][weekKey]) {
          datasets["sold"][weekKey] = 0;
        }

        for (let product of collectionStore.products) {
          if (
            weekData["boxes_opened"] &&
            product["boxIDTag"] in weekData["boxes_opened"]
          ) {
            datasets["sold"][weekKey] +=
              weekData["boxes_opened"][product["boxIDTag"]] *
              product["containersPerCase"];
          }
        }
      });
    });
    // console.log(datasets["returned"]);

    returnsBreakdown.value = {
      tabs: ["total"],
      datasets: {
        total: { Returned: datasets["returned"], "Sold*": datasets["sold"] },
      },
    };
  } catch (e) {
    console.log(e);
    logError(
      `Analytics.setupReturnsBreakdownSum: Failed to setup Returns Breakdown Sum`
    );
  }
}

/**
 *
 * @param {*} stats
 */
function setupReturnsBreakdown(stats) {
  // console.log("SetupReturnsBreakdown");
  try {
    let datasets = {
      returned: {},
      sold: {},
    };

    // for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
    //   datasets["returned"][entry["week"].split("T")[0]] = entry["total_returns"];
    //   datasets["sold"][entry["week"].split("T")[0]] = 0;

    //   if ("boxes_opened_shifted" in entry) {
    //     if (entry["boxes_opened_shifted"] == null) {
    //       datasets["sold"][entry["week"].split("T")[0]] = 0;
    //     } else {
    //       for (let product of collectionStore.products) {
    //         if (!(product["boxIDTag"] in entry["boxes_opened_shifted"])) continue;

    //         datasets["sold"][entry["week"].split("T")[0]] +=
    //           entry["boxes_opened_shifted"][product["boxIDTag"]] * product["containersPerCase"];
    //       }
    //     }
    //   } else {
    //     for (let product of collectionStore.products) {
    //       if (!(product["boxIDTag"] in entry["boxes_opened"])) continue;

    //       datasets["sold"][entry["week"].split("T")[0]] +=
    //         entry["boxes_opened"][product["boxIDTag"]] * product["containersPerCase"];
    //     }
    //   }
    // }

    // console.log(activeRestaurant.value);

    for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
      datasets["returned"][entry["week"].split("T")[0]] =
        entry["total_returns"];
      datasets["sold"][entry["week"].split("T")[0]] = 0;

      for (let product of collectionStore.products) {
        if (!(product["boxIDTag"] in entry["boxes_opened"])) continue;

        datasets["sold"][entry["week"].split("T")[0]] +=
          entry["boxes_opened"][product["boxIDTag"]] *
          product["containersPerCase"];
      }
    }

    // console.log(datasets["returned"]);

    returnsBreakdown.value = {
      tabs: ["total"],
      datasets: {
        total: {
          Returned: datasets["returned"],
          "Sold*": datasets["sold"],
        },
      },
    };
  } catch (e) {
    logError(
      `Analytics.setupReturnsBreakdown: Failed to setupReturnsBreakdown`
    );
  }
}

function setupOrdersReturnsBreakdown(stats) {
  try {
    let datasets = {
      returned: {},
      orders: {},
    };

    for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
      datasets["returned"][entry["week"].split("T")[0]] =
        entry["total_returns"];
      datasets["orders"][entry["week"].split("T")[0]] = 0;

      if (!entry["order_details"]) continue;

      for (let product of collectionStore.products) {
        if (!(product["boxIDTag"] in entry["order_details"])) continue;

        datasets["orders"][entry["week"].split("T")[0]] +=
          entry["order_details"][product["boxIDTag"]] *
          product["containersPerCase"];
      }
    }

    ordersReturnsBreakdown.value = {
      tabs: ["total"],
      datasets: {
        total: {
          Returned: datasets["returned"],
          Orders: datasets["orders"],
        },
      },
    };

    // console.log(ordersReturnsBreakdown.value);
  } catch (e) {
    logError(
      `Analytics.setupOrdersReturnsBreakdown: Failed to ordersReturnsBreakdown`
    );
  }
}

/**
 * Collect Restaurants Associate with User
 */
const getUserRestaurants = async () => {
  // Collect user's restaurants
  restaurants.value = {};

  if (Array.isArray(userStore.placeID) == true) {
    for (let placeID of userStore.placeID) {
      let res = await _fb.getRestaurant(placeID);
      if (isEqual(res, {})) continue;
      restaurants.value[placeID] = res;
    }
  } else {
    restaurants.value[userData.placeID] = await _fb.getRestaurant(
      userData.placeID
    );
  }

  const matchingPlaceID = Object.keys(restaurants.value).find(
    (placeID) => placeID === clientStore.activePlaceID
  );

  activeRestaurant.value = restaurants.value[matchingPlaceID];
};

/**
 * Loads the dashboard variables with the provided stats accoring to dashboard type
 * @param {['total','single','multi']} type Style of Dashboard to render
 * @param {Object} stats stat object
 */
async function buildDashboard(type, stats) {
  if (type == "total") {
    activeUsers.value = stats["existing_users"] + stats["new_users"];
    repeatUsers.value = stats["returning_users"];
    newUsers.value = stats["new_users"];

    cycleTime.value =
      Math.round(
        (stats["avgerage_time_between_distribution_and_return"] /
          60 /
          60 /
          24) *
          10
      ) / 10;
    containersReturned.value = stats["lifetime_returns"];

    containersSold.value = 0;
    if (stats["boxes_opened"])
      for (let product of collectionStore.products) {
        if (!(product["boxIDTag"] in stats["boxes_opened"])) continue;
        containersSold.value +=
          stats["boxes_opened"][product["boxIDTag"]] *
          product["containersPerCase"];
      }

    // console.log(containersSold.value, stats["total_returns"]);
    returnRate.value =
      Math.round((stats["total_returns"] / containersSold.value) * 100 * 10) /
      10;

    outletReturns.value = {};
    if (isMultiOutlet.value) {
      outletReturns.value[stats["name"]] = stats["total_returns"];
      for (let outlet of stats["group"]) {
        outletReturns.value[outlet["name"]] = outlet["total_returns"];
      }
    }

    // console.log(dates.value);
    basicStats.value = await getBasicStats(containersReturned.value);

    let outletStats = await analytics.getOutletAnalytics(
      activeRestaurant.value["data"]["placeID"],
      dates.value.slice(-5),
      false
    );
    if (outletStats.error) throw new Error(outletStats.message);

    // console.log(outletStats);

    setupOrderFrequency(outletStats["data"]);
    setupReturnsBreakdown(outletStats["data"]);
  } else if (type == "monthly") {
    activeUsers.value = {};
    repeatUsers.value = {};
    newUsers.value = {};
    cycleTime.value = {};
    containersReturned.value = {};
    containersSold.value = {};
    outletReturns.value = {};

    activeUsersMonth.value = 0;
    repeatUsersMonth.value = 0;
    newUsersMonth.value = 0;
    cycleTimeMonth.value = 0;
    containersReturnedMonth.value = 0;
    outletMonth.value = {};

    let soldOverride = false;

    let returnRateSold = 0;
    let returnRateReturn = 0;

    for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
      let weekKey = entry["week"].split("T")[0];
      activeUsers.value[weekKey] = entry["existing_users"] + entry["new_users"];
      repeatUsers.value[weekKey] = entry["existing_users"];
      newUsers.value[weekKey] = entry["new_users"];
      cycleTime.value[weekKey] =
        Math.round(
          (entry["avgerage_time_between_distribution_and_return"] /
            60 /
            60 /
            24) *
            10
        ) / 10;

      containersReturned.value[weekKey] = entry["total_returns"];
      returnRateReturn += entry["total_returns"];
      containersSold.value[weekKey] = 0;

      //Month
      activeUsersMonth.value += entry["existing_users"] + entry["new_users"];
      repeatUsersMonth.value += entry["existing_users"];
      newUsersMonth.value += entry["new_users"];
      containersReturnedMonth.value += entry["total_returns"];

      cycleTimeMonth.value =
        Math.round(
          (entry["avgerage_time_between_distribution_and_return"] /
            60 /
            60 /
            24) *
            10
        ) / 10;

      if (
        entry["sold_override"] != undefined &&
        entry["sold_override"] != null
      ) {
        soldOverride = true;
        containersSold.value[weekKey] = entry["sold_override"];
        returnRateSold += entry["sold_override"];
      } else {
        for (let product of collectionStore.products) {
          if (!(product["boxIDTag"] in entry["boxes_opened"])) continue;
          containersSold.value[weekKey] +=
            entry["boxes_opened"][product["boxIDTag"]] *
            product["containersPerCase"];
          returnRateSold +=
            entry["boxes_opened"][product["boxIDTag"]] *
            product["containersPerCase"];
          // if (!(product["boxIDTag"] in entry["boxes_opened_shifted"])) continue;
          // containersSold.value[weekKey] += entry["boxes_opened_shifted"][product["boxIDTag"]] * product["containersPerCase"];
          // returnRateSold += entry["boxes_opened_shifted"][product["boxIDTag"]] * product["containersPerCase"];
        }
      }

      if (isMultiOutlet.value) {
        for (let outlet in stats) {
          if (outlet == "total") continue;
          let outletWeekStats = {};
          let outletWeekKey = "";

          for (let outletEntry of stats[outlet]) {
            outletWeekKey = outletEntry["week"].split("T")[0];
            if (outletWeekKey == weekKey) {
              outletWeekStats = outletEntry;
              break;
            }
          }
          if (isEqual(outletWeekStats, {})) continue;

          if (
            Object.keys(outletReturns.value).includes(outletWeekStats["name"])
          ) {
            outletReturns.value[outletWeekStats["name"]] +=
              outletWeekStats["total_returns"];
          } else {
            outletReturns.value[outletWeekStats["name"]] =
              outletWeekStats["total_returns"];
          }
        }
      }
    }

    returnRate.value = Math.round((returnRateReturn / returnRateSold) * 100);
    basicStats.value = await getBasicStats(
      sum(Object.values(containersReturned.value))
    );
    setupOrderFrequency(stats);

    if (soldOverride) {
      returnsBreakdown.value = undefined;
      ordersReturnsBreakdown.value = {};
      setupOrdersReturnsBreakdown(stats);
    } else {
      ordersReturnsBreakdown.value = undefined;
      returnsBreakdown.value = {};
      setupReturnsBreakdown(stats);
    }
  } else if (type == "single") {
    let activeStats = stats[activeRestaurant.value["data"]["placeID"]][0];
    activeUsers.value =
      activeStats["existing_users"] + activeStats["new_users"];
    repeatUsers.value = activeStats["returning_users"];
    newUsers.value = activeStats["new_users"];

    cycleTime.value =
      Math.round(
        (activeStats["avgerage_time_between_distribution_and_return"] /
          60 /
          60 /
          24) *
          10
      ) / 10;
    containersReturned.value = activeStats["lifetime_returns"];

    containersSold.value = 0;
    for (let product of collectionStore.products) {
      if (!(product["boxIDTag"] in activeStats["boxes_opened_shifted"]))
        continue;
      containersSold.value +=
        activeStats["boxes_opened_shifted"][product["boxIDTag"]] *
        product["containersPerCase"];
    }

    returnRate.value =
      Math.round(
        (activeStats["total_returns"] / containersSold.value) * 100 * 10
      ) / 10;

    if (isMultiOutlet.value) {
      for (let outlet in stats) {
        if (outlet == "total") continue;
        outletReturns.value[stats[outlet][0]["name"]] =
          stats[outlet][0]["total_returns"];
      }
    }

    // orderingFrequency.value = {};
    // for (let [key, val] of Object.entries(activeStats["order_details"])) {
    //   orderingFrequency.value[key] = [val];
    // }

    orderingFrequency.value = { keys: new Set(), values: [] };
    let weeklyOrderStats = {};
    for (let [key, val] of Object.entries(activeStats["order_details"])) {
      weeklyOrderStats[key] = val;
      orderingFrequency.value.keys.add(key);
    }
    weeklyOrderStats["week"] = activeStats["week"].split("T")[0];
    orderingFrequency.value.values.push(weeklyOrderStats);

    let sold = 0;
    for (let product of collectionStore.products) {
      if (!(product["boxIDTag"] in activeStats["boxes_opened_shifted"]))
        continue;
      sold +=
        activeStats["boxes_opened_shifted"][product["boxIDTag"]] *
        product["containersPerCase"];
    }
    returnsBreakdown.value = {
      Returned: [activeStats["total_returns"]],
      "Sold*": [sold],
    };

    basicStats.value = await getBasicStats(containersReturned.value);
  } else if (type == "multi") {
    activeUsers.value = {};
    repeatUsers.value = {};
    newUsers.value = {};
    cycleTime.value = {};
    returnRate.value = {};
    containersReturned.value = {};
    containersSold.value = {};
    outletReturns.value = {};

    for (let entry of stats[activeRestaurant.value["data"]["placeID"]]) {
      let weekKey = entry["week"].split("T")[0];
      activeUsers.value[weekKey] = entry["existing_users"] + entry["new_users"];
      repeatUsers.value[weekKey] = entry["existing_users"];
      newUsers.value[weekKey] = entry["new_users"];
      cycleTime.value[weekKey] =
        Math.round(
          (entry["avgerage_time_between_distribution_and_return"] /
            60 /
            60 /
            24) *
            10
        ) / 10;

      containersReturned.value[weekKey] = entry["lifetime_returns"];
      containersSold.value[weekKey] = 0;

      for (let product of collectionStore.products) {
        if (!(product["boxIDTag"] in entry["boxes_opened_shifted"])) continue;
        containersSold.value[weekKey] +=
          entry["boxes_opened_shifted"][product["boxIDTag"]] *
          product["containersPerCase"];
      }
      returnRate.value[weekKey] =
        Math.round(
          (entry["total_returns"] / containersSold.value[weekKey]) * 100 * 10
        ) / 10;

      if (isMultiOutlet.value) {
        for (let outlet in stats) {
          if (outlet == "total") continue;
          let outletStats = {};
          let outletWeekKey = "";
          for (let outletEntry of stats[outlet]) {
            outletWeekKey = outletEntry["week"].split("T")[0];
            if (outletWeekKey == weekKey) {
              outletStats = outletEntry;
              break;
            }
          }
          if (!(outletStats["name"] in outletReturns.value))
            outletReturns.value[outletStats["name"]] = {};
          outletReturns.value[outletStats["name"]][outletWeekKey] =
            outletStats["total_returns"];
        }
      }
    }

    basicStats.value = await getBasicStats(
      sum(Object.values(containersReturned.value))
    );
    setupOrderFrequency(stats);
    setupReturnsBreakdown(stats);
  }

  // console.log(orderingFrequency.value);
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

h1 {
  text-align: center;
}

.data-dashboard {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  column-gap: 0.1vw;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: stretch;
  margin-bottom: 2%;

  .premium-data {
    display: flex;
    margin: auto;
    height: fit-content;
    justify-content: space-evenly;
    width: 98%;

    .chart-container {
      margin-bottom: auto;
      max-width: 100%;
    }
  }
}

.data-delim {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  margin: auto;
  height: fit-content;

  .divider {
    flex-grow: 2;
    background-color: $cloud;
    min-height: 20px;
    min-width: 1px;
  }

  .text {
    color: $cloud;
    flex-grow: 1;
  }
}

.v-chip.active {
  background-color: #032673;
  color: white;
}

.v-chip.hover {
  background-color: #032673;
  color: white;
}

.selected-month {
  font-size: 32px;
  font-family: "GibsonBold", serif !important;
  font-weight: 900;
  padding-top: 5px;
}
.date-selection {
  display: flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    max-width: 45%;
  }

  > :last-child {
    max-width: 45%;
  }

  button {
    padding: 0.7rem;
    background-color: $sunflower;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: bolder;
    display: inline-block;
    width: 20%;
    margin: 1rem;
  }

  .week-range-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    flex-wrap: wrap;

    #date-range-picker {
      width: 100%;

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        label {
          width: 10%;
          margin: 5px;
          font-size: 18px;
        }

        select {
          width: 90%;
          margin: 5px;
          padding: 7px 12px;
          border-radius: 6px;
          font-size: 18px;
        }
      }
    }

    button {
      width: 90%;
    }
  }
}

.table-container {
  max-height: 450px;
  width: 100%;

  .title {
    height: 50px;
    width: 100%;
    padding: 0.75rem;
    padding-bottom: 1%;
    color: $denim;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .table {
    overflow: auto;
    height: 100%;
    width: 100%;
    max-height: 400px;
    margin-bottom: 50px;

    table {
      white-space: nowrap;
      margin: 0;
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;

      thead {
        background-color: white;
        th {
          padding: 2px;
          position: sticky;
          top: 0;
          z-index: 1;
          min-width: 100px;
          text-align: center;

          span {
            display: inline-block;
            background: white;
            border-radius: 15px;
            padding: 7px 12px;
            width: 100%;
            border: 2px solid $denim;
          }
        }

        :first-child {
          position: sticky;
          left: 0;
          z-index: 2;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $denim;
        }
        td {
          background: white;
          padding: 7px 12px;
          text-align: center;
        }
        th {
          font-weight: 900;
          font-style: italic;
          text-align: center;

          position: sticky;
          left: 0;
          background: white;
          z-index: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .data-delim {
    display: none;
  }
  .data-dashboard {
    flex-flow: column nowrap;
    row-gap: 2vw;
    align-items: center;
    margin: auto;

    .premium-data {
      width: 80%;

      .chart-container {
        align-self: center;
      }
    }
  }
}
</style>
