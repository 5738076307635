<template>
  <div class="small-loading-container">
    <div class="small-loading-wheel" />
  </div>
</template>

<script setup>
import { ref } from "vue";
const smallloading = ref(true);
</script>

<style>
.small-loading-container {
  margin-top: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-loading-wheel {
  background-image: url("@/assets/patterns/loading.svg");
  height: 35px;
  width: 35px;
  background-position: center;
  background-size: cover;
}
</style>
