import { ref, computed } from "vue";

export const collapsed = ref(false);
export const helpModal = ref(false);
export const toggleSidebar = () => (collapsed.value = !collapsed.value);
export const toggleHelpModal = () => (helpModal.value = !helpModal.value);

if (window.innerWidth < 500) {
  collapsed.value = true;
}

export const SIDEBAR_WIDTH = 180;
export const SIDEBAR_WIDTH_COLLAPSED = 54;
export const sidebarWidth = computed(() => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`);
