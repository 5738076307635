<template>
  <div v-if="user != null" class="sidebar" :style="{ width: sidebarWidth }">
    <h1>
      <span v-if="collapsed">
        <figure class="image pl-1" style="width: 95%; max-width: 32px">
          <a href="/order"><img :src="FriendlierIconOceanCloud" /></a>
        </figure>
      </span>
      <span v-else>
        <figure class="image pt-2 pl-2" style="width: 95%">
          <a href="/order"><img :src="FriendlierBusinessLogoWhite" /></a>
        </figure>
      </span>
    </h1>
    <span class="sidebar-options">
      <span class="top-group">
        <SidebarLink to="/order" icon="fas fa-shopping-cart" class="has-text-weight-bold">
          {{ $t("sidebar.order") }}
        </SidebarLink>

        <SidebarLink to="/past-orders" icon="fas fa-history" class="has-text-weight-bold">
          {{ $t("sidebar.past-orders") }}
        </SidebarLink>

        <SidebarLink to="www.google.com" icon="fas fa-envelope" class="has-text-weight-bold" external="true">
          {{ $t("sidebar.return") }}
        </SidebarLink>

        <SidebarLink @click="handleAnalyticsClick" to="/analytics" icon="fas fa-chart-line" class="has-text-weight-bold">
          {{ $t("sidebar.analytics") }}
        </SidebarLink>
        <SidebarLink @click="handleFeedbackClick" to="/feedback" icon="fas fa-comments" class="has-text-weight-bold">
          {{ $t("input") }}
        </SidebarLink>
        <SidebarLink to="/settings" icon="fas fa-cog" class="has-text-weight-bold">
          {{ $t("sidebar.settings") }}
        </SidebarLink>
        <!-- <SidebarLink
          v-if="!isApproved"
          to="/onboarding"
          icon="fas fa-cog"
          class="has-text-weight-bold"
        >
          Onboarding
        </SidebarLink> -->
      </span>
      <span class="bottom-group">
        <LocaleSwitcher />
        <SidebarLink @click="handleClick" to="" icon="fas fa-sign-out-alt btn-text" class="has-text-weight-bold btn">
          {{ $t("logout") }}
        </SidebarLink>
        <SidebarLink
          @click.prevent="handleHelpClick"
          @click="toggleHelpModal()"
          to=""
          icon="fas fa-exclamation-triangle"
          class="has-text-weight-bold btn"
        >
          {{ $t("sidebar.help") }}
        </SidebarLink>
      </span>
    </span>

    <div class="modal" v-bind:class="{ 'is-active': helpModal }">
      <div
        class="modal-background"
        :onClick="
          () => {
            hardReset();
            toggleHelpModal();
          }
        "
      ></div>
      <div class="modal-card" style="overflow-y: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("sidebar.help") }}</p>
          <button
            class="delete"
            aria-label="close"
            :onClick="
              () => {
                hardReset();
                toggleHelpModal();
              }
            "
          ></button>
        </header>
        <form class="help-form">
          <div class="help">
            <div class="field inline-input" style="width: 49%">
              <div class="control">
                <input
                  name="nameValue"
                  id="nameHelp"
                  class="input"
                  type="text"
                  :placeholder="$t('name')"
                  :value="defaultName"
                />
              </div>
            </div>
            <div class="field inline-input" style="width: 49%; margin-left: 2%">
              <div class="control">
                <input name="emailValue" id="emailHelp" class="input" :placeholder="$t('email')" :value="defaultEmail" />
              </div>
            </div>
            <div class="field" style="width: 100%">
              <div class="control">
                <input
                  name="subjectValue"
                  id="subjectHelp"
                  class="input"
                  type="text"
                  :placeholder="$t('sidebar.subject')"
                  :value="(subjectHelp = '')"
                />
              </div>
            </div>
            <div class="field" style="width: 100%">
              <div class="control">
                <textarea
                  name="messageValue"
                  id="messageHelp"
                  class="textarea"
                  :placeholder="$t('message')"
                  :value="(messageHelp = '')"
                ></textarea>
              </div>
            </div>
            <div class="mt-4 is-family-secondary is-size-5 has-text-danger">
              {{ error }}
            </div>
            <div class="field is-grouped" style="margin-top: 2%">
              <div class="control">
                <button
                  class="button is-info is-link"
                  @click.prevent="
                    () => {
                      sendForm();
                    }
                  "
                  :disabled="wasClicked"
                >
                  {{ $t("submit") }}
                </button>
              </div>
              <div class="control"></div>
            </div>
            <p class="confirmation-text">
              {{ confirmation }}
            </p>
          </div>
        </form>
      </div>
    </div>

    <span class="collapse-icon" :class="{ 'rotate-180': collapsed }" @click="toggleSidebar">
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import moment from "moment";

import { projectFirestore, increment } from "@/firebase/config";

import SidebarLink from "./SidebarLink";
import LocaleSwitcher from "./LocaleSwitcher.vue";

import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";
import { useAPI } from "@/composables";

import { collapsed, toggleSidebar, toggleHelpModal, helpModal, sidebarWidth } from "./state";

import { FriendlierIconOceanCloud, FriendlierBusinessLogoWhite } from "@/assets";

export default {
  components: {
    SidebarLink,
    LocaleSwitcher,
  },
  setup() {
    const confirmation = ref("");
    let wasClicked = ref(false);
    let showModal = ref(false);
    const defaultName = ref("");
    const defaultEmail = ref("");
    const { user } = getUser();
    const { logout, error } = useLogout();
    const api = useAPI();
    var host = window.location.protocol + "//" + window.location.host;

    const isApproved = ref(false);

    const getData = async () => {
      if (user.value == null) {
        return null;
      }
      const uniqueId = user.value.uid.toString();
      const docRef = projectFirestore.collection("users").doc(uniqueId);

      await docRef.get().then((doc) => {
        if (doc.data().fname == null) {
          defaultName.value = doc.data().lname;
        } else if (doc.data().lname == null) {
          defaultName.value = doc.data().fname;
        } else if (doc.data().lname == null && doc.data().fname == null) {
          defaultName.value = null;
        } else {
          defaultName.value = doc.data().fname + " " + doc.data().lname;
        }
        if (doc.data().email == null) {
          defaultEmail.value = null;
        } else {
          defaultEmail.value = doc.data().email;
        }
        isApproved.value = doc.data().portalApproved;
        console.log(doc.data().portalApproved);
      });
    };

    onMounted(() => {
      getData();
    });

    const handleAnalyticsClick = async () => {
      // Increment analytics button counter
      await projectFirestore.collection("totals").doc("analytics").set({ analyticsButtonClicks: increment }, { merge: true });
    };

    const handleFeedbackClick = async () => {
      // Increment feedback button counter
      await projectFirestore.collection("totals").doc("analytics").set({ feedbackButtonClicks: increment }, { merge: true });
    };

    const handleHelpClick = async () => {
      // Increment help button counter
      await projectFirestore.collection("totals").doc("analytics").set({ helpButtonClicks: increment }, { merge: true });
    };

    //logout function
    const handleClick = async () => {
      await logout();
      if (!error.value) {
        //push back to Login
      }
    };

    const handleHelp = async () => {
      showModal = true;
    };

    function sendForm() {
      wasClicked.value = false;
      let validity = ref(false);
      const validateEmail = () => {
        if (
          String(document.getElementById("emailHelp").value)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            )
        ) {
          validity.value = true;
        } else {
          validity.value = false;
        }
      };
      validateEmail();
      if (validity.value == false) {
        error.value = $t("sidebar.email-format");
        wasClicked.value = false;
      } else if (
        nameHelp.value == null ||
        nameHelp.value == "" ||
        emailHelp.value == null ||
        emailHelp.value == "" ||
        messageHelp.value == null ||
        messageHelp.value == "" ||
        subjectHelp.value == null ||
        subjectHelp.value == ""
      ) {
        error.value = $t("sidebar.missing-field");
        wasClicked.value = false;
      } else {
        error.value = "";
        wasClicked.value = true;
      }
      if (wasClicked.value == true) {
        api.request(process.env.VUE_APP_HELP_SLACK_BOT_URL, "POST", {
          type: "mrkdwn",
          text:
            "Time Submitted: " +
            moment().format("MMMM Do YYYY, h:mm:ss a") +
            "\n------------------------------------" +
            "\nName: " +
            nameHelp.value +
            "\nEmail: " +
            emailHelp.value +
            "\nSubject: " +
            subjectHelp.value +
            "\nMessage: " +
            messageHelp.value +
            "\n------------------------------------",
        });

        // Increment help form submission counter
        projectFirestore.collection("totals").doc("analytics").set({ helpFormSubmissions: increment }, { merge: true });

        confirmation.value = $t("sidebar.confirmation");
        reset();
      }
    }

    function reset() {
      nameHelp.value = null;
      emailHelp.value = null;
      subjectHelp.value = null;
      messageHelp.value = null;
    }

    function hardReset() {
      nameHelp.value = null;
      emailHelp.value = null;
      subjectHelp.value = null;
      messageHelp.value = "";
      wasClicked.value = false;
      confirmation.value = null;
      error.value = null;
    }

    return {
      // Methods
      handleAnalyticsClick,
      handleFeedbackClick,
      handleHelpClick,
      handleHelp,
      handleClick,
      toggleSidebar,
      toggleHelpModal,
      helpModal,
      sendForm,
      hardReset,
      // Data
      user,
      isApproved,
      error,
      confirmation,
      collapsed,
      sidebarWidth,
      showModal,
      host,
      wasClicked,
      defaultName,
      defaultEmail,
      hardReset,

      // Images
      FriendlierIconOceanCloud,
      FriendlierBusinessLogoWhite,
    };
  },
};
</script>

<style>
:root {
  --sidebar-bg-color: #032673;
  --sidebar-item-hover: #08369b;
  --sidebar-item-active: #1356e6;
}
</style>

<style lang="scss" scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}
.sidebar h1 {
  height: 4.5em;
}
.sidebar-options {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;
}
.sidebar-options a {
  margin-top: 5px !important;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.btn {
  background-color: white;
  color: var(--sidebar-bg-color);
}
.btn:hover {
  color: white;
}
#logoutBtn {
  bottom: 0;
}
#helpBtn {
  margin-top: 20px;
}
.help {
  margin: 20px;
  text-align: left;
}

.help-form {
  background-color: white;
  padding-bottom: 30px;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}
.modal-card {
  max-height: calc(100vh - 250px);
  /*remove width: 100%;*/
  width: calc(45% - 1em);
  border-radius: 0.5em;
  overflow-y: auto;
  // height: 100%;
  position: static;
  z-index: 1;
}
.confirmation-text {
  color: black;
  font-size: 20px;
}
.inline-input {
  display: inline-block;
}
::-webkit-input-placeholder {
  color: red;
}
::-moz-placeholder {
  color: red;
}
::-ms-placeholder {
  color: red;
}
::placeholder {
  color: red;
}
</style>
