import { logError } from "@/utils";
import { useCookies } from "vue3-cookies";

import { getUser, useAPI } from "@/composables";
import { projectFirestore } from "../firebase/config";

/**
 * Check for the existance of a cookie, if found return and update ttl
 * @param {string} key
 * @returns {any | false} cookie value, or false if not found
 */
function checkCookies(key) {
  try {
    const { cookies } = useCookies();

    // check cookie exists
    if (!cookies.isKey(key)) throw new Error("Cookie not found");

    let cookieVal = cookies.get(key);

    // & isn't empty
    if (!cookieVal) throw new Error("Cookie was empty");

    cookies.set(key, cookieVal, "7d");

    return cookieVal;
  } catch (e) {
    console.log(
      `UseAnalytics.CheckCookies: Failed to Check cookie. ${e.toString()}`
    );
  }
  return false;
}

async function getAnalytics() {
  let lytics = null;

  try {
    let analyticsRef = await projectFirestore
      .collection("parameters")
      .doc("analytics")
      .get();
    if (!analyticsRef.exists)
      throw new error("ClientAnalyticsVisible not found");

    lytics = analyticsRef.data();
  } catch (e) {
    logError(`ClientAnalyticsVisible: failed due to ${e.toString()}`);
  }
  return lytics;
}

/**
 * Retrieve matching outlet record from db
 * @param {string} placeID
 * @returns {Object | Null} resulting data from the API
 */
async function getOutlet(placeID, includeGroup) {
  if (
    includeGroup == null ||
    includeGroup == undefined ||
    typeof includeGroup != typeof true
  )
    includeGroup = false;

  const api = useAPI();

  try {
    let response = await api.request(
      `${process.env.VUE_APP_GOOGLE_CLOUD_FUNCTIONS_BASE_URL}/outlets/${placeID}?includeGroup=${includeGroup}`,
      "GET",
      undefined,
      { includeAuth: true, gcpKey: true }
    );

    return response;
  } catch (err) {
    logError(
      `useAnalytics.getOutlet: Failed to fetch analytics for ${placeID}. ${err.toString()}`
    );
  }
  return null;
}

/**
 * Retrieve the weekly entry of a specific or group of placeIDs
 * @param {string} placeID
 * @param {string[]} weeks format YYYY-MM-DD
 * @param {boolean} includeGroup
 * @returns
 */
async function getOutletAnalytics(placeID, weeks, includeGroup) {
  const api = useAPI();

  try {
    let week = `${weeks[weeks.length - 1]}`;
    if (weeks.length > 1) week += `-${weeks[0]}`;

    let response = await api.request(
      `${process.env.VUE_APP_GOOGLE_CLOUD_FUNCTIONS_BASE_URL}/outlets/${placeID}/analytics?week=${week}&includeGroup=${includeGroup}`,
      "GET",
      undefined,
      { includeAuth: true, gcpKey: true }
    );

    return response;
  } catch (err) {
    logError(
      `${
        process.env.NODE_ENV
      }: useAnalytics: Failed to fetch analytics for ${placeID}. ${err.toString()}`
    );
  }
}

// unfinished
async function weeklyReturnData(dates, group, outlet) {
  let returnData = [];
  let newReturnData = [];
  let loyalReturnData = [];
  let outletReturnData = [];
  let otherReturnData = [];
  for (let date in dates) {
    let weeklyData = await getOutletAnalytics(dates[date], group);
    if (weeklyData && weeklyData["data"][outlet]) {
      if (weeklyData["data"][outlet]["total_returns"]) {
        returnData.push(weeklyData["data"][outlet]["total_returns"]);
      } else {
        returnData.push(0);
      }
      if (weeklyData["data"][outlet]["new_returns"]) {
        newReturnData.push(weeklyData["data"][outlet]["new_returns"]);
      } else {
        newReturnData.push(0);
      }
      if (weeklyData["data"][outlet]["loyal_returns"]) {
        loyalReturnData.push(weeklyData["data"][outlet]["loyal_returns"]);
      } else {
        loyalReturnData.push(0);
      }
      if (weeklyData["data"][outlet]["outlet_returns"]) {
        outletReturnData.push(weeklyData["data"][outlet]["outlet_returns"]);
      } else {
        outletReturnData.push(0);
      }
      if (weeklyData["data"][outlet]["left_over_returns"]) {
        otherReturnData.push(weeklyData["data"][outlet]["left_over_returns"]);
      } else {
        otherReturnData.push(0);
      }
    }
  }
  return {
    returns: returnData,
    newReturns: newReturnData,
    loyalReturns: loyalReturnData,
    outletReturns: outletReturnData,
    otherReturns: otherReturnData,
  };
}

async function getChartData(dataset, placeID, chartID, ignoreCookies) {
  let data_key = `${dataset.datasetId}-${placeID}-${chartID}`;

  if (!ignoreCookies) {
    let exists = checkCookies(data_key);

    // ensure not falsy = 0, null, false, undefined
    if (exists) {
      return exists;
    }
  }
  return await grabNewData(dataset, placeID, chartID);
}

const fetchContainersSoldReturned = async (dataset, placeID, chartID) => {
  try {
    let data = await getChartData(dataset, placeID, chartID, false);

    let sold = data.containers.sold;
    let returned = data.containers.returned;

    const { user } = getUser();
    const uniqueId = user.value.uid.toString();
    const userRef = await projectFirestore
      .collection("users")
      .doc(uniqueId)
      .get();
    const userData = userRef.data();
    let lang =
      userData.languagePreference != null ? userData.languagePreference : "en";

    let formatted = {
      x_labels: [""],
      datasets: {
        Returned: [returned],
        Sold: [sold],
      },
    };
    if (lang == "fr") {
      formatted = {
        x_labels: [""],
        datasets: {
          Rendu: [returned],
          Vendu: [sold],
        },
      };
    }

    if (chartID == "ror") {
      let ror = (returned / sold) * 100;
      return Math.round(ror * 10) / 10;
    }

    return formatted;
  } catch (err) {}
};

const fetchOrderFreq = async (dataset, placeID, chartID) => {
  try {
    let data = await getChartData(dataset, placeID, chartID, false);

    if (!data) return null;

    let formatted_dates = [];

    data.order_freq.dates.forEach((date) =>
      formatted_dates.push(date.replace(/-/g, " "))
    );

    const { user } = getUser();
    const uniqueId = user.value.uid.toString();
    const userRef = await projectFirestore
      .collection("users")
      .doc(uniqueId)
      .get();
    const userData = userRef.data();
    let lang =
      userData.languagePreference != null ? userData.languagePreference : "en";

    let formatted = {
      x_labels: formatted_dates,
      datasets: {
        Total: {
          "All Containers": data.order_freq.total_counts,
        },
        "By Size": data.order_freq.container_counts,
      },
    };
    if (lang == "fr") {
      formatted = {
        x_labels: formatted_dates,
        datasets: {
          Total: {
            "Tous Contenants": data.order_freq.total_counts,
          },
          "Par Taille": data.order_freq.container_counts,
        },
      };
    }

    return formatted;
  } catch (err) {}
};

// @depricated
const fetchCycleTime = async (dataset, placeID, chartID) => {
  try {
    let data = await getChartData(dataset, placeID, chartID, false);

    if (!data) return null;

    // Formatting for cycle time
    return data.cycle;
  } catch (err) {}
};

// @depricated
const fetchActiveUsers = async (dataset, placeID, chartID) => {
  try {
    let data = await getChartData(dataset, placeID, chartID, false);

    if (!data) return null;

    return data.users.unique_users;
  } catch (err) {}
};

// @depricated
const fetchCustomerTypes = async (dataset, placeID, chartID) => {
  try {
    let data = await getChartData(dataset, placeID, chartID, false);

    if (!data) return null;

    const { user } = getUser();
    const uniqueId = user.value.uid.toString();
    const userRef = await projectFirestore
      .collection("users")
      .doc(uniqueId)
      .get();
    const userData = userRef.data();
    let lang =
      userData.languagePreference != null ? userData.languagePreference : "en";

    let formatted = {
      "New to Location": data.users.existing,
      "New to Friendlier": data.users.new,
      Returning: data.users.returning,
    };
    if (lang == "fr") {
      formatted = {
        "Nouveau à l'Emplacement": data.users.existing,
        "Nouveau à Friendlier": data.users.new,
        Revenant: data.users.returning,
      };
    }

    return formatted;
  } catch (err) {}
};

const useAnalytics = () => {
  return {
    getOutlet,
    getOutletAnalytics,
    getAnalytics,
    fetchOrderFreq,
    fetchContainersSoldReturned,
    weeklyReturnData,
  };
};

export default useAnalytics;
