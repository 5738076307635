import { userStore } from "@/store";

export default async function logError(msg) {
  console.log(msg);
  try {

    let textBlocks = [
      {
        type: "header",
        text: {
          type: "plain_text",
          text: "Error Found!",
          emoji: true,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Message*: ${msg}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `• *Found by*: ${userStore.email}\n• *At*: ${new Date()}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `• *Environment*: ${process.env.NODE_ENV}\n`,
        },
      },
      // {
      //   type: "section",
      //   text: {
      //     type: "mrkdwn",
      //     text: affectedLog,
      //   },
      // },
      // {
      //   type: "section",
      //   text: {
      //     type: "mrkdwn",
      //     text: callLog,
      //   },
      // },
    ];

    await fetch(process.env.VUE_APP_ERROR_SLACK_BOT_URL, {
      method: "POST",
      body: JSON.stringify({
        text: "Cliet Portal Error",
        blocks: textBlocks,
      }),
    });
  } catch (error) {
    try {
      await fetch(process.env.VUE_APP_ERROR_SLACK_BOT_URL, {
        method: "POST",
        body: JSON.stringify({
          type: "mrkdwn",
          text: `LogError: Failed to log error ${error.toString()}`,
        }),
      });
    } catch (err) {
      console.log(`Failed to log error. ${err.toString()}. ${error.toString()}`);
    }
  }
}