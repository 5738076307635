<template>
  <Loading v-if="loading" />
  <template v-else>
    <div>
      <span class="is-flex is-justify-content-space-between is-align-items-center p-5">
        <h1 class="pl-5 pt-4 is-size-3 is-family-secondary has-text-info">
          {{ $t("settings.title") }}
        </h1>

        <ClientSelect />
      </span>
      <template v-if="error">
        <p>
          <strong>{{ $t("oops") }}</strong> {{ $t("something-wrong") }}
        </p>
      </template>
      <template v-else>
        <hr />
        <ResetPassword :email="user.email" />
        <hr />
        <!-- <EmailNotice
          v-if="clientStore.activeClient['data']['paymentType'] == 'invoice'"
          :placeID="clientStore.activeClient['data']['placeID']"
        /> -->
        <EmailNotice :placeID="clientStore.activeClient['data']['placeID']" />
        <hr />
        <!-- <OutletDetails /> -->
      </template>
    </div>
  </template>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import { useFirebase } from "@/composables";
import getUser from "@/composables/getUser";
import { Loading, ResetPassword, EmailNotice, ClientSelect, OutletDetails } from "@/components";
import { userStore, clientStore, permissionStore } from "@/store";
import { logError } from "@/utils";

const { user } = getUser();
const router = useRouter();
const _fb = useFirebase();

let loading = ref(true);
let error = ref(false);

onMounted(async () => {
  document.title = "Friendlier | Settings";
  try {
    loading.value = true;

    await userStore.initialize();
    await clientStore.initialize();
    await permissionStore.initialize();

    if (!userStore.portalApproved) {
      router.push("/login");
    }

    await getData();
  } catch (e) {
    logError(`settings.onMounted: Failed to setup component. ${e.toString()}`);
  }
  loading.value = false;
});

// watch(user, () => {
//   if (!user.value) {
//     router.push({ name: "Login" });
//   }
// });

/**
 * Function to get values about current logged in user
 */
async function getData() {
  try {
  } catch (err) {
    logError(`settings.getData: Failed to get data for component. ${err.toString()}`);
    error.value = true;
  }
}
</script>

<style>
body {
  height: 100%;
}
</style>
