<template>
  <div class="modal" id="checkout-modal">
    <div
      class="modal-background"
      :onclick="
        () => {
          if (!loading) close;
        }
      "
    />
    <div class="modal-card">
      <header v-if="!loading" class="modal-card-head">
        <p class="modal-card-title is-family-secondary is-denim" v-if="orderSummaryPage == 3">
          {{ $t("checkoutModal.order-received") }}
        </p>
        <p class="modal-card-title" v-else>
          {{ $t("checkoutModal.order-details") }}
          <span v-if="editable">{{ $t("checkoutModal.please-review") }}</span>
        </p>
        <button class="delete" aria-label="close" :onclick="close" />
      </header>

      <section v-if="loading" class="modal-card-body" style="padding-bottom: 25%">
        <Loading />
      </section>

      <section v-if="!loading" class="modal-card-body has-text-centered">
        <template v-if="orderSummaryPage == 1">
          <CheckoutUserDetails v-if="editable" />
          <hr v-if="editable" />
          <CheckoutRestaurantDetails
            :editable="editable"
            :delivery-date="activeOrder ? activeOrder.deliveryDate : null"
            @change="updateDeliveryDate"
            :activeRestaurant="activeRestaurant"
          />
          <hr />
          <OrderDetailsTable
            :quantities="quantities"
            @report="updateProductDetails"
            :editable="editable"
            :activeOrder="activeOrder"
            :products="products"
          />
          <hr />
        </template>
        <template v-if="orderSummaryPage == 2">
          <Suspense>
            <CardDetails :setupIntent="setupIntent" :orderID="tempOrder" @confirmOrder="confirmOrder" />
          </Suspense>
        </template>
        <template v-if="orderSummaryPage == 3">
          <div class="has-text-centered">
            {{ $t("checkoutModal.invoice-created") }}
          </div>
          <div class="level is-mobile my-3">
            <a
              v-if="invoiceDownloadURL != ''"
              class="button level-item is-denim-back is-cloud m-3"
              target="_blank"
              style="width: 30%"
              :href="invoiceDownloadURL"
              follow="no-referrer"
            >
              {{ $t("checkoutModal.download-invoice") }}
            </a>
            <a
              v-if="invoicePaymentURL != ''"
              class="button level-item is-denim-back is-cloud m-3"
              style="width: 30%"
              target="_blank"
              :href="invoicePaymentURL"
              follow="no-referrer"
            >
              {{ $t("checkoutModal.pay-invoice") }}
            </a>
          </div>
          <div class="has-text-centered">
            {{ $t("checkoutModal.thank-you") }}
            <a class="is-family-secondary" href="/feedback"
              ><u>{{ $t("checkoutModal.thank-cont") }}</u></a
            >.
          </div>
        </template>
        <template v-if="orderSummaryPage == 0">
          <div class="subtitle my-1">Deli Container Lids</div>
          <hr class="is-denim-back m-1" />
          <div>
            With the deli containers we offer two types of lids, please select one.
            <div class="checkbox-group">
              <span class="deli-lid-option" @click="() => (deliLid = 'overcap')">
                <label for="overcap">Overcap</label>
                <div id="overcap" class="checkbox">
                  <span v-if="deliLid == 'overcap'" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                  </span>
                </div>
              </span>
              <span class="deli-lid-option" @click="() => (deliLid = 'recessed')">
                <label for="recessed">Recessed</label>
                <div id="recessed" class="checkbox">
                  <span v-if="deliLid == 'recessed'" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </template>
      </section>

      <footer class="modal-card-foot buttons is-centered">
        <template v-if="!loading && orderSummaryPage == 1">
          <button v-if="editable" class="button is-danger" :onClick="back">
            {{ $t("checkoutModal.back") }}
          </button>
          <button v-else class="button is-danger" :onClick="close">
            {{ $t("close") }}
          </button>
          <a
            v-if="!editable && activeOrder['invoicePDF']"
            class="button is-denim-back is-cloud"
            target="_blank"
            style="width: 30%"
            :href="activeOrder['invoicePDF']"
            follow="no-referrer"
          >
            {{ $t("checkoutModal.download-invoice") }}
          </a>
          <a
            v-if="!editable && activeOrder['progress'] == 'invoice sent' && activeOrder['invoiceURL']"
            class="button is-denim-back is-cloud"
            target="_blank"
            style="width: 30%"
            :href="activeOrder['invoiceURL']"
            follow="no-referrer"
          >
            {{ $t("checkoutModal.pay-invoice") }}
          </a>
          <button
            v-if="editable"
            class="button is-info"
            :disabled="!totalPrice || !deliveryDate"
            @click.prevent="handleSubmitOrder"
          >
            {{
              clientStore.getState().activeClient["data"]["paymentType"] == "invoice"
                ? $t("confirm-checkout")
                : $t("payment-details")
            }}
          </button>
        </template>
        <template v-if="!loading && orderSummaryPage == 2">
          <button class="button is-danger mt-1" :onClick="back">
            {{ $t("checkoutModal.back") }}
          </button>
        </template>
        <template v-if="!loading && orderSummaryPage == 3">
          <button class="button is-danger mt-1" :onClick="close">
            {{ $t("close") }}
          </button>
        </template>
        <template v-if="!loading && orderSummaryPage == 0">
          <button class="button is-danger mt-1" :onClick="close">
            {{ $t("checkoutModal.cancel") }}
          </button>
          <button class="button is-info mt-1" :onClick="next">
            {{ $t("Ok") }}
          </button>
        </template>
      </footer>
    </div>
  </div>

  <!-- <Modal
    v-if="extraDetails"
    title="New Payment Information"
    body="Friendlier has added credit card payments to our ordering platform to streamline our payment process and make it easier for our clients to keep track of payments. Please reach out to your Friendlier Rep if you have any questions or concerns."
    :close="() => (extraDetails = false)"
    :actions="[
      {
        text: 'Dismiss',
        id: 1,
        click: () => (extraDetails = false),
      },
    ]"
  /> -->
</template>
<script setup>
import { ref, onMounted } from "vue";

import { Loading, Modal } from "@/components";
import {
  useStripe,
  // useFirebase,
  ordersAPI,
} from "@/composables";
import { clientStore, userStore } from "@/store";
import { logError } from "@/utils";

import CheckoutUserDetails from "./checkoutUserDetails.vue";
import CheckoutRestaurantDetails from "./checkoutRestaurantDetails.vue";
import OrderDetailsTable from "./orderDetailsTable.vue";
import CardDetails from "./cardDetails.vue";

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  quantities: Object,
  activeOrder: {
    type: Object,
    default: null,
  },
  activeRestaurant: {
    type: Object,
    default: null,
  },
  products: {
    type: Array,
    default: [],
  },
});

const emits = defineEmits(["close"]);

// const stripe = useStripe();
// const _fb = useFirebase();

const loading = ref(true);

const deliveryDate = ref();

const localProducts = ref(null);
const totalPrice = ref(null);
const totalTax = ref(null);

const orderSummaryPage = ref(0);
const setupIntent = ref();
const tempOrder = ref();

const invoiceDownloadURL = ref("");
const invoicePaymentURL = ref("");

const deliLid = ref("overcap");

onMounted(async () => {
  await setup();
  loading.value = false;
});

async function setup() {
  try {
    await clientStore.initialize();
    await userStore.initialize();
    await deliCheck();
  } catch (e) {
    logError(`checkoutModal.setup: Failed to setup checkout modal component. ${e.toString()}`);
  }
}

async function deliCheck() {
  try {
    if (props.editable) {
      for (let quantity in props.quantities) {
        if (quantity == "F8D" || quantity == "F16D" || quantity == "F32D") {
          if (props.quantities[quantity] > 0) {
            orderSummaryPage.value = 0;
            return;
          }
        }
      }
    }
    deliLid.value = undefined;
    orderSummaryPage.value = 1;
  } catch (e) {
    logError(`checkoutModal.deliCheck: Failed to perform deliCheck. ${e.toString()}`);
  }
}

async function updateDeliveryDate(date) {
  deliveryDate.value = date;
}

function updateProductDetails(lp, tp, tt) {
  localProducts.value = lp;
  totalPrice.value = tp;
  totalTax.value = tt;
}

async function handleSubmitOrder() {
  if (loading.value == true) return;
  loading.value = true;

  let quantities = {};
  for (let q in props.quantities) {
    if (props.quantities[q] > 0) {
      quantities[q] = props.quantities[q];
    }
  }

  // Invoice pathway
  //     Use PlaceOrder endpoint

  // Payment Pathway
  //     Generate an orderID
  //         Can create an order now leaving it in the created state
  //     Create Payment Method Setup Intent
  //          calcs total from order
  //              *potentially update order with payment details here*
  //          create SI object
  //          needs customer ID, placeID, and OrderID for metadeta
  //          generate SI object in stripe
  //         Return Client Secret For Library to use
  //     Display Credit Card Fields with Stripe Library
  //     OnPay; confirm order
  //         Confirm Order
  //             transition from creating to confirmed
  //         Charge Card
  //     Generate the invoice
  //         update the order invoice details
  //         update the order payment details
  //     There is a chance of redirect depending on the credit card..
  //         a flow will be needed to handle this
  //         currently handled by the payment flow function on the main page
  //         but this is problematic, I don't think generates an invoice

  try {
    if (clientStore.activePaymentType == "invoice") {
      // let orderID = await _fb.createOrder(
      //   deliveryDate.value,
      //   localProducts.value,
      //   totalTax.value,
      //   totalPrice.value,
      //   userStore.uid,
      //   clientStore.activePlaceID,
      //   clientStore.activeFacility,
      //   deliLid.value ? `${deliLid.value} deli lids!` : undefined
      // );

      // let invoice = await stripe.createStripeInvoice(
      //   clientStore.stripeCustomer,
      //   userStore.email,
      //   localProducts.value,
      //   orderID,
      //   clientStore.activePlaceID,
      //   userStore.languagePreference,
      //   undefined
      // );

      let order = await ordersAPI.placeOrder(
        clientStore.activeClient["data"]["placeID"],
        deliveryDate.value.getTime(),
        quantities,
        deliLid.value ? `${deliLid.value} deli lids!` : undefined
      );

      loading.value = false;
      orderSummaryPage.value = 3;

      invoiceDownloadURL.value = order["invoice"]["invoice_pdf"];
      invoicePaymentURL.value = order["invoice"]["hosted_invoice_url"];
    } else {
      // let orderObj = await _fb.generateTempOrder(
      //   deliveryDate.value,
      //   localProducts.value,
      //   totalTax.value,
      //   totalPrice.value,
      //   userStore.uid,
      //   clientStore.activePlaceID,
      //   clientStore.activeFacility,
      //   deliLid.value ? `${deliLid.value} deli lids!` : undefined
      // );
      let order = await ordersAPI.createOrder(
        clientStore.activePlaceID,
        deliveryDate.value.getTime(),
        quantities,
        deliLid.value ? `${deliLid.value} deli lids!` : undefined
      );

      // setupIntent.value = await stripe.createSetupIntent(
      //   orderObj["orderID"],
      //   localProducts.value,
      //   clientStore.stripeCustomer,
      //   userStore.email,
      //   clientStore.activePlaceID
      // );

      let response = await ordersAPI.setupCreditCard(order["orderID"], clientStore.activePlaceID);
      setupIntent.value = response["client_secret"];

      logError(`checkoutModal.handleSubmitOrder: recieved setupIntent`);

      loading.value = false;
      orderSummaryPage.value = 2;
      tempOrder.value = order["orderID"];
    }
  } catch (e) {
    logError(`checkoutModal.handleSubmitOrder: Failed to kick off order flow. ${e.toString()}`);
  }
}

async function confirmOrder() {
  try {
    let details = await ordersAPI.finalizeOrder(tempOrder.value);

    loading.value = false;
    orderSummaryPage.value = 3;
    invoiceDownloadURL.value = details["invoice"]["invoice_pdf"];
  } catch (e) {
    logError(`checkoutModal.confirmOrder: Failed to confirm Order. ${e.toString()}`);
  }
}

/*** Functional Controls **/
function close() {
  emits("close");
}

async function back() {
  if (orderSummaryPage.value == 1 && !deliLid.value) close();
  orderSummaryPage.value = orderSummaryPage.value - 1;
}

async function next() {
  orderSummaryPage.value = orderSummaryPage.value + 1;
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.modal {
  display: flex;
  .modal-background {
    position: fixed !important;
    z-index: 2;
  }
  .modal-card {
    position: relative;
    z-index: 3;
    border-radius: 15px;
    max-height: 100vh;
    margin: 20px 0px;
    overflow-y: auto;

    header {
      height: 50px;

      .modal-card-title {
        position: relative;
        display: flex;
        align-items: flex-end;
        span {
          font-size: 0.8rem;
          padding-left: 20px;
        }
      }
    }

    section {
      height: fit-content;
      min-height: 200px;

      > div {
        height: 50px;
        padding: 5px;
      }
    }

    footer {
      height: 75px;
      margin-bottom: 0px;

      .tooltip {
        position: relative;
        cursor: pointer;
      }
    }
  }
}

.checkbox-group {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 15px;
  width: 50%;

  .deli-lid-option {
    display: flex;
    align-items: center;

    label {
      font-weight: bold;
      margin-right: 15px;
    }

    .checkbox {
      border: 1px solid $denim;
      border-radius: 6px;
      width: 30px;
      height: 30px;

      .checkmark {
        display: inline-block;
        width: 30px;
        height: 30px;
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
        transform: rotate(45deg);
      }

      .checkmark_stem {
        position: absolute;
        width: 7px;
        height: 22px;
        background-color: $denim;
        left: 11px;
        top: 1px;
      }

      .checkmark_kick {
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: $denim;
        left: 5px;
        top: 16px;
      }
    }
  }
}
</style>
