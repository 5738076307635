<template>
  <div class="card-container is-flex-direction-column is-align-items-flex-start pl-5 pr-5">
    <div class="title no-select">{{ $t("productFilter.filters") }}</div>
    <span class="pl-5 is-flex is-align-items-center">
      <label class="label mb-0 mr-3 no-select" for="size-select">{{ $t("productFilter.container-size") }}</label>
      <select
        class="select no-outline friendlier-select p-2"
        v-if="sizes != null"
        id="size-select"
        @change="onSizeChange(productSize)"
        v-model="productSize"
      >
        <option value="" selected>{{ $t("productFilter.any") }}</option>
        <option v-for="size in sizes" :value="size" :key="size">
          {{ size }}
        </option>
      </select>

      <label class="label mb-0 mx-3 no-select" for="size-select">{{ $t("productFilter.container-type") }}</label>
      <select
        class="select no-outline friendlier-select p-2"
        v-if="cTypes != null"
        id="size-select"
        @change="onTypeChange(productType)"
        v-model="productType"
      >
        <option value="" selected>{{ $t("productFilter.any") }}</option>
        <option v-for="cType in cTypes" :value="cType" :key="cType">
          {{ cType }}
        </option>
      </select>
    </span>
    <hr class="is-denim-back is-full filter-underline" />
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";

export default {
  name: "",
  components: {},
  props: {
    products: Array,
    onFilterChange: Function,
  },
  setup(props) {
    const sizes = ref(null);
    const cTypes = ref(null);
    const productSize = ref("");
    const productType = ref("");
    const filters = ref({
      size: "",
      type: "",
    });

    onMounted(() => {
      let sizeArray = [];
      let typeArray = [];
      props.products.forEach((product) => {
        sizeArray.push(product["size"]);
        typeArray.push(product["type"]);
      });
      sizes.value = new Set(sizeArray.sort());
      cTypes.value = new Set(typeArray.sort());
    });

    watch(filters.value, () => {
      props.onFilterChange(filters.value);
    });

    function onSizeChange(sizeFilter) {
      filters.value["size"] = sizeFilter;
    }

    function onTypeChange(typeFilter) {
      filters.value["type"] = typeFilter;
    }

    return {
      // Data
      sizes,
      cTypes,
      productSize,
      productType,
      // Methods
      onSizeChange,
      onTypeChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter-underline {
  margin-bottom: 0;
}
</style>
