import { Store, userStore } from "./";
import { useFirebase } from "@/composables";
import { logError, timeSince } from "@/utils";

const FETCH_TIMER = 60000

class PermissionStore extends Store {
  constructor(storeName) {
    super(storeName, PermissionStore.initialState());
  }
  
  static initialState() {
    return {
      accountTier: {},
      permissionGroups: [],
      permissions: [],
      lastFetch: undefined
    };
  }

  async initialize(tier) {
    try {
      if (this.state.lastFetch && !timeSince(this.state.lastFetch,FETCH_TIMER)) {
        return;
      }

      this.state.lastFetch = new Date();
      let response = await useFirebase().getPermissions(tier);

      this.state.accountTier = response['accountTier'];
      this.state.permissionGroups = response['permissionGroups'];
      this.state.permissions = response['permissions'];

    } catch (e) {
      logError(`permissionStore.initialize: Failed to initialize permissions. ${e.toString()}`);
    }
  }

  get accountTier() {
    return this.state.accountTier
  }

  get permissionGroups() {
    return this.state.permissionGroups
  }

  get permissions() {
    return this.state.permissions
  }

  can(permissionType) {
    try {
      for (let permission of this.state.permissions) {
        if (permission['data']['name'] == permissionType) return true;
      }
    } catch (err) {
      logError(`permissionStore.can: Failed to check permissions. ${permissionType}. ${err.toString()}`);
    }
    return false;
  }

}

export const permissionStore = new PermissionStore("permission-store");
