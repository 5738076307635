<template>
  <div class="modal-container">
    <div class="modal-cover" @click="close" />
    <div class="modal-card" :class="{ 'is-denim-back': isDanger == true }">
      <div class="modal-exit unselectable" @click="close" :class="{ 'is-sunflower': isDanger == true }">x</div>
      <div class="modal-header">
        <p class="modal-title unselectable is-bold" :class="{ 'is-sunflower': isDanger == true }">{{ title }}</p>
      </div>
      <div class="modal-body">
        <p class="modal-body-text" :class="{ 'is-cloud': isDanger == true }">{{ body }}</p>
      </div>
      <div class="modal-footer">
        <span
          class="modal-action unselectable"
          :class="{ 'is-sunflower-back': isDanger == true, 'is-denim': isDanger == true }"
          v-for="action in actions"
          :key="action.id"
          @click="action.click"
          >{{ action.text }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  components: {},
  props: {
    title: String,
    body: String,
    actions: Array,
    close: Function,
    isDanger: Boolean,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

$container-padding: 20px;

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(h.$stormCloud, 0.8);
  }

  .modal-card {
    border-radius: 25px;
    background-color: h.$cloud;
    padding: $container-padding;
    min-width: calc(300px + 2 * $container-padding);
    min-height: calc(200px + 2 * $container-padding);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .modal-exit {
      position: absolute;
      top: $container-padding;
      right: $container-padding;
      color: h.$denim;
      cursor: pointer;
      line-height: 0;
      font-weight: bolder;
      font-size: 20px;
    }

    .modal-header {
      height: 20%;
      width: 100%;

      .modal-title {
        padding: 0;
        margin: 0;
        color: h.$denim;
        font-weight: bold;
        font-size: 28px;
      }
    }

    .modal-body {
      padding: 5px;
      color: h.$denim;
      height: 60%;
      width: 100%;
      font-weight: bold;
      flex-grow: 2;
    }

    .modal-footer {
      height: 20%;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .modal-action {
        display: block;
        border-radius: 5px;
        margin: 5px;
        padding: 7px 12px;
        background-color: h.$denim;
        color: h.$cloud;
        cursor: pointer;
        font-weight: bold;
        min-width: 75px;
        text-align: center;
      }
    }
  }
}
</style>
