import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { projectAuth } from "./firebase/config";
import FontAwesomeIcon from "@/assets/fontawesome";
import i18n from "./i18n";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

require("@/assets/main.scss");

const vuetify = createVuetify({
  components,
  directives,
});

let app;
projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(router);
    app.use(i18n);
    app.use(vuetify);
    app.component("font-awesome-icon", FontAwesomeIcon);
    app.mount("#app");
  }
});
