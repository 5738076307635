<template>
  <div id="background-pattern">
    <FloatingLocale />
    <div id="form-container">
      <div class="card mt-6 has-text-centered" style="width: 650px">
        <figure class="image is-inline-block" style="width: 33%">
          <img class="pt-5" :src="FriendlierPrimaryDenim" />
        </figure>
        <div class="card-content ml-5 mr-5 mb-5">
          <hr />
          <RegForm @signup="enterPortal" />
          <hr />
          <span>
            <a class="has-text-info">
              <router-link to="/"> {{ $t("register.have-account") }} </router-link>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { RegForm, FloatingLocale } from "@/components";
import { useRouter } from "vue-router";
import { FriendlierPrimaryDenim } from "@/assets";
import { onMounted } from "vue";

const router = useRouter();

onMounted(() => {
  console.log("Register");
  document.title = "Friendlier | Register";
});

const enterPortal = () => {
  console.log("enterPortal");
  router.push("/home");
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

#background-pattern {
  @extend .background-pattern;
  background-image: url("../../assets/patterns/friendlier-pattern-08.png");
}
</style>
