import { Store, userStore } from "./";
import { useFirebase, useStripe } from "@/composables";
import { logError } from "@/utils";
import { isEqual } from "lodash";

class ClientStore extends Store {
  constructor(storeName) {
    super(storeName, ClientStore.initialState());
  }
  
  static initialState() {
    return {
      activeClient: {},
      userClients: [],
      stripeCustomer: {}
    };
  }

  get activeClient () {
    try {
      if (isEqual(this.state.activeClient,{})) {
        this.state.activeClient = this.state.userClients[0]; 
      }
      return this.state.activeClient;
    } catch (e) {
      logError(`clientStore.getActiveClient: Failed to get activeClient. ${e.toString()}`);
    }
    return undefined;
  }

  get userClients () {
    try {
      if (this.state.userClients.length == 0) {
        this.fetchUserClients()
      }
      return this.state.userClients;
    } catch (e) {
      logError(`clientStore.getUserClients: Failed to get user client. ${e.toString()}`)
    }
    return undefined;
  }

  get stripeCustomer() {
    try {
      if (isEqual(this.state.stripeCustomer,{})) {
        this.fetchStripeCustomer()
      }
      return this.state.stripeCustomer;
    } catch (e) {
      logError(`clientStore.stripeCustomer: Failed to get stripe customer. ${e.toString()}`)
    }
    return undefined;
  }

  get activePriceGroup() {
    try {
      if (isEqual(this.state.activeClient,{})) {
        this.state.activeClient = this.state.userClients[0]; 
      }
      return this.state.activeClient['data']['priceGroup'];
    } catch (e) {
      logError(`clientStore.getActivePriceGroup: Failed to get active clients priceGroup. ${e.toString()}`);
    }
    return undefined;
  }

  get activePaymentType() {
    try {
      if (isEqual(this.state.activeClient,{})) {
        this.state.activeClient = this.state.userClients[0]; 
      }
      return this.state.activeClient['data']['paymentType'];
    } catch (e) {
      logError(`clientStore.getActivePaymentType: Failed to get active clients payment type. ${e.toString()}`);
    }
    return undefined;
  }

  get activePlaceID () {
    try {
      if (isEqual(this.state.activeClient,{})) {
        this.state.activeClient = this.state.userClients[0]; 
      }
      return this.state.activeClient['data']['placeID'];
    } catch (e) {
      logError(`clientStore.getActivePlaceID: Failed to get active clients placeID. ${e.toString()}`);
    }
    return undefined;
  }

  get activeFacility () {
    try {
      if (isEqual(this.state.activeClient,{})) {
        this.state.activeClient = this.state.userClients[0]; 
      }
      return this.state.activeClient['data']['facility'];
    } catch (e) {
      logError(`clientStore.getActiveFacility: Failed to get active clients facility. ${e.toString()}`);
    }
    return 'Guelph';
  }

  async initialize() {
    try {
      await this.fetchUserClients();
      await this.fetchStripeCustomer(false);
    } catch (e) {
      logError(`clientStore.initialize: Failed to initialize clientStore. ${e.toString()}`);
    }
  }

  async setActiveClientByName(clientName) {
    try {
      for (let client of this.state.userClients) {
        if (client['data']['companyName'] == clientName) {
          this.state.activeClient = client;
        }
      }
    } catch (e) {
      logError(`clientStore.setActiveClientByName: Failed to update active client. ${e.toString()}`);
    }
  }

  async setActiveClientByDisplayName(displayName) {
    try {
      for (let client of this.state.userClients) {
        if (client['data']['displayName'] == displayName) {
          this.state.activeClient = client;
        }
      }
    } catch (e) {
      logError(`clientStore.setActiveClientByDisplayName: Failed to update active client. ${e.toString()}`);
    }
  }

  async setActiveClientByPlaceID(placeID) {
    try {
      for (let client of this.state.userClients) {
        if (client['data']['placeID'] == placeID) {
          this.state.activeClient = client;
        }
      }
    } catch (e) {
      logError(`clientStore.setActiveClientByPlaceID: Failed to update active client. ${e.toString()}`);
    }
  }

  async fetchUserClients(force = false) {
    try {
      if (!force && this.state.userClients.length != 0) return;
  
      const _fb = useFirebase();
      let clients = [];

      // console.log(userStore.getState().placeID);
  
      for (let place of userStore.getState().placeID) {
        let res = await _fb.getRestaurant(place);
        if (isEqual(res, {})) continue;
        clients.push(res);
      }
      this.state.userClients = clients;

      if (force || isEqual(this.state.activeClient,{})) {
        this.state.activeClient = clients[0];
      }
    } catch (e) {
      logError(`clientStore.fetchUserClients: Failed to fetch user clients. ${e.toString()}`);
    }
  }

  async fetchStripeCustomer(override) {
    if (override || isEqual(this.state.stripeCustomer,{}) || this.state.stripeCustomer == null || this.state.stripeCustomer == undefined) {
      try {
        if (this.state.activeClient["data"].stripeCustomerID == undefined) {
          this.state.stripeCustomer = await useStripe().createStripeCustomer(this.state.activeClient["data"]);
        } else {
          this.state.stripeCustomer = await useStripe().getStripeCustomer(this.state.activeClient["data"].stripeCustomerID);
        }
      } catch (err) {
        logError(`clientStore.fetchStripeCustomer: Failed to retrieve or create stripe customer. ${err.toString()}`);
      }
      if (this.state.stripeCustomer.value == undefined) {
        // TODO gracefully stop processing here because there is no stripe customer
      }
    }
  }
}

export const clientStore = new ClientStore("client-store");
