<template>
  <div id="background-pattern">
    <FloatingLocale />
    <div id="form-container">
      <div class="card mt-6 has-text-centered" style="width: 650px">
        <figure class="image is-inline-block" style="width: 33%">
          <img class="pt-5" :src="FriendlierPrimaryDenim" />
        </figure>
        <div id="card-login" class="card-content ml-5 mr-5 mb-5">
          <hr />
          <LoginForm @login="enterPortal" />
          <span>
            <a class="has-text-info">
              <router-link to="/forgot-password">
                {{ $t("login.forgot-pass") }}
              </router-link>
            </a>
          </span>
          <hr />
          <span>
            <a class="has-text-info">
              <router-link to="/register">
                {{ $t("login.register") }}
              </router-link>
              <Loading class="small" v-if="loginLoading" medium />
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { LoginForm, FloatingLocale, Loading } from "@/components";
import { useRouter } from "vue-router";
import { FriendlierPrimaryDenim } from "@/assets";
import { watch, ref, onMounted } from "vue";
import { userStore } from "@/store";

const router = useRouter();
const loginLoading = ref(false);

watch(
  () => userStore.doc,
  () => {
    console.log(userStore.doc);
    router.push({ path: "/home" });
  }
);

const enterPortal = async () => {
  loginLoading.value = true;
};

onMounted(() => {
  document.title = "Friendlier | Login";
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

#background-pattern {
  @extend .background-pattern;
  background-image: url("../../assets/patterns/friendlier-pattern-08.png");
}

.small {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.card {
  position: relative;
}
</style>
