<template>
  <div class="stripe-checkout-container">
    <div class="subtitle">{{ $t("cardDetails.payment-info") }}</div>
    <div class="body">
      <div class="detail-container">
        <div class="is-red-highlight mb-2">{{ errorMsg }}</div>
        <form @submit.prevent="pay">
          <div id="card-payment-element" />
          <Loading v-if="loading" contained class="loading-override" />
          <button :disabled="loading" type="submit" id="submit" class="button is-info m-5" style="width: 50%">
            {{ $t("cardDetails.pay") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";

import { Loading } from "@/components";
import { ordersAPI } from "@/composables";
import { logError } from "@/utils";
import { userStore, clientStore } from "@/store";

const props = defineProps({
  setupIntent: {
    type: String,
    required: true,
  },
  orderID: String,
});

const emits = defineEmits(["confirmOrder"]);

const { t } = useI18n();

const loading = ref(true);
const errorMsg = ref("");

const stripeKey = ref(process.env.VUE_APP_STRIPE_API_KEY_PUBLISHABLE);
const stripeLibrary = ref();
const stripeElements = ref();
const cardPaymentElement = ref();

onMounted(async () => {
  try {
    const stripePromise = loadStripe(stripeKey.value, {
      locale: userStore.languagePreference,
    });

    stripePromise.then((s) => {
      try {
        stripeLibrary.value = s;
        stripeElements.value = stripeLibrary.value.elements({ clientSecret: props.setupIntent, loader: "always" });

        cardPaymentElement.value = stripeElements.value.create("payment");
        cardPaymentElement.value.mount("#card-payment-element");
        cardPaymentElement.value.on("change", validate);
        loading.value = false;
      } catch (err) {
        logError(`cardDetails.onMounted: Failed to setup stripe elements ${err.toString()}`);
      }
    });

    stripePromise.catch((e) => {
      logError(`cardDetails.onMounted: Failed to setup stripe Library ${e.toString()}`);
    });
  } catch (e) {
    logError(`cardDetails.onMounted: Failed to mount card details ${e.toString()}`);
  }
});

function validate(e) {
  errorMsg.value = e.error ? e.error.message : "";
}

async function pay() {
  if (errorMsg.value != "") {
    return;
  }

  loading.value = true;

  // await _fb.updateOrder(
  //   props.orderObj["orderID"],
  //   props.orderObj["outlet"],
  //   props.orderObj
  // );

  try {
    stripeLibrary.value
      .confirmSetup({
        elements: stripeElements.value,
        redirect: "if_required",
        confirmParams: {
          return_url: `${process.env.VUE_APP_HOSTING_URL}/order?orderID=${props.orderID}&customerID=${clientStore.stripeCustomer.id}`,
        },
      })
      .then((result) => {
        if (result.error) {
          switch (result.error.type) {
            case "card_error":
            case "validation_error":
              errorMsg.value = t("cardDetails.pay-error");
              loading.value = false;
              break;
            default:
              errorMsg.value = t("cardDetails.pay-error");
              throw new Error(`Failed to setup payment Method. ${JSON.stringify(result.error)}`);
          }
        } else {
          emits("confirmOrder", props.setupIntent);
        }
      });
  } catch (e) {
    await ordersAPI.deleteOrder(props.orderID, "Failed to confirm payment method setup.");
    logError(`cardDetails.pay: Failed to confirm payment method setup. ${e.toString()}`);
    errorMsg.value = t("cardDetails.pay-error");
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.stripe-checkout-container {
  min-height: 350px;

  .body {
    height: 90%;

    .loading-override {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 25%;
      background-color: $cloud;
    }
  }
}

.card-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10px;

  label {
    font-weight: bold;
    text-align: left;
    width: 25%;
    margin-right: 15px;
    font-size: 22px;
  }

  div {
    border: 1px solid $stormCloud;
    border-radius: 6px;
    width: 50%;
    align-items: center;
    padding: 7px 12px;
  }
}
</style>
