import { ref } from 'vue'
import { projectAuth } from '../firebase/config'

const error = ref(null)

const signup = async (email, password, firstName) => {
  error.value = null

  try {
    const res = await projectAuth.createUserWithEmailAndPassword(
      email,
      password
    )
    if (!res) {
      throw new Error('Could not complete the signup')
    }
    await res.user.updateProfile({ firstName })
    error.value = null 
    return res
  } catch (err) {
    if (err.code = "auth/invalid-email") {
      error.value = "Invalid Email or Password format."
    } else {
      error.value = err.message
    }
  }
}

const useSignup = () => {
  return { error, signup }
}

export default useSignup