<template>
  <div id="background-pattern">
    <FloatingLocale />
    <div id="form-container">
      <div class="card mt-6 has-text-centered" style="width: 650px">
        <div class="card-content">
          <p class="p-5 is-family-secondary is-size-5">
            {{ $t("businessPending.thank-you") }}
          </p>

          <!-- <RouterLink to="/home">{{ $t("back-home") }}</RouterLink> -->
          <hr class="is-denim-back" />
          <p class="mt-2">{{ $t("businessPending.update-password-notice") }}</p>
          <button class="button is-warning mt-2" @click="resetPassword">
            {{ $t("businessPending.update-password") }}
          </button>
          <hr class="is-denim-back" />
          <button class="button is-info mt-2 mb-2" @click="handleLogout">
            {{ $t("logout") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import { getUser, useLogout, useFirebase } from "@/composables";
import { Loading, FloatingLocale, ResetPassword } from "@/components";

export default {
  name: "BusinessPending",
  components: {
    Loading,
    FloatingLocale,
  },
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const _fb = useFirebase();

    const router = useRouter();

    watch(
      user,
      async () => {
        if (user == null || user == undefined || !user.value) {
          router.push({ name: "Login" });
        }
      },
      { immediate: true }
    );

    //logout function
    const handleLogout = async () => {
      await logout();
    };

    const resetPassword = async () => {
      if (user.value && user.value.email) {
        try {
          await _fb.sendPasswordResetEmail(user.value.email);
        } catch (err) {
          console.error(err.message);
        }
      }
    };

    return {
      user,
      handleLogout,
      resetPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

#background-pattern {
  @extend .background-pattern;
  background-image: url("../../assets/patterns/friendlier-pattern-08.png");
}
</style>
