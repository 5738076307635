<template>
  <div id="background-pattern">
    <FloatingLocale />
    <div id="form-container">
      <div class="card mt-6 has-text-centered" style="width: 650px">
        <div class="card-content">
          <Loading v-if="pageLoading" />

          <!-- Non Business Warning -->
          <template v-else-if="!busExist || client.length == 0">
            <p class="p-5 is-family-secondary is-size-5">{{ $t("home.not-registered") }}</p>
            <!-- <RouterLink to="/register-business">{{ $t("home.register") }}</RouterLink> -->
            <hr class="is-denim-back" />
            <button class="button is-info mt-2 mb-2" @click="handleLogout">{{ $t("logout") }}</button>
          </template>

          <template v-else-if="busExist && !portalApproved">
            <div>
              <NotApproved />
              <button class="button is-info mt-2 mb-2" @click="handleLogout">{{ $t("logout") }}</button>
            </div>
          </template>

          <template v-else>
            <button class="button is-info mt-2 mb-2" @click="enterPortal">{{ $t("enter-portal") }}</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onMounted, watch } from "vue";

import { Loading, NotApproved, FloatingLocale } from "@/components";
import { projectFirestore } from "@/firebase/config";
import { getUser, useLogout } from "@/composables";

export default {
  name: "Home",
  components: {
    NotApproved,
    Loading,
    FloatingLocale,
  },
  setup() {
    const router = useRouter();
    const { logout } = useLogout();

    const { user } = getUser();
    const uniqueId = user.value.uid.toString();
    const client = ref("");
    const busExist = ref(false);
    const portalApproved = ref(false);
    const pageLoading = ref(true);

    //logout function
    const handleLogout = async () => {
      await logout();
    };

    const enterPortal = () => {
      router.push({ name: "Order" });
    };

    //Function to get values about current logged in user
    const getData = async () => {
      const docRef = projectFirestore.collection("users").doc(uniqueId);
      await docRef.get().then((doc) => {
        portalApproved.value = doc.data().portalApproved;
        if (doc.data().client) {
          client.value = doc.data().client;
          busExist.value = true;
        }
      });

      pageLoading.value = false;
    };

    onMounted(async () => {
      getData();
    });

    watch(
      user,
      () => {
        if (!user.value) {
          router.push({ name: "Login" });
        }
      },
      { immediate: true }
    );

    return {
      // Data
      pageLoading,
      user,
      uniqueId,
      portalApproved,
      busExist,
      client,
      // Methods
      getData,
      handleLogout,
      enterPortal,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

#background-pattern {
  @extend .background-pattern;
  background-image: url("../assets/patterns/friendlier-pattern-08.png");
}
</style>
