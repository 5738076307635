<template>
  <div id="background-pattern">
    <FloatingLocale />
    <div id="form-container">
      <div class="card mt-6 has-text-centered" style="width: 650px">
        <figure class="image is-inline-block" style="width: 33%">
          <img class="pt-5" :src="FriendlierPrimaryDenim" />
        </figure>
        <div class="card-content ml-5 mr-5 mb-5">
          <hr />
          <div class="columns is-vcentered my-2">
            <div class="column">{{ $t("forgotPassword.reset-prompt") }}</div>
            <input type="email" v-model="resetEmail" class="column input" />
          </div>
          <hr />
          <div class="pt-4 pb-3">
            <button class="button is-info" @click="handleReset">{{ $t("submit") }}</button>
          </div>
          <a class="has-text-info">
            <router-link to="/"> {{ $t("forgotPassword.return-home") }} </router-link>
          </a>
        </div>
      </div>

      <div class="modal" v-bind:class="{ 'is-active': success }">
        <div class="modal-background" onclick="javascript:window.location.reload(true)"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" onclick="javascript:window.location.reload(true)"></button>
          </header>
          <section class="modal-card-body">
            <p class="mt-2">
              <strong>{{ $t("forgotPassword.email-sent") }}</strong>
            </p>
            <!-- <div class="mt-2 has-text-danger" v-if="error">{{ error }}</div> -->
          </section>
          <footer class="modal-card-foot buttons is-centered">
            <button class="button is-info" onclick="javascript:window.location.reload(true)">{{ $t("Ok") }}</button>
          </footer>
        </div>
      </div>

      <div class="modal" v-bind:class="{ 'is-active': error }">
        <div class="modal-background" onclick="javascript:window.location.reload(true)"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" onclick="javascript:window.location.reload(true)"></button>
          </header>
          <section class="modal-card-body">
            <div class="mt-2 has-text-danger" v-if="error">{{ error }}</div>
          </section>
          <footer class="modal-card-foot buttons is-centered">
            <button class="button is-info" onclick="javascript:window.location.reload(true)">{{ $t("Ok") }}</button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { projectAuth } from "@/firebase/config";
import { FloatingLocale } from "@/components";
import { FriendlierPrimaryDenim } from "@/assets";

const resetEmail = ref("");
const success = ref(false);
const error = ref("");

onMounted(() => {
  document.title = "Friendlier | Forgot Password";
});

const handleReset = async () => {
  try {
    await projectAuth.sendPasswordResetEmail(resetEmail.value);
    resetEmail.value = "";
    success.value = true;
  } catch (err) {
    error.value = err.message;
    resetEmail.value = "";
  }
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers.scss" as h;

#background-pattern {
  @extend .background-pattern;
  background-image: url("../../assets/patterns/friendlier-pattern-08.png");
}
</style>
