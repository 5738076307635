<template>
  <div v-if="loading">
    <Loading />
  </div>
  <form v-else-if="portalApproved && !loading" name="feedbackForm">
    <div>
      <p class="ml-4 mt-4 is-size-3 is-family-secondary has-text-info">{{ $t("feedback.title") }}</p>
    </div>
    <hr />
    <div class="feedback" style="width: 33.4%">
      <div class="field inline-input" style="width: 49%">
        <div class="control">
          <input name="nameValue" id="nameFeedback" class="input" type="text" :placeholder="$t('name')" :value="defaultName" />
        </div>
      </div>

      <div class="field inline-input" style="width: 49%; margin-left: 2%">
        <div class="control">
          <input name="emailValue" id="emailFeedback" class="input" :placeholder="$t('email')" :value="defaultEmail" />
        </div>
      </div>

      <div class="field">
        <!-- <label class="label">Prompt</label>  -->
        <div class="control">
          <div class="select">
            <select name="promptValue" id="promptFeedback" :value="promptMessage" @change="unlockSubmit">
              <option disabled selected value>{{ $t("feedback.topic") }}</option>
              <option v-for="datum in prompts" :key="datum" :value="datum">
                {{ $t(datum) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <!-- <label class="label" >Answer</label> -->
        <div class="control">
          <textarea
            name="messageValue"
            id="messageFeedback"
            class="textarea"
            placeholder="Message"
            @change="updateMessage"
            :value="messageFeedback"
          ></textarea>
        </div>
      </div>
      <div class="mt-4 is-family-secondary is-size-5 has-text-danger">
        {{ $t(error) }}
      </div>
      <div class="field is-grouped" style="margin-top: 2%">
        <div class="control">
          <button
            class="button is-info is-link"
            @click.prevent="
              () => {
                sendFeedback();
              }
            "
            :disabled="wasClicked"
          >
            {{ $t("submit") }}
          </button>
        </div>
        <div class="control">
          <button
            class="button is-link is-light"
            @click.prevent="
              () => {
                hardReset();
              }
            "
          >
            {{ $t("feedback.reset") }}
          </button>
        </div>
      </div>
      <p class="confirmation-text">
        {{ $t(confirmation) }}
      </p>
    </div>
  </form>
  <div v-else-if="!portalApproved && !loading">
    <meta http-equiv="refresh" content="0; url=/home" />
  </div>
  <!-- <div v-else-if="!portalApproved && !loading">
    <NotApproved />
  </div> -->
</template>

<script setup>
import getUser from "../composables/getUser";
import { NotApproved, Loading } from "@/components";
import { increment, projectFirestore } from "../firebase/config";
import moment from "moment";
import { ref, onMounted } from "vue";
import { useAPI } from "@/composables";

const prompts = ref([
  "feedback.portal-improve",
  "feedback.new-feature",
  "feedback.trouble",
  "feedback.praise",
  "feedback.other",
]);

const confirmation = ref("");
const error = ref("");
const wasClicked = ref(false);
const { user } = getUser();
const uniqueId = user.value.uid.toString();
const userDoc = ref(null);

const defaultName = ref("");
const defaultEmail = ref("");
const messageFeedback = ref("");
const promptMessage = ref("");

const loading = ref(false);
const portalApproved = ref(false);
const api = useAPI();

onMounted(() => {
  document.title = "Friendlier | Feedback";
});

const getData = async () => {
  if (userDoc.value == null) {
    userDoc.value = await projectFirestore.collection("users").doc(uniqueId).get();
  }
  if (!userDoc.value.exists) {
    return;
  }
  const docData = userDoc.value.data();
  portalApproved.value = docData.portalApproved ? docData.portalApproved : false;

  if (docData.lname == null && docData.fname == null) {
    defaultName.value = "";
  } else if (docData.fname == null) {
    defaultName.value = docData.lname;
  } else if (docData.lname == null) {
    defaultName.value = docData.fname;
  } else {
    defaultName.value = docData.fname + " " + docData.lname;
  }

  if (docData.email == null) {
    defaultEmail.value = "";
  } else {
    defaultEmail.value = docData.email;
  }

  loading.value = false;
};

(async () => {
  loading.value = true;
  await getData();
})();

const validateEmail = () => {
  if (
    String(defaultEmail.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
  ) {
    return true;
  } else {
    return false;
  }
};

async function sendFeedback() {
  wasClicked.value = true;

  if (validateEmail() == false) {
    wasClicked.value = false;
    error.value = "feedback.email-format";
    return;
  }

  if (defaultName.value == null || defaultName.value == "") {
    error.value = "feedback.empty-name";
    wasClicked.value = false;
    return;
  }
  if (defaultEmail.value == null || defaultEmail.value == "") {
    error.value = "feedback.empty-email";
    wasClicked.value = false;
    return;
  }
  if (defaultName.value == null || defaultName.value == "") {
    error.value = "feedback.empty-name";
    wasClicked.value = false;
    return;
  }
  if (promptMessage.value == null || promptMessage.value == "") {
    error.value = "feedback.empty-prompt";
    wasClicked.value = false;
    return;
  }
  if (messageFeedback.value == null || messageFeedback.value == "") {
    error.value = "feedback.empty-msg";
    wasClicked.value = false;
    return;
  }

  error.value = "";
  await api.request(
    `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/feedback`,
    "POST",
    {
      name: defaultName.value,
      email: defaultEmail.value,
      topic: promptMessage.value,
      message: messageFeedback.value,
    },
    {
      includeToken: true,
    }
  );

  // Increment feedback form submission counter
  await projectFirestore.collection("totals").doc("analytics").set({ feedbackFormSubmissions: increment }, { merge: true });
  confirmation.value = "feedback.confirmation";

  await reset();
}

async function reset() {
  await getData();
  messageFeedback.value = "";
  promptMessage.value = "";
}

function hardReset() {
  nameFeedback.value = null;
  emailFeedback.value = null;
  messageFeedback.value = null;
  promptFeedback.value = "";
  wasClicked.value = false;
  confirmation.value = null;
  error.value = null;
}

function unlockSubmit(e) {
  wasClicked.value = false;
  promptMessage.value = e.target.value;
}

function updateMessage(e) {
  messageFeedback.value = e.target.value;
}
</script>

<style scoped>
.feedback {
  margin: 20px;
  text-align: left;
  position: relative;
  z-index: 0;
}

.form {
  position: absolute;
  z-index: 0;
}

#helpBtn {
  margin-top: 10px;
}

.inline-input {
  display: inline-block;
}

.confirmation-text {
  color: black;
  font-size: 20px;
}
</style>
