import { outletsAPI } from "@/composables";
import { parametersAPI } from "../composables";
import { cloneDeep } from "lodash";

const MIN_DAYS_TO_DELIVER = 3;
const MILISECONDS_IN_DAY = 86400000;

const getDeliveryDate = async (outletID) => {
  
  let now = new Date();

  while(isWeekend(now)) {
    now = cloneDeep(addDay(now));
  }
  
  now.setTime(now.getTime() + MIN_DAYS_TO_DELIVER*MILISECONDS_IN_DAY);

  let delivery = cloneDeep(now);

  try {
    let closures = await parametersAPI.getClosures();
    let outlet = await outletsAPI.getOutlet(outletID);
    
    let deliveryDates = outlet.deliveryDates;
    if (!deliveryDates || deliveryDates.length <= 0) {
      deliveryDates = await parametersAPI.getRegionDeliveryDates(outlet.region);
    }

    let dow = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let now_day = now.getDay();
    
    while(isWeekend(now)) {
      now = cloneDeep(addDay(now));
    }

    let idxs = deliveryDates.map((d) => dow.indexOf(d));

    let distances = idxs.map((idx) => {
      let d = 0;
      let date = cloneDeep(now);
      if (idx < now_day)
        d = idx + 7 - now_day;
      else
        d = idx - now_day;

      date.setTime(now.getTime() + d*MILISECONDS_IN_DAY);

      for (let closure of closures) {
        let closure_date = new Date(closure);
        let closure_string = closure_date.getFullYear() + "-" + (closure_date.getMonth() + 1).toString().padStart(2, '0') + "-" + closure_date.getDate().toString().padStart(2, '0');
        let date_string = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
        
        if (closure_string === date_string) {
          d += 7;
        }
      }
      return d;
    });

    let minDistance = Math.min(...distances);
    delivery.setTime(now.getTime() + minDistance*MILISECONDS_IN_DAY);

    return delivery;
  } catch (err) {
    delivery = new Date();
    delivery.setTime(delivery.getTime() + 14*MILISECONDS_IN_DAY);
  }
  return delivery;
};

const isWeekend = (date) => {
  try {
    let day = date.getDay();
    if (day == 6 || day == 0) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

const addDay = (date) => {
  try {
    date.setTime(date.getTime() + MILISECONDS_IN_DAY);
    return date;
  } catch (err) {
    return date;
  }
}

export default getDeliveryDate;
