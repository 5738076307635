<template>
  <p class="is-size-3 mb-3 is-family-secondary has-text-info">{{ $t("loginForm.login") }}</p>
  <form class="has-text-centered">
    <div class="field">
      <p class="control has-icons-left">
        <input type="email" required :placeholder="$t('loginForm.email')" v-model="email" class="input" />
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </p>
    </div>
    <div class="field">
      <p class="control has-icons-left">
        <input type="password" required :placeholder="$t('loginForm.password')" v-model="password" class="input" />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </p>
    </div>
    <button class="button is-info mt-2 mb-2" @click.prevent="handleSubmit">{{ $t("loginForm.login") }}</button>

    <div class="error mt-4 mb-4 is-family-secondary has-text-danger">
      {{ error }}
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useLogin } from "@/composables";
import { logError } from "@/utils";

export default {
  props: {},
  emits: ["login"],
  setup(props, { emit }) {
    const email = ref("");
    const password = ref("");

    const { error, login } = useLogin();

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (error.value == null) {
        emit("login");
      } else {
        logError(`LoginForm.handleSubmit: ${error.value}`);
      }
    };
    return { email, password, handleSubmit, error };
  },
};
</script>

<style></style>
