import { useAPI } from "@/composables";

async function getRegionDeliveryDates(region) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getRegionDeliveryDates`,
      "POST",
      { region: region },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to get region deliveryDates. ${err.toString()}`);
  }
}

/**
 * Retrieves closures from the server.
 * @returns {Promise<any>} A promise that resolves to the closures data.
 * @throws {Error} If there is an error retrieving the closures.
 */
async function getClosures() {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getFacilityClosures`,
      "POST",
      {
        facility: "all"
      },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to get closures. ${err.toString()}`);
  }
}


const parametersAPI = {
  getRegionDeliveryDates,
  getClosures
};

export default parametersAPI;