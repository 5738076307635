<template>
  <div class="friendlier-field">
    <p class="control">
      <span v-if="leadingIcon" class="icon is-medium is-left mr-3">
        <slot></slot>
      </span>
      <input
        required
        :type="type"
        :placeholder="placeholder"
        v-model="fieldValue"
        class="is-size-4 is-right p-2"
        :class="{
          'has-border': hasBorder,
          'no-border': !hasBorder,
          'full-width': !leadingIcon,
          'highlight-red': !valid,
        }"
      />
    </p>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  type: String,
  placeholder: String,
  value: String,
  darkMode: {
    type: Boolean,
    default: false,
  },
  onchange: {
    type: Function,
    default: () => {},
  },
  leadingIcon: {
    type: Boolean,
    default: false,
  },
  hasBorder: {
    type: Boolean,
    default: false,
  },
  reset: Boolean,

  validator: {
    type: Function,
    default: () => {
      return true;
    },
  },
});

const emits = defineEmits(["change"]);

const fieldValue = ref(props.value);
const valid = ref(true);

watch(fieldValue, () => {
  if (fieldValue.value == "") valid.value = true;
  else valid.value = props.validator(fieldValue.value);

  emits("change", fieldValue.value);
});

watch(
  () => props.reset,
  () => {
    fieldValue.value = "";
  }
);
</script>
<style lang="scss" scoped>
@import "@/assets/scss/elements.scss";
@import "@/assets/scss/colors.scss";

.field-icon {
  height: 100%;
  width: 10%;
}

.has-border {
  border: 1px solid black !important;
}

.no-border {
  border: none !important;
}

.full-width {
  width: 100%;
}

.highlight-red {
  border: 1px solid $red-highlight;
  color: $red-highlight;
  background-color: lighten($red-highlight, 50%);
}
</style>
