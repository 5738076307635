<template>
  <div style="margin-left: 1%">
    <p class="is-family-secondary is-size-4 mb-4 ml-3">{{ $t('resetPassword.change-password') }}</p>
    <form>
      <div class="is-size-6 mb-3 ml-3">
        <p>{{ $t('resetPassword.reset-1') }}</p>
        <p>{{ $t('resetPassword.reset-2') }}</p>
        <p>{{ $t('resetPassword.reset-3') }} <strong>{{ $t('resetPassword.reset-4') }}</strong> {{ $t('resetPassword.reset-5') }}</p>
      </div>
      <p v-if="message" class="m-3 is-denim is-bold">{{ message }}</p>
      <p v-if="error" class="m-3 is-sunrise is-bold">{{ error }}</p>
      <button
        type="submit"
        :disabled="submitting"
        @click.prevent="resetPassword"
        class="button is-info mt-1 is-size-6 ml-3 mb-5"
      >
        {{ $t('resetPassword.change-password') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFirebase } from "@/composables";

const props = defineProps({
  email: String,
});

const _fb = useFirebase();

const message = ref("");
const error = ref("");
const submitting = ref(false);

const resetPassword = async () => {
  submitting.value = true;
  try {
    await _fb.sendPasswordResetEmail(props.email);

    for (_ = 5; _ > 0; _--) {
      message.value = `${$t('resetPassword.change-password')} ${_} ${$t('resetPassword.seconds')}`;
      await new Promise(async (res) => {
        setTimeout(res, 1000);
      });
    }
    await _fb.signOut();
  } catch (err) {
    error.value = err.message;
  }
  submitting.value = false;
};
</script>
