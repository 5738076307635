// Library Components
import { projectAuth } from "../firebase/config";
// Library Composables
import { useRouter } from "vue-router";
// Custom Components
import { fetchUserToken, logError } from "@/utils";
// Custom Composables
import { useFirebase } from "@/composables";
// State Management
import { Store, permissionStore } from "./";

class UserStore extends Store {
  constructor(storeName) {
    super(storeName, UserStore.initialState());
  }

  static initialState() {
    return {
      fb_user: undefined,
      doc: undefined,
      uid: "",
      email: "",
      client: "",
      fname: "",
      lname: "",
      languagePreference: "",
      placeID: [],
      portalApproved: false,
      accountTier: undefined,
      tokenID: "",
    };
  }

  get uid() {
    return this.state.uid;
  }

  get email() {
    return this.state.email;
  }

  get languagePreference() {
    return this.state.languagePreference;
  }

  get portalApproved() {
    return this.state.portalApproved;
  }

  get placeID() {
    return this.state.placeID ?? [];
  }

  get tokenID() {
    return this.state.tokenID;
  }

  get accountTier() {
    return this.state.accountTier;
  }

  get fb_user() {
    return this.state.fb_user;
  }
  get doc() {
    return this.state.doc;
  }

  async initialize() {
    try {
      console.log("Initializing User Store")
      let user = projectAuth.currentUser;
      await this.setup(user);

      projectAuth.onAuthStateChanged(async (_user) => {
        await this.setup(_user);
      });
    } catch (e) {
      logError(
        `userStore.initialize: Failed to initialize user store. ${e.toString()}`
      );
    }
  }

  async setup(user) {
    try {
      const router = useRouter();
      this.state.fb_user = user;

      if (this.state.fb_user != null || this.state.fb_user != undefined) {
        await this.fetchUserData();
        await this.fetchUserToken();
      } else {
        if (router) router.push("/login");
      }
    } catch (e) {
      console.log(e);
      logError(`userStore.setup: Failed to setup UserStore. ${e.toString()}`);
    }
  }

  async fetchUserData() {
    try {
      const _fb = useFirebase();
      let user = await _fb.getUser(this.state.fb_user.uid);
      this.state.doc = user["doc"];
      let userData = user["data"];

      let fields = Object.keys(this.state);
      for (let field of fields) {
        if (field in userData) this.state[field] = userData[field];
      }

      if (this.state.accountTier != undefined) {
        await permissionStore.initialize(this.state.accountTier);
      }
    } catch (e) {
      logError(
        `userStore.fetchUserData: Failed to fetch extra user data. ${e.toString()}`
      );
    }
  }

  async fetchUserToken() {
    try {
      if (this.state.fb_user == null) {
        return;
      }
      this.state.tokenID = await this.state.fb_user.getIdToken(true);
      // this.state.tokenID = await fetchUserToken(this.state.fb_user);
    } catch (e) {
      logError(
        `userStore.fetchUserToken: Failed to getch userToken. ${e.toString()}`
      );
    }
  }

  async updateLanguagePreference(lang) {
    try {
      const _fb = useFirebase();

      await _fb.updateUser(this.state.doc, { languagePreference: lang });
      this.state.languagePreference = lang;
    } catch (e) {
      logError(
        `userStore.updateLanguagePreference: Failed to update user langauge preference. ${e.toString()}`
      );
    }
  }
}

export const userStore = new UserStore("user-store");
