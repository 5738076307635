<template>
  <div class="chart-title column is-full is-family-secondary is-size-4">
    <p>{{ title }}</p>
  </div>
  <div class="chart-content columns is-multiline m-auto is-mobile is-vcentered">
    <div v-if="loading" class="mx-auto">
      <Loading />
    </div>
    <div v-else-if="!hasData" class="is-full mt-5"><NoData large centered contained /></div>
    <div v-else class="bar-container">
      <Bar style="width: 100%; height: 100%" :id="id" :options="chartOptions" :data="chartData" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { Bar } from "vue-chartjs";
import { isEqual } from "lodash";

import { Loading, NoData } from "@/components";
import { logError } from "@/utils";

const loading = ref(true);
const hasData = ref(false);
const chartData = ref({});

const FRIENDLIER_COLORS = {
  // sunflower: "#f0ba30",
  // sunrise: "#fa7854",
  // stormcloud: "#969696",
  // cloud: "#3bbaf2",
  // denim: "#032673",
  // greenHighlight: "#4D8B31",
  sunflower: "#F0BA30",
  faded_subflower: "#FAE6B4",
  tangerine: "#F68319",
  faced_tangerine: "#FF9F40",
  faded_sunrise: "#FCC7B8",
  sunrise: "#FA7854",
  faded_denim: "#5372B5",
  denim: "#032673",
  faded_ocean: "#99DAF6",
  ocean: "#3BBAF2",
};

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  resizeDelay: 10,
  scales: {
    x: {
      display: "auto",
      offset: true,
      ticks: {
        padding: 0,
        autoSkipPadding: 10,
        font: {
          family: "GibsonBold",
        },
      },
      border: {
        display: false,
      },
      grid: {
        drawTicks: false,
        display: false,
      },
    },
    y: {
      display: "auto",
      offset: true,
      ticks: {
        includeBounds: false,
        padding: 10,
        autoSkipPadding: 20,
      },
      border: {
        display: false,
      },
      grid: {
        drawTicks: false,
      },
    },
  },
  plugins: {
    legend: {
      onClick: null,
      position: "top",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        padding: 20,
        color: FRIENDLIER_COLORS.denim,
        font: {
          family: "GibsonBold",
        },
      },
    },
  },
});

const props = defineProps({
  id: String,
  labels: {
    type: Array,
    default: [],
  },
  data: {
    type: [Object, Function],
  },
  colors: {
    type: Array,
    default: null,
  },
  title: {
    type: String,
    default: "",
  },
  dataset: {
    type: [Object, undefined],
    default: undefined,
  },
  activeBusiness: {
    type: Object,
    required: true,
  },
});

onMounted(async () => {
  try {
    loading.value = true;
    await makeChartData();
    loading.value = false;
  } catch (err) {
    logError(`bar.onMounted: Failed to set up component. ${err.toString()}`);
  }
});

watch(
  () => props.dataset,
  async (newVal, oldVal) => {
    try {
      if (isEqual(oldVal, newVal) || newVal == undefined || isEqual(newVal, {})) return;

      loading.value = true;
      await makeChartData();
      loading.value = false;
    } catch (err) {
      logError(`Bar.watchDataset: Failed to setup bar chart. ${err.toString()}`);
    }
  }
);

async function makeChartData() {
  if (props.dataset) {
    let data = props.dataset;
    if (!data) {
      hasData.value = false;
      return;
    }
    hasData.value = true;
    chartData.value = {
      labels: data.x_labels,
      datasets: [],
    };
    var colour = 0;
    var border = 0;
    for (let key of Object.keys(data.datasets)) {
      if (colour >= Object.values(FRIENDLIER_COLORS).length) {
        colour = 0;
        border = 3;
      }
      chartData.value.datasets.push({
        label: key,
        data: data.datasets[key],
        borderDash: [border, border],
        backgroundColor: assignColor(props.colors, colour),
        borderColor: assignColor(props.colors, colour),
      });
      colour++;
    }
  } else {
    if (!props.data) {
      hasData.value = false;
      return;
    }
    hasData.value = true;
    chartData.value = {
      labels: props.labels,
      datasets: [],
    };
    var colour = 0;
    var border = 0;
    console.log(props.data);
    for (let [key, val] of Object.entries(props.data)) {
      console.log(key, val);
      if (colour >= Object.values(FRIENDLIER_COLORS).length) {
        colour = 0;
        border = 3;
      }
      chartData.value.datasets.push({
        label: key,
        data: val,
        backgroundColor: assignColor(props.colors, colour),
        borderColor: assignColor(props.colors, colour),
      });
      colour++;
    }
  }
}

function assignColor(prop, i) {
  if (!prop) {
    return Object.values(FRIENDLIER_COLORS)[i];
  } else {
    return prop[i];
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.chart-title {
  color: $denim;
  padding-bottom: 1%;
}

.chart-content {
  max-width: 100%;
  .bar-container {
    position: relative;
    padding: 1%;
    min-height: 250px;
    max-height: 350px;
  }
}

@media screen and (max-width: 700px) {
  .chart-title {
    font-size: 18px;
  }
}
</style>
