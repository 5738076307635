import { ref } from "vue";
import { projectAuth, projectFirestore } from "../firebase/config";

const user = ref(projectAuth.currentUser);
let userData = null;

projectAuth.onAuthStateChanged(async (_user) => {
  user.value = _user;
  if (user.value != null)
    userData = (
      await projectFirestore.collection("users").doc(_user.uid).get()
    ).data();

  try {
    const idToken = await _user.getIdToken();
  } catch (error) {
    console.error("Error getting ID token:", error);
  }
});

const getUser = () => {
  return { user, userData };
};

export default getUser;
