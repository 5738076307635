
/**
 * Calculate the basic impact numbers and equivilencies based on the number of containers sent
 * @param {Number} num_containers 
 * @returns {Object}
 */
export default async function getBasicStats(num_containers, detailed) {

    if(!num_containers && (num_containers != 0)){
        return false;
    }

    let sig = 1
    if (detailed) sig = 100;

    let ghg = Math.round(num_containers * 0.168 * sig) / sig;
    let waste = Math.round(num_containers * 0.058 * sig) / sig;
    let water = Math.round((num_containers * 0.026 * 324 - 0.3296 * num_containers) * sig) / sig;
    let transportEmissions = Math.round((num_containers * 0.007) * sig) / sig;

    let bowling = Math.round(waste/6.35 * sig) / sig;   // plastic waste equiv
    let trees = Math.round(ghg/22 * sig) / sig;         // ghg emission removal equiv
    let bottles = Math.round(water/0.5 * sig) / sig;     // water equiv

    return {
        total_returns: num_containers, 
        metrics: {
            'analytics.ghg': ghg.toLocaleString("en-US") + ' kg', 
            'analytics.waste': waste.toLocaleString("en-US") + ' kg', 
            'analytics.water': water.toLocaleString("en-US") + ' L',
        },
        equivalencies: {
            'analytics.bowling': bowling,
            'analytics.trees': trees,
            'analytics.bottles': bottles
        },
        extra: {
            'analytics.transportEmissions': transportEmissions.toLocaleString("en-US") + ' kg',
        }
    };
}