<template>
  <div style="margin-left: 1%">
    <p class="is-family-secondary is-size-4 mb-4 ml-3">{{ $t("emailNotice.email-list") }}</p>
    <Loading v-if="loading" medium class="m-5" />
    <template v-if="error">
      <p><strong>{{ $t("oops") }}</strong> {{ $t("something-wrong") }}</p>
    </template>
    <template v-if="!loading && !error">
      <div class="is-size-6 mb-3 ml-3">
        <p>{{ $t("emailNotice.notice-1") }}</p>
        <p>{{ $t("emailNotice.notice-2") }}</p>
        <p>{{ $t("emailNotice.notice-3") }}</p>
      </div>
      <div class="email-list-container">
        <div v-for="email in emailList" :key="email" class="email-element">
          <p class="is-denim is-bold">{{ email }}</p>
          <span class="email-remove" @click="() => removeEmail(email)"><img :src="TrashCanBlack" alt="remove email" /></span>
        </div>
      </div>
      <span class="hr is-denim-back" />
      <div class="new-email-container">
        <FormField
          hasBorder
          type="text"
          :reset="resetField"
          :placeholder="$t('emailNotice.new-email')"
          :value="newEmail"
          :validator="emailValidator"
          @change="(val) => (newEmail = val)"
        />
        <button class="ocean-button" @click="addEmail">{{ $t("add") }}</button>
      </div>
      <p
        v-if="message.type != null"
        class="m-3 is-bold"
        :class="{ 'is-denim': message.type == 'msg', 'is-sunrise': message.type == 'err' }"
      >
        {{ message.text }}
      </p>
    </template>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";

import { logError, emailValidator } from "@/utils";
import { Loading, FormField } from "@/components";
import { TrashCanBlack } from "@/assets";
import { useFirebase } from "@/composables";

const props = defineProps({
  placeID: String,
});

const _fb = useFirebase();

const loading = ref(false);
const error = ref(false);
const message = ref({ type: null });

const emailList = ref(new Set());
const newEmail = ref("");
const resetField = ref(false);

async function setup() {
  try {
    emailList.value = await _fb.getInvoiceEmailList(props.placeID);
  } catch (err) {
    logError(`emailNotice.setup: Failed to fetch invoice email list ${props.placeID}. ${err.toString()}`);
    error.value = true;
  }
}

onMounted(async () => {
  loading.value = true;
  await setup();
  loading.value = false;
});

watch(
  () => props.placeID,
  async () => {
    loading.value = true;
    await setup();
    loading.value = false;
  }
);

async function addEmail() {
  message.value = { type: null };
  if (!emailValidator(newEmail.value)) {
    message.value = { type: "err", text: $t('emailNotice.invalid-email') };
    return;
  }
  try {
    emailList.value = await _fb.addInvoiceEmail(newEmail.value, emailList.value, props.placeID);
    resetField.value = !resetField.value;
  } catch (err) {
    console.log(err);
    message.value = { type: "err", text: `${$t('emailNotice.add-fail')} ${newEmail.value}` };
  }
}

async function removeEmail(email) {
  message.value = { type: null };
  try {
    emailList.value = await _fb.removeInvoiceEmail(email, emailList.value, props.placeID);
    resetField.value = !resetField.value;
  } catch (err) {
    console.log(err);
    message.value = { type: "err", text: `${$t('emailNotice.remove-fail')} ${email.value}` };
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
.new-email-container {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.email-list-container {
  display: flex;
  flex-wrap: wrap;

  .email-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px 12px;
    border-radius: 6px;
    width: fit-content;
    border: 1px solid $denim;
    height: 35px;

    p {
      display: inline-block;
      margin-right: 50px;
    }

    &:hover .email-remove {
      display: inline-block;
    }

    &:hover p {
      margin-right: 25px;
    }

    .email-remove {
      height: 25px;
      width: 25px;
      display: none;
      cursor: pointer;

      img {
        height: 100%;
      }
    }
  }
}

.hr {
  height: 1px;
  width: 100%;
  max-width: 400px;
  display: inline-block;
  margin: 5px;
}
</style>
