import { ref } from "vue";
import {
  projectAuth,
  projectFirestore,
  timestamp,
  arrayUnion,
  arrayRemove,
} from "@/firebase/config";
import { logError } from "@/utils";
import { useAPI } from "@/composables";

/***** RESTAURANTS *****/
/**
 * Restrieve a specific restaurant from firebase
 * @param {string} placeID
 * @returns <[{key: string}: any]>: Restaurant object, doc & data, empty if restaurant DNE
 * @throws error if restaurant fails to fetch
 */
async function getRestaurant(placeID) {
  try {
    let docRef = await projectFirestore.collection("restaurants").doc(placeID);
    let docSnap = await docRef.get();

    if (!docSnap.exists) {
      return {};
    }

    let docData = docSnap.data();

    return {
      doc: docSnap,
      data: docData,
    };
  } catch (err) {
    logError(
      `useFirebase.getRestaurant: Failed to fetch restaurant ${placeID}. ${err.toString()}`
    );
    throw new Error("Failed to fetch restaurant");
  }
}

async function getRestaurantRequest(id) {
  try {
    let docRef = await projectFirestore
      .collection("restaurantRequests")
      .doc(id);

    let docSnap = await docRef.get();

    if (!docSnap.exists) {
      return {};
    }

    let docData = docSnap.data();

    return {
      doc: docSnap,
      data: docData,
    };
  } catch (err) {
    logError(
      `useFirebase.getRestaurant: Failed to fetch restaurant request ${id}. ${err.toString()}`
    );
    throw new Error("Failed to fetch restaurant");
  }
}

async function updateRestaurantRequest(id, updateData) {
  try {
    let docRef = projectFirestore.collection("restaurantRequests").doc(id);

    // Update the document with the new data
    await docRef.update(updateData);

    return { success: true };
  } catch (err) {
    logError(
      `useFirebase.updateRestaurantRequest: Failed to update restaurant request ${id}. ${err.toString()}`
    );
    throw new Error("Failed to update restaurant request");
  }
}

async function getInvoiceEmailList(placeID) {
  try {
    let docSnap = await projectFirestore
      .collection("restaurants")
      .doc(placeID)
      .get();
    if (!docSnap.exists) throw new Error("Resturant not found");

    let docData = docSnap.data();
    return docData["invoiceEmailList"]
      ? new Set(docData["invoiceEmailList"])
      : new Set();
  } catch (err) {
    logError(
      `useFirebase.getInvoiceEmailList: Failed to fetch invoice email list ${placeID}. ${err.toString()}`
    );
  }
}

async function addInvoiceEmail(newEmail, emailList, placeID) {
  try {
    if (newEmail.toLowerCase().trim() == "") return emailList;
    emailList.add(newEmail.toLowerCase().trim());
    await projectFirestore
      .collection("restaurants")
      .doc(placeID)
      .set({ invoiceEmailList: arrayUnion(newEmail) }, { merge: true });
    return emailList;
  } catch (err) {
    logError(
      `useFirebase.addInvoiceEmail: Failed to add email to invoice email list ${newEmail} ${placeID}. ${err.toString()}`
    );
    throw new Error("Failed to add email to invoice list");
  }
}

async function removeInvoiceEmail(email, emailList, placeID) {
  try {
    emailList.delete(email);
    await projectFirestore
      .collection("restaurants")
      .doc(placeID)
      .set({ invoiceEmailList: arrayRemove(email) }, { merge: true });
    return emailList;
  } catch (err) {
    logError(
      `useFirebase.removeInvoiceEmail: Failed to remove email from invoice email list ${email} ${placeID}. ${err.toString()}`
    );
    throw new Error("Failed to remove email from invoice list");
  }
}

async function updateDisplayName(placeID, displayName) {
  try {
    await projectFirestore
      .collection("restaurants")
      .doc(placeID)
      .set({ displayName: displayName }, { merge: true });

    return true;
  } catch (e) {
    logError(
      `useFirebase.updateDisplayName: Failed to update displayName. ${e.toString()}`
    );
  }
  return false;
}

/***** ORDERS *****/

async function getOrder(orderID) {
  try {
    let order = await projectFirestore.collection("orders").doc(orderID).get();

    return {
      doc: order,
      data: order.data(),
    };
  } catch (e) {
    logError(
      `useFirebase.getOrder: failed to retrieve order. ${orderID}. ${e.toString()}`
    );
    return undefined;
  }
}

async function getOrders(restaurant) {
  let orders = [];
  try {
    let orderQuery = await restaurant["doc"].ref
      .collection("orders")
      .where("deleted", "!=", true)
      .get();
    // let orderQuery = await restaurant['doc'].ref.collection("orders").get();

    orderQuery.forEach((orderDoc) => {
      orders.push(orderDoc.data());
    });
  } catch (e) {
    logError(
      `useFirebase.getOrders: Failed to fetch restaurant orders. ${e.toString()}`
    );
  }
  return orders;
}

async function createOrder(
  deliveryDate,
  products,
  totalTax,
  totalPrice,
  uid,
  outlet,
  facility,
  notes
) {
  try {
    let orderObj = null;
    try {
      orderObj = {
        deliveryDate: deliveryDate,
        isPremiumShipping: false,
        orderDetails: {},
        priceDetails: {
          subTotal: Math.round(totalPrice * 100) / 100,
          taxTotal: Math.round(totalTax * 100) / 100,
        },
        outlet: outlet,
        facility: facility,
        payment: Math.round((totalTax + totalPrice) * 100) / 100,
        progress: "pending",
        timeSubmitted: timestamp(),
        userSubmitted: uid,
        deleted: false,
        state: "confirmed",
      };
      if (notes) {
        orderObj["notes"] = notes;
      }
      for (let product of products) {
        orderObj["orderDetails"][product["boxIdTag"]] = product["quantity"];
        orderObj["priceDetails"][product["boxIdTag"]] = {
          unitPrice:
            product["quantity"] *
            product["unitPrice"] *
            product["containersPerCase"],
          depositPrice:
            product["quantity"] *
            product["depositPrice"] *
            product["containersPerCase"],
        };
      }
    } catch (e) {
      throw new Error(`Failed to create order object. ${e.toString()}`);
    }

    let newOrder = null;
    try {
      newOrder = await projectFirestore.collection("orders").add(orderObj);
      await newOrder.update({ orderID: newOrder.id });
    } catch (e) {
      throw new Error(
        `Failed to create order in orders collection. ${e.toString()}`
      );
    }

    try {
      await projectFirestore
        .collection("restaurants")
        .doc(outlet)
        .collection("orders")
        .doc(newOrder.id)
        .set({
          ...orderObj,
          orderID: newOrder.id,
        });
    } catch (e) {
      throw new Error(
        `Failed to create order in outlet collection ${outlet}. ${e.toString()}`
      );
    }
    return newOrder.id;
  } catch (err) {
    logError(
      `useFirebase.createOrder: Failed to create order. ${err.toString()}`
    );
  }
  return null;
}

async function generateTempOrder(
  deliveryDate,
  products,
  totalTax,
  totalPrice,
  uid,
  outlet,
  facility,
  notes
) {
  try {
    let orderObj = null;
    try {
      orderObj = {
        deliveryDate: deliveryDate,
        isPremiumShipping: false,
        orderDetails: {},
        priceDetails: {
          subTotal: Math.round(totalPrice * 100) / 100,
          taxTotal: Math.round(totalTax * 100) / 100,
        },
        outlet: outlet,
        facility: facility,
        payment: Math.round((totalTax + totalPrice) * 100) / 100,
        progress: "pending",
        timeSubmitted: timestamp(),
        userSubmitted: uid,
        deleted: false,
        state: "confirmed",
      };
      if (notes) {
        orderObj["notes"] = notes;
      }
      for (let product of products) {
        orderObj["orderDetails"][product["boxIdTag"]] = product["quantity"];
        orderObj["priceDetails"][product["boxIdTag"]] = {
          unitPrice:
            product["quantity"] *
            product["unitPrice"] *
            product["containersPerCase"],
          depositPrice:
            product["quantity"] *
            product["depositPrice"] *
            product["containersPerCase"],
        };
      }
    } catch (e) {
      throw new Error(`Failed to create order object. ${e.toString()}`);
    }

    let newOrder = null;
    try {
      newOrder = await projectFirestore
        .collection("orders")
        .add({ temp: true });
      orderObj["orderID"] = newOrder.id;
      await projectFirestore.collection("orders").doc(newOrder.id).delete();
    } catch (e) {
      throw new Error(`Failed to create temp orderID. ${e.toString()}`);
    }
    return orderObj;
  } catch (error) {
    logError(
      `useFirebase.generateTempOrder: Failed to create temporary order. ${error.toString()}`
    );
  }
  return null;
}

async function updateOrder(orderID, outlet, orderObj) {
  try {
    try {
      await projectFirestore
        .collection("orders")
        .doc(orderID)
        .set(orderObj, { merge: true });
    } catch (e) {
      throw new Error(`Failed to update order ${orderID}. ${e.toString()}`);
    }

    try {
      await projectFirestore
        .collection("restaurants")
        .doc(outlet)
        .collection("orders")
        .doc(orderID)
        .set(orderObj, { merge: true });
    } catch (e) {
      throw new Error(
        `Failed to update outlet ${outlet} order ${orderID}. ${e.toString()}`
      );
    }

    return true;
  } catch (err) {
    logError(
      `useFirebase.updateOrder: Failed to update order ${orderID}. ${err.toString()}`
    );
  }
  return false;
}

async function deleteOrder(orderID, outlet) {
  try {
    await projectFirestore.collection("orders").doc(orderID).delete();
    await projectFirestore
      .collection("restaurants")
      .doc(outlet)
      .collection("orders")
      .doc(orderID)
      .delete();
  } catch (err) {
    logError(
      `useFirebase.deleteOrder: Failed to delete order ${orderID}. ${err.toString()}`
    );
  }
}

// @deprecated
async function calculateOrderTotals(
  products,
  prices,
  quantities,
  priceGroup,
  stripeCustomer
) {
  try {
    const _api = useAPI();
    let response = await _api.request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/calculateOrderTotals`,
      "POST",
      {
        products,
        prices,
        quantities,
        priceGroup,
        stripeCustomer,
      },
      { includeAuth: true, includeToken: true }
    );
    return response["details"];
  } catch (e) {
    logError(
      `useFirebase.calculateOrderTotals: Failed to calculate order totals. ${e.toString()}`
    );
    throw new Error(`Failed to calculate order totals. ${e.toString()}`);
  }
}

/***** USERS *****/
async function sendPasswordResetEmail(email) {
  try {
    await projectAuth.sendPasswordResetEmail(email);
  } catch (err) {
    logError(
      `useFirebase.sendPasswordResetEmail: Failed to send email ${email}. ${err.toString()}`
    );
    throw new Error("Failed to send password reset email");
  }
}

async function signOut() {
  try {
    await projectAuth.signOut();
  } catch (err) {
    logError(
      `useFirebase.signOut: Failed to sign out user ${email}. ${err.toString()}`
    );
    throw new Error("Failed to logout user, please logout now");
  }
}

/**
 * Retrieve a copy of the user's db entry
 * @param {string} uid
 * @returns <[{key: string}: any]>: doc, data object containing user values
 * @throws error if user fails to fetch
 */
async function getUser(uid) {
  try {
    let docRef = await projectFirestore.collection("users").doc(uid);
    let docSnap = await docRef.get();
    let docData = docSnap.data();
    return {
      doc: docSnap,
      data: docData,
    };
  } catch (err) {
    logError(
      `useFirebase.getUser: Failed to get User ${uid} info. ${err.toString()}`
    );
    throw new Error("Failed to fetch user");
  }
}

async function updateUser(user, updateDelta) {
  try {
    if (user.ref) await user.ref.update(updateDelta);
  } catch (e) {
    logError(`useFirebase.updateUser: Failed to updateUser. ${e.toString()}`);
  }
}

async function updateUserWithUID(uid, updateDelta) {
  try {
    const userRef = projectFirestore.collection("users").doc(uid);
    await userRef.update(updateDelta);
  } catch (e) {
    logError(
      `useFirebase.updateUserWithUID: Failed to updateUser. ${e.toString()}`
    );
  }
}

/***** PRODUCTS *****/

/**
 * Retrieve an list of products from firebase
 * @returns <[{key: string}: any]>[], product list
 */
async function getProducts() {
  let products = [];
  try {
    let productCollectionRef = await projectFirestore
      .collection("products")
      .where("posted", "==", true)
      .get();
    productCollectionRef.forEach((product) => {
      products.push({ ...product.data(), id: product.id });
    });
  } catch (e) {
    logError(
      `useFirebase.getProducts: Failed to get Products. ${e.toString()}`
    );
  }
  return products;
}

/***** PARAMETERS *****/

/**
 * Retrieve all the custom pricing groups from firebase
 * @returns Object: { priceGroupID: priceGroupDetails}: a key-value pair for each pricing group
 */
async function getPriceGroups() {
  let priceGroups = null;

  try {
    let priceGroupRef = await projectFirestore
      .collection("parameters")
      .doc("priceGroups")
      .get();
    if (!priceGroupRef.exists)
      throw new error("PriceGroups document not found");

    priceGroups = priceGroupRef.data();
  } catch (e) {
    logError(
      `UseFirebase.getPriceGroups: Failed to retrieve priceG groups. ${e.toString()}`
    );
  }
  return priceGroups;
}

async function getNotes() {
  let notes = null;

  try {
    let notesRef = await projectFirestore
      .collection("parameters")
      .doc("notes")
      .get();
    if (!notesRef.exists) throw new error("Notes not found");

    notes = notesRef.data();
  } catch (e) {
    logError(`UseFirebase.getNotes Failed to retrieve notes. ${e.toString()}`);
  }
  return notes;
}

async function getPermissions(tier) {
  let response = {};
  try {
    let accountTier = await tier.get();

    response.accountTier = {
      doc: accountTier,
      data: accountTier.data(),
    };
  } catch (e) {
    logError(
      `useFirebase.getPermissions: Failed to get permissions for account tier ${tier}. ${e.toString()}`
    );
  }

  // console.log('got account tier moving to perm group')

  try {
    if (
      !("accountTier" in response) ||
      !("permissionGroups" in response["accountTier"]["data"])
    ) {
      throw new Error("permission groups not found");
    }

    response.permissionGroups = [];
    for (let group of response.accountTier.data.permissionGroups) {
      let groupDoc = await group.get();
      response.permissionGroups.push({
        doc: groupDoc,
        data: groupDoc.data(),
      });
    }
  } catch (e) {
    logError(
      `useFirebase.getPermissions: Failed to get permission groups. ${e.toString()}`
    );
  }

  // console.log('got perm group moving to perm')

  response.permissions = [];
  try {
    if (!("permissionGroups" in response)) {
      throw new Error("permission groups not found");
    }
    for (let group of response.permissionGroups) {
      try {
        if (!("permissions" in group.data)) {
          throw new Error(`Permissions not found in group ${group.id}`);
        }

        for (let permission of group.data.permissions) {
          try {
            let permissionDoc = await permission.get();
            response.permissions.push({
              doc: permissionDoc,
              data: permissionDoc.data(),
            });
          } catch (error) {
            logError(
              `useFirebase.getPermissions: Failed to fetch permission. ${error.toString()}`
            );
          }
        }
      } catch (err) {
        logError(
          `useFirebase.getPermissions: Failed to fetch permissions of group ${
            group.id
          }. ${err.toString()}`
        );
      }
    }
  } catch (e) {
    logError(
      `useFirebase.getPermissions: Failed to get permissions. ${e.toString()}`
    );
  }

  return response;
}

const useFirebase = () => {
  return {
    // Restaurant
    getRestaurant,
    updateRestaurantRequest,
    getRestaurantRequest,
    getInvoiceEmailList,
    addInvoiceEmail,
    removeInvoiceEmail,
    getOrders,
    updateDisplayName,
    // Order
    getOrder,
    createOrder,
    updateOrder,
    deleteOrder,
    generateTempOrder,
    // User
    signOut,
    getUser,
    sendPasswordResetEmail,
    updateUser,
    updateUserWithUID,
    // Products
    getProducts,
    // Parameters
    getNotes,
    getPriceGroups,
    getPermissions,
    
    
    // CLEANED
    // DEPRECATED
    calculateOrderTotals,
  };
};

export default useFirebase;
