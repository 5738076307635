
/**
 * Logic for sorting by order id, company name, date dubmitted, or payment
 */
export default async function (array, sortDirection, sortColumn) {
  let dir = sortDirection == "asc" ? -1 : 1;

  if (sortColumn == "time") {
    array.sort((a, b) => {
      if (a.timeSubmitted > b.timeSubmitted) {
        return -1 * dir;
      } else if (a.timeSubmitted < b.timeSubmitted) {
        return 1 * dir;
      } else {
        return 0;
      }
    });
  }
  if (sortColumn == "id") {
    array.sort((a, b) => {
      if (a.orderID.toLowerCase() > b.orderID.toLowerCase()) {
        return -1 * dir;
      } else if (a.orderID.toLowerCase() < b.orderID.toLowerCase()) {
        return 1 * dir;
      } else {
        return 0;
      }
    });
  }
  if (sortColumn == "company") {
    array.sort((a, b) => {
      if (a.outlet.toLowerCase() > b.outlet.toLowerCase()) {
        return -1 * dir;
      } else if (a.outlet.toLowerCase() < b.outlet.toLowerCase()) {
        return 1 * dir;
      } else {
        return 0;
      }
    });
  }
  if (sortColumn == "payment") {
    array.sort((a, b) => {
      if (a.payment > b.payment) {
        return -1 * dir;
      } else if (a.payment < b.payment) {
        return 1 * dir;
      } else {
        return 0;
      }
    });
  }
}