<template>
  <div class="card product-card">
    <Loading v-if="loading" medium contained center />
    <template v-else>
      <div class="card-image product-image">
        <figure class="image">
          <p class="is-family-secondary is-size-3 has-text-centered"></p>
          <img :src="product.imageURL" alt="Product Image" />
        </figure>
      </div>
      <div class="card-content product-content">
        <p class="is-family-secondary is-size-4 has-text-centered">
          {{ product.name }}
        </p>
        <p class="is-size-5">
          ${{ formatPrice((unitPrice / 100) * product.containersPerCase) }} {{ $t("productCard.per-case") }} (+ ${{
            formatPrice((depositPrice / 100) * product.containersPerCase)
          }}
          {{ $t("productCard.deposits") }})
        </p>
        <hr />
        <p class="is-size-6 is-sunrise is-bold">{{ product.memo }}</p>
        <p class="is-size-6">{{ $t("productCard.quant-per-case") }} {{ product.containersPerCase }}</p>
        <p class="is-size-6 pb-4">
          ${{ formatPrice(unitPrice / 100) }} (+ ${{ formatPrice(depositPrice / 100) }} {{ $t("productCard.deposit") }})
        </p>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label is-family-secondary is-size-7">{{ $t("productCard.case-number") }}</label>
              <p class="control has-icons-left">
                <input
                  type="number"
                  required
                  :value="quantity"
                  :onChange="onChange"
                  :step="product.minQuantity"
                  class="input"
                  min="0"
                />
                <span class="icon is-small is-left">
                  <i class="fas fa-shopping-cart"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
// Custom Components
import { formatPrice, logError } from "@/utils";
import { Loading } from "@/components";
// State management
import { collectionStore, clientStore } from "@/store";

const props = defineProps({
  product: Object,
  quantity: Number,
});
const emits = defineEmits(["change"]);

const unitPrice = ref(0);
const depositPrice = ref(0);

const priceGroup = ref(undefined);
const prices = ref({});

const loading = ref(true);

onMounted(async () => {
  setup();
});

watch(
  () => clientStore.activeClient,
  (after, before) => {
    setup();
  }
);

async function setup() {
  try {
    await collectionStore.initialize();
    await clientStore.initialize();

    prices.value = {
      unit: collectionStore.stripePrices["products"][props.product["stripeProductIDUnit"]],
      deposit: collectionStore.stripePrices["products"][props.product["stripeProductIDDeposit"]],
    };

    priceGroup.value = collectionStore.priceGroups[clientStore.activePriceGroup];

    if (priceGroup.value && priceGroup.value[props.product.boxIdTag]) {
      for (let price of prices.value["unit"]) {
        if (priceGroup.value[props.product["boxIdTag"]]["unit"] == price["id"]) {
          unitPrice.value = price["amount"];
        }
      }
      for (let price of prices.value["deposit"]) {
        if (priceGroup.value[props.product["boxIdTag"]]["deposit"] == price["id"]) {
          depositPrice.value = price["amount"];
        }
      }
      loading.value = false;
      return;
    }

    for (let price of prices.value["unit"]) {
      if (price["default"]) {
        unitPrice.value = price["amount"];
      }
    }
    for (let price of prices.value["deposit"]) {
      if (price["default"]) {
        depositPrice.value = price["amount"];
      }
    }
    loading.value = false;
  } catch (err) {
    logError(`productCard.setup: Failed to setup product Card. ${err.toString()}`);
  }
}

const onChange = (e) => {
  emits("change", e);
};
</script>
<style lang="scss" scoped>
.card {
  margin: auto;
}

.product-image {
  max-height: 300px;
  height: 100%;
  max-width: 400px;
}

.product-image > figure {
  height: 100%;
}

.product-image > figure > img {
  height: 100%;
}

.product-card {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-content {
  position: absolute;
  bottom: 0;
}
</style>
