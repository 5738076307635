<template>
  <Loading v-if="pageLoading" />
  <template v-else>
    <NotPermitted v-if="!permitted" />
    <div v-else class="order-container">
      <span>
        <span class="is-flex is-justify-content-space-between is-align-items-center p-5">
          <p class="is-size-3 is-family-secondary has-text-info has-text-left">
            {{ $t("order.order-containers") }}
          </p>

          <ClientSelect />
        </span>

        <hr />

        <div class="filter-header">
          <ProductFilter :products="collectionStore.products" :onFilterChange="onFilterChange" />
          <div>
            <i class="icon fas fas fa-shopping-cart" />
            <span class="unselectable">{{ sum(quantities) }}</span>
          </div>
        </div>

        <div class="card-container is-flex-wrap-wrap is-justify-content-flex-start products-container">
          <div v-if="filteredProducts.length == 0" class="m-5">
            <p class="is-size-3 has-text-info">{{ $t("order.no-products") }}</p>
          </div>
          <div v-for="product in filteredProducts" :key="product.id" class="column is-4 product-container">
            <ProductCard
              :product="product"
              :quantity="quantities[product.boxIdTag]"
              @change="
                (e) => {
                  quantities[product.boxIdTag] = parseInt(e.target.value);
                }
              "
            />
          </div>
        </div>

        <div class="button is-info is-large submit-button" @click.prevent="handleConfirmCart">
          {{ $t("order.confirm") }}
        </div>
      </span>

      <!-- Order Preview -->
      <CheckoutModal
        v-if="showOrderModal"
        @close="() => (showOrderModal = false)"
        :products="selectedProducts(collectionStore.products)"
        :quantities="quantities"
      />

      <!-- Empty Cart Warning -->
      <Modal
        v-if="showErrorModal"
        :title="error.title"
        isDanger
        :body="error.body"
        :close="() => (showErrorModal = false)"
        :actions="[
          {
            text: $t('dismiss'),
            id: 1,
            click: () => {
              showErrorModal = false;
            },
          },
        ]"
      />
    </div>
  </template>
</template>

<script setup>
// Library Components
import { ref, onMounted } from "vue";

// Library Composables
import { useRouter } from "vue-router";

// State Management
import { userStore, clientStore, collectionStore, permissionStore } from "@/store";

// Custom Components
import { sum, logError } from "@/utils";
import { ProductCard, Loading, ProductFilter, Modal, CheckoutModal, ClientSelect, NotPermitted } from "@/components";

// Custom Composables
import { useFirebase, useStripe } from "@/composables";

// Libraries
const router = useRouter();

// Composables
const _fb = useFirebase();
const stripe = useStripe();

/* Data */
// Contains a list of active friendlier products & details taken from firebase
const filteredProducts = ref([]);

// Input Variables
const quantities = ref({});

// Loading balances
const pageLoading = ref(true);
const permitted = ref(false);

// Order Summary
const showOrderModal = ref(false);
const showErrorModal = ref(false);
const error = ref({});

onMounted(async () => {
  document.title = "Friendlier | Place Order";
  await getData();
});

/**
 * Function to get values about current logged in user
 */
async function getData() {
  pageLoading.value = true;

  try {
    await userStore.initialize();
    await clientStore.initialize();
    await collectionStore.initialize();
  } catch (e) {
    logError(`Order.getData: Failed to setup order page. ${e.toString()}`);
  }

  try {
    if (permissionStore.can("placeOrder")) {
      permitted.value = true;
    }
  } catch (err) {
    logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
  }

  try {
    for (let product of collectionStore.products) {
      quantities.value[product["boxIdTag"]] = 0;
    }
  } catch (err) {
    logError(`Order.getData: Failed to fetch products. ${err.toString()}`);
  }
  filteredProducts.value = collectionStore.getState().products;

  await paymentFlow();

  pageLoading.value = false;
}

/*
 * Update the listed products according to user selected filters
 */
function onFilterChange(filters) {
  console.log(filters);
  filteredProducts.value = [];
  collectionStore.getState().products.forEach((product) => {
    let skip = false;
    for (let key in filters) {
      if (filters[key] == "" || filters[key] == 0 || filters[key] == undefined || filters[key] == null) continue;

      if (key in product) {
        if (product[key] != filters[key]) {
          skip = true;
          break;
        }
      }
    }
    if (!skip) {
      filteredProducts.value.push(product);
    }
  });
}

/*
 * Isolate the selected products from the total
 */
const selectedProducts = (arr) => {
  return arr.filter((item) => {
    if (quantities.value[item.boxIdTag] > 0) {
      return item;
    }
  });
};

/**
 * @deprecated
 */
async function paymentFlow() {
  let url;
  let queryParams;
  let redirect_status;
  let order;
  let stripeCustomer;

  try {
    url = window.location.href.split("?");
    if (url.length <= 1) return;

    queryParams = url[1].split("&");
    if (queryParams.length != 5) throw new Error(`bad url recieved. ${url}`);

    redirect_status = queryParams[4].split("=");
    if (redirect_status[0] != "redirect_status" || redirect_status[1] != "succeeded")
      throw new Error(`bad url recieved. ${url}`);

    order = queryParams[0].split("=");
    stripeCustomer = queryParams[1].split("=");
  } catch (e) {
    logError(`Failed to process paymentFlow URL. ${e.toString()}`);
    return;
  }

  let orderDetails;
  try {
    orderDetails = await _fb.getOrder(order[1]);
    if (!orderDetails) {
      throw new Error(`Failed to retrieve order details`);
    }
  } catch (e) {
    logError(`Order.paymentFlow: ${e.toString()}`);
    return;
  }

  await clientStore.setActiveClientByPlaceID(orderDetails["data"]["outlet"]);

  let orderedProducts = [];
  quantities.value = orderDetails["data"]["orderDetails"];

  try {
    let orderTotals = await _fb.calculateOrderTotals(
      collectionStore.getState().products,
      collectionStore.getState().stripePrices,
      quantities.value,
      collectionStore.getState().priceGroups[clientStore.getState().activeClient["data"]["priceGroup"]],
      {
        id: stripeCustomer[1],
      }
    );

    orderedProducts = orderTotals.products;
  } catch (err) {
    logError(`order.paymentFlow: Failed to calculate order totals. ${err.toString()}`);
    console.log(`${err.toString()}`);
  }

  try {
    let invoice = await stripe.createStripeInvoice(
      { id: stripeCustomer[1] },
      userStore.getState().email,
      orderedProducts,
      orderDetails["data"]["orderID"],
      orderDetails["data"]["outlet"],
      userStore.getState().languagePreference,
      "charge_automatically"
    );
    if (!invoice) {
      throw new Error(`Invoice undefined`);
    }
  } catch (err) {
    console.log(`order.paymentFlow: Failed to create invoice. ${err.toString()}`);
  }

  // Navigate to past orders page after successfull invoice creation and order updating
  router.push(`/past-orders?order=${orderDetails["data"]["orderID"]}&order_status=confirmed`);
}

const handleConfirmCart = async () => {
  try {
    if (sum(quantities.value) <= 0) {
      switch (userStore.getState().languagePreference) {
        case "fr":
          error.value = {
            title: "Tenez bon!",
            body: "Votre panier est vide, veuillez faire une sélection",
          };
          break;
        case "en":
        default:
          error.value = {
            title: "Hang On!",
            body: "Your cart is empty, please make a selection",
          };
          break;
      }
      showErrorModal.value = true;
    } else {
      error.value = {};
      showOrderModal.value = true;
    }
  } catch (e) {
    logError(`order.handleConfirmCard: Failed to confirm cart. ${e.toString()}`);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";
.order-container {
  height: 100vh;
  position: relative;
  z-index: 0;

  > :first-child {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .filter-header {
    display: flex;
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0px 20px 25px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 20px 25px 0px rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 25px 0px rgba(255, 255, 255, 1);

    :first-child {
      height: auto;
      flex-grow: 2;
      overflow: hidden;
    }

    > :last-child {
      position: absolute;
      top: 0;
      right: 25px;
      color: $denim;
      flex-grow: 1;
    }
  }

  .submit-button {
    position: fixed;
    bottom: 25px;
    right: 25px;
    border-radius: 15px;
  }

  .card-container {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    z-index: 0;
    overflow-y: scroll;
    height: 675px;

    .product-container {
      height: 650px;
      margin: 10px 0px;
      min-width: 300px;
    }
  }

  .modal {
    .modal-background {
      display: fixed !important;
      z-index: 2;
    }
    .modal-card {
      position: relative;
      z-index: 3;
      border-radius: 15px;
      max-height: 100vh;
      margin: 20px 0px;
      overflow-y: auto;

      header {
        height: 50px;

        .modal-card-title {
          position: relative;
          display: flex;
          align-items: flex-end;
          span {
            font-size: 0.8rem;
            padding-left: 20px;
          }
        }
      }

      section {
        height: fit-content;

        > div {
          height: 50px;
          padding: 5px;
        }
      }

      footer {
        height: 75px;
        margin-bottom: 0px;

        .tooltip {
          position: relative;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
