export default {
  "emailNotice": {
    "email-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'adresses électroniques"])},
    "notice-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez plusieurs courriels pour être informé de l'achèvement d'une commande."])},
    "notice-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces courriels recevront une confirmation de commande"])},
    "notice-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec une copie de la facture et un lien de paiement rapide."])},
    "new-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau courriel"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un courriel valide"])},
    "add-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'ajout d'un courriel:"])},
    "remove-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la suppression du courriel: "])}
  },
  "resetPassword": {
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
    "reset-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un courriel contenant des instructions pour modifier votre mot de passe."])},
    "reset-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela peut prendre jusqu'à 10 minutes, assurez-vous de vérifier vos indésirables."])},
    "reset-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous serez"])},
    "reset-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déconnecté"])},
    "reset-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["après avoir cliqué"])},
    "reset-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre boîte de réception! Nous vous avons envoyé un courriel avec un lien pour changer votre mot de passe. Vous serez déconnecté dans"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondes"])}
  },
  "outletDetails": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réviser"])}
  },
  "cardDetails": {
    "payment-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le paiement"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "pay-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement a échoué, veuillez vérifier que tous les champs sont corrects et réessayer."])}
  },
  "checkoutModal": {
    "order-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande reçue!"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la commande"])},
    "please-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à revoir, s'il vous plaît"])},
    "invoice-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre facture a été créée, elle peut être téléchargée ou consultée en ligne en utilisant les liens ci-dessous."])},
    "download-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la facture"])},
    "pay-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer la facture"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir utilisé le système Friendlier, nous allons commencer à traiter votre commande immédiatement. Si vous avez des questions ou des préoccupations, n'hésitez pas"])},
    "thank-cont": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à nous en faire part."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  },
  "checkoutRestaurantDetails": {
    "restaurant-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du restaurant"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom:"])},
    "select-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un emplacement"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région:"])},
    "estimated-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison estimée:"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse:"])},
    "help-modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose ne va pas ? Contactez nous !"])}
  },
  "checkoutUserDetails": {
    "user-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'utilisateur"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom:"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille:"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel:"])}
  },
  "orderDetailsTable": {
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la commande"])},
    "var-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Les prix exacts des produits peuvent varier"])},
    "each": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chacun"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qté:"])},
    "tax-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes Incluses"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "sales-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de vente"])},
    "total-due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total dû"])}
  },
  "stripeCheckout": {
    "card-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la carte"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de la carte :"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration :"])},
    "cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVC :"])},
    "submit-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now Testing"])}
  },
  "addressField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir l'adresse complète, y compris le numéro pour la saisie automatique de l'adresse"])},
  "businessForm": {
    "business-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscription de l'entreprise"])},
    "business-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne saisissez l'identifiant du lieu que si vous en avez déjà reçu un"])},
    "placeid-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de lieu facultatif (ex. R100111)"])},
    "business-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "select-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une province"])},
    "ontario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
    "quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Québec"])},
    "alberta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alberta"])},
    "bc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie-Britannique"])},
    "manitoba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manitoba"])},
    "nb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau-Brunswick"])},
    "nfl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terre-Neuve et Labrador"])},
    "nwt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoires du Nord-Ouest"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Écosse"])},
    "nunavut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunavut"])},
    "pei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Île-du-Prince-Édouard"])},
    "saskatchewan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saskatchewan"])},
    "yukon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukon"])},
    "select-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une région"])},
    "postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "street-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse (rue)"])},
    "website-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du site web (facultatif)"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "business-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise a été inscrite."])},
    "placeid-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de lieu invalide"])},
    "field-missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veuillez remplir tous les champs obligatoires"])},
    "already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà inscrit pour une entreprise !"])},
    "address-num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'adresse"])}
  },
  "loginForm": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connexion"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courriel"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot de passe"])}
  },
  "regForm": {
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscription"])},
    "name-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prénom"])},
    "name-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de famille"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer le mot de passe"])},
    "email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un courriel valide"])},
    "password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
    "missing-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs"])}
  },
  "sidebar": {
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
    "past-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes antérieures"])},
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un Retour"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "email-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format du courriel est incorrect"])},
    "missing-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir soumis une demande d'aide, nous essaierons d'y répondre rapidement."])}
  },
  "noData": {
    "name-present": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas de données pour ", _interpolate(_named("name")), " pour l'instant!"])},
    "name-missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de données !"])}
  },
  "notApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise n'a pas encore été approuvée. Veuillez attendre d'être contacté par l'équipe de Friendlier une fois que vous aurez rempli le formulaire d'inscription de l'entreprise sur la page de commande."])},
  "orderDetailModal": {
    "container-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme du contenant"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
  },
  "productCard": {
    "per-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par caisse"])},
    "deposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consignes"])},
    "quant-per-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantité par caisse:"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consigne"])},
    "case-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de cas:"])}
  },
  "productFilter": {
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres:"])},
    "container-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du contenant:"])},
    "container-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme du contenant:"])},
    "any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])}
  },
  "passwordValidator": {
    "password-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter plus de 8 caractères"])},
    "password-req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins une majuscule et un chiffre."])},
    "password-mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])}
  },
  "forgotPassword": {
    "reset-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre adresse électronique pour réinitialiser votre mot de passe :"])},
    "return-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retour à la page d'accueil"])},
    "email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel envoyé ! Vérifiez votre boîte de réception"])}
  },
  "login": {
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe ?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour rejoindre Friendlier"])}
  },
  "register": {
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ? Cliquez ici."])}
  },
  "businessPending": {
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir soumis votre entreprise ! Elle n'a pas encore été approuvée. Veuillez patienter pour être contacté par l'équipe de Friendlier."])},
    "update-password-notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez réinitialiser votre mot de passe"])},
    "update-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
  },
  "analytics": {
    "an": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyse"])},
    "packages-reused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des emballages réutilisés"])},
    "cycle-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne du cycle du contenant"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs uniques"])},
    "return-rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de rendement"])},
    "sold-returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumul des ventes et des retours"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "by-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par taille"])},
    "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence de commande (cas)"])},
    "customer-breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répartition des clients"])},
    "more-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez obtenir des informations plus détaillées ?"])},
    "click-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici !"])},
    "thats-like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wow ! C'est comme..."])},
    "ghg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émissions de GES détournées"])},
    "transportEmissions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les émissions évitées représentent ", _interpolate(_named("value")), " d'émissions liées au transport"])},
    "waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déchets détournés"])},
    "water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eau économisée"])},
    "bowling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boules de bowling en plastique"])},
    "trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbres absorbant les GES en un an"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles d'eau remplies"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commentaires"])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation"])},
    "portal-improve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idées d'amélioration du portail"])},
    "new-feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande de fonctionnalité"])},
    "trouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème avec quelque chose"])},
    "praise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éloges de la plate-forme"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
    "email-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ courriel doit avoir le format suivant : _____", "@", "____.com"])},
    "empty-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom vide"])},
    "empty-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel vide"])},
    "empty-prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite vide"])},
    "empty-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message vide"])},
    "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de nous faire part de vos commentaires", "!"])}
  },
  "home": {
    "not-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte ne fait pas partie d'une entreprise Friendlier inscrite."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour vous inscrire"])}
  },
  "order": {
    "order-containers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commander des contenants"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la commande"])},
    "transaction-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de transaction"])},
    "processing-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des paiements :"])},
    "creating-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une commande :"])},
    "not-working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose ne fonctionne pas ?"])},
    "hang-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenez bon !"])},
    "empty-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide, veuillez faire une sélection"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups"])},
    "empty-location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un lieu"])},
    "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit correspondant aux filtres sélectionnés"])}
  },
  "pastOrders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commandes antérieures"])},
    "order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identifiant de commande"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de l'entreprise"])},
    "date-submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de soumission"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement (`$`)"])},
    "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de livraison"])},
    "order-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails de la commande"])},
    "order-complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande complète"])},
    "thank-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir utilisé le système Friendlier, nous allons commencer à traiter votre commande immédiatement. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous en faire part."])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paramètres du compte"])}
  },
  "confirm-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la paiement"])},
  "payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de paiement"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups !"])},
  "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème s'est produit. Veuillez réessayer plus tard."])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée !"])},
  "active-business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise active :"])},
  "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rétroaction"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscrivez-vous"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
  "enter-portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez dans le portail"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])},
  "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])}
}