import { ref } from 'vue'
import { projectAuth } from '../firebase/config'

const error = ref(null)

const login = async (email, password) => {
  error.value = null
  try {

    if (email == '' || password == '') {
      error.value = 'Please add a username and password';
      return;
    }

    const res = await projectAuth.signInWithEmailAndPassword(email,password)
    error.value = null
    return res
  } catch(err) {
    if (err.code = "auth/invalid-email") {
      error.value = "Invalid Credentials"
    } else {
      error.value = err.message
    }
  }
}

const useLogin = () => {
  return {error, login}
}

export default useLogin