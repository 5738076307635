<template>
  <div class="language-box">
    <div class="control has-icons-left">
      <div class="select">
        <select @change="changePref" v-model="$i18n.locale">
          <option value="en">{{ $t("sidebar.english") }}</option>
          <option value="fr">{{ $t("sidebar.french") }}</option>
        </select>
      </div>
      <span class="icon is-small is-left" style="height: 100%">
        <i class="fas fa-globe" style="color: #032673"></i>
      </span>
    </div>
  </div>
</template>

<script setup>
// Library Components
import { onMounted } from "vue";
// Library Composables
import { useI18n } from "vue-i18n";
// State Management
import { userStore } from "@/store";

// Composables
const { t, locale } = useI18n();

onMounted(async () => {
  if (userStore.getState().fb_user == undefined) {
    await userStore.initialize();
  }

  if (userStore.getState().languagePreference == "") {
    await userStore.updateLanguagePreference("en");
  }

  locale.value = userStore.getState().languagePreference;
});

function changePref(e) {
  userStore.updateLanguagePreference(e.target.value);
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
.language-box {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-family: "Gibson";
}

.control {
  display: flex;
  width: 100%;
  height: 3em;
  position: relative;
  background-color: white;
  border-radius: 4px;

  &.has-icons-left {
    .icon {
      color: white;
      background-color: transparent;
      max-width: 38px;
    }
  }
}
.select {
  font-size: 1em;
  width: 100%;
  padding-left: max(2.5em, 38px);
  height: 100% !important;

  &:not(.is-multiple) {
    &:not(.is-loading) {
      &::after {
        content: none;
      }
    }
  }
  select {
    &:not([multiple]) {
      padding-right: unset;
      padding-left: 0 !important;
      outline: none !important;
      box-shadow: none;
      border: none;
      height: 3em;
    }
  }
}

select {
  width: 100%;
  font-weight: 700;
}

option {
  font-weight: 700;
}
</style>
