<template>
  <router-link v-if="!external" :to="to" class="link" :class="{ active: isActive }">
    <i class="icon" :class="icon" />
    <transition name="fade">
      <span v-if="!collapsed">
        <slot />
      </span>
    </transition>
  </router-link>

  <a
    v-else
    class="link"
    :class="{ active: isActive }"
    style="color: white"
    href="mailto:michelle.md@friendlier.ca?subject=Return%20Request&body=Please%20enter%20the%20following%20information%20to%20request%20a%20return%3A%20%0A%0ADate%3A%0ALocation%20and%20Business%20Name%3A%0AReason%20for%20Return%20(e.g.%20Over%20stock%2C%20Defect%2C%20Ordered%20Wrong%20Product)%3A%0ANumber%20of%20Containers%20or%20Lids%2C%20please%20indicate%20if%20full%20or%20partial%20box%3A%0ABox%20Number%20of%20returns%20(optional)%3A%0A%0AWe%20will%20get%20back%20to%20you%20with%20a%20confirmed%20RMA%20number%20and%20an%20estimated%20pickup%20date.%20"
    target="_blank"
  >
    <i class="icon" :class="icon" />
    <span v-if="!collapsed">
      <slot />
    </span>
  </a>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { collapsed } from "./state";

const props = defineProps({
  to: { type: String, required: true },
  icon: { type: String, required: true },
  external: { type: Boolean, default: false },
});

const route = useRoute();
const isActive = computed(() => route.path === props.to);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  margin: 0.1em 0;
  padding: 0.4em;
  border-radius: 0.25em;
  height: 3em;
  color: white;
  text-decoration: none;
  white-space: normal;
}
.link:hover {
  background-color: var(--sidebar-item-hover);
}
.link.active {
  background-color: var(--sidebar-item-active);
}
.link .icon {
  flex-shrink: 0;
  width: 25px;
  margin-right: 10px;
}
</style>
