<template>
  <div class="language-box">
    <div class="control has-icons-left">
      <div class="select is-rounded">
        <select class="lang" @change="changePref" v-model="$i18n.locale">
          <option value="en">{{ $t("sidebar.english") }}</option>
          <option value="fr">{{ $t("sidebar.french") }}</option>
        </select>
      </div>
      <span class="icon is-small is-left">
        <i class="fas fa-globe" style="color: #032673; margin-left: 0.4rem"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import getUser from "@/composables/getUser";

export default {
  methods: {
    changePref(e) {
      const { user } = getUser();

      if (user.value != null) {
        const uniqueId = user.value.uid.toString();
        const docRef = projectFirestore.collection("users").doc(uniqueId);
        let lang = "en";
        docRef.get().then((doc) => {
          switch (e.target.options.selectedIndex) {
            case 0:
              lang = "en";
              break;
            case 1:
              lang = "fr";
              break;
          }
          docRef.update({ languagePreference: lang });
        });
      }
    },
  },
  // check for preferred locale on component mount
  mounted() {
    const { user } = getUser();

    if (user.value != null) {
      const uniqueId = user.value.uid.toString();
      const docRef = projectFirestore.collection("users").doc(uniqueId);
      docRef.get().then((doc) => {
        if (doc.data().languagePreference != null) {
          this.$i18n.locale = doc.data().languagePreference;
        } else {
          docRef.update({ languagePreference: "en" });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.lang {
  padding: 0.6em;
  padding-left: 2.6em;
  padding-right: 0.9em;
  background-color: $sunflower;
  font-family: "GibsonBold";
  color: $denim;
  line-height: normal;
}
.select {
  &:not(.is-multiple) {
    &:not(.is-loading) {
      &::after {
        content: none;
      }
    }
  }
}
select {
  padding-right: 1rem !important;
  border: none !important;
}
option {
  background-color: white;
}
.language-box {
  position: fixed;
  right: 1rem;
  top: 1rem;
}
</style>
